define('portale-vigilanza/mixins/checkMessage/check-message', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({

    activate: function activate() {

      _ember['default'].$('#modalMessage').modal('hide');

      var _this = this;

      setTimeout(function () {
        if (_this.get('session.plessi_non_attivi_visualizza') && !_this.get('session.view_message')) {

          var messaggio = _this.get('session.plessi_non_attivi_messaggio');
          $.bigBox({
            title: '',
            content: messaggio,
            color: "#C79121",
            //color : "#f9d43f",
            icon: false,
            number: ""
          }, function () {
            _this.set('session.plessi_non_attivi_visualizza', false);
          });
        }
      }, 2000);

      //controllo se ha cambiato tot volte la route
      if (this.get('session.count_check_message') === window.ENV.APP.NCheckMessage) {

        //***DATI UTENTE
        this.store.unloadAll('currentuser');
        this.store.find('currentuser').then(function (utenti) {
          utenti.forEach(function (utente) {

            _this.set('session.id', utente.get('id'));
            _this.set('session.nominativo', utente.get('nominativo'));
            _this.set('session.email', utente.get('email'));
            _this.set('session.telefono', utente.get('telefono'));

            _this.set('session.crediti', utente.get('crediti'));

            _this.set('session.costo_minimo_crediti', utente.get('costo_minimo_crediti'));
            if (parseInt(_this.get('session.crediti')) > parseInt(_this.get('session.costo_minimo_crediti'))) {
              _this.set('session.crediti_ok', true);
            } else {
              _this.set('session.crediti_ok', false);
            }

            if (Math.floor(parseInt(_this.get('session.crediti')) / parseInt(_this.get('session.costo_minimo_crediti'))) == 1) {
              _this.set('session.crediti_uno', true);
            } else {
              _this.set('session.crediti_uno', false);
            }

            _this.set('session.is_vigilo', utente.get('is_vigilo'));
            _this.set('session.is_vigilo_attivo', utente.get('is_vigilo_attivo'));

            _this.set('session.view_message', utente.get('view_message'));
            _this.set('session.message', utente.get('message'));
            _this.set('session.require_message', utente.get('require_message'));
            if (utente.get('require_message')) {}
            /*
                      //SALVO LE CONFIGURAZIONI PER I TAGS
                      _this.set('session.show_tags',false);
                      _this.set('session.tags',null);
                      var show_tags = utente.get('show_tags');
                      if(show_tags){
                        _this.set('session.show_tags', show_tags);
                        //Recupero l'array dei tags
                        var elenco_tags = [];
                        utente.get('tags').forEach(function(tag){
                          elenco_tags.push({
                            etichetta: tag.etichetta,
                            descrizione: tag.descrizione,
                            placeholder: tag.placeholder
                          });
                        });
                        _this.set('session.tags',elenco_tags);
                      }
                      //FINE TAGS
            */
            _this.set('session.route', utente.get('route'));
          });

          if (_this.get('session.view_message')) {
            _ember['default'].$('#modalMessage').modal('show');
          }

          //reimposto il conteggio
          _this.set('session.count_check_message', 1);
        });
      } else {
        //incremento il conteggio
        var cont = this.get('session.count_check_message') + 1;
        this.set('session.count_check_message', cont);
      }
    },

    actions: {
      //  ** Azione pulsante del modal **
      cambiaRoute: function cambiaRoute() {

        _ember['default'].$('#modalMessage').modal('hide');
        //controllo qual'è la route dove deve essere reindirizzato
        switch (this.get('session.route')) {
          case 'home':
            this.transitionTo('home');break;
          case 'datiAccount':
            this.transitionTo('account.modifica', { queryParams: { azione: 'cellulare' } });
            _ember['default'].$('#modificaCel').modal('show');
            break;
          case 'documentoAccount':
            this.transitionTo('account.modifica', { queryParams: { azione: 'documento' } });
            break;
          case 'statutoPresaVisioneAccount':
            this.transitionTo('home', { queryParams: { azione: 'agente_statuto' } });
            break;
          default:
            this.transitionTo('home');break;
        }
      }

    }

  });
});
define('portale-vigilanza/controllers/plessiquote/index', ['exports', 'ember', 'portale-vigilanza/mixins/sortList/sort-list', 'ember-cli-pagination/remote/route-mixin'], function (exports, _ember, _portaleVigilanzaMixinsSortListSortList, _emberCliPaginationRemoteRouteMixin) {
    exports['default'] = _ember['default'].Controller.extend(_emberCliPaginationRemoteRouteMixin['default'], _portaleVigilanzaMixinsSortListSortList['default'], {

        namePage: 'plessiquote',

        queryParams: ["page", "sort", "order", "nome_plesso"],
        sort: 'id',
        order: 'asc',
        modelIsLoad: false,
        actions: {

            //  ** Ricerca delle fatture **
            searchPlessi: function searchPlessi() {
                this.set('modelIsLoad', false);
                var _this = this;
                this.findPaged('plessiquote', { page: 1, sort: this.get('sort'), order: this.get('order'), nome_plesso: this.get('nome_plesso') }).then(function (data) {
                    _this.set('model', data);
                    _this.set('modelIsLoad', true);
                });
            },

            pulisci: function pulisci() {
                this.set('nome_plesso', '');
                this.send('searchPlessi');
            }

        }
    });
});
define('portale-vigilanza/controllers/copertura/create', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    success: false,

    myProvinceList: _ember['default'].ArrayProxy.create({
      content: _ember['default'].A()
    }),

    obsProvince: _ember['default'].observer('provinciaSelected', function () {
      if (this.provinciaSelected && this.get('myProvinceList').indexOf(this.provinciaSelected) === -1) {
        /** aggiungo la provincia alla mia lista */
        this.get('myProvinceList').pushObject(this.provinciaSelected);
      }
      /** resetto quelli selezionata */
      this.set('provinciaSelected', null);
    }),

    actions: {
      removeItem: function removeItem(item) {
        this.get('myProvinceList').removeObject(item);
      },
      send: function send() {
        var _this = this;

        /** mappo l'array */
        var provinces = this.myProvinceList.map(function (item) {
          return item.id;
        });
        /** preparo il modello */
        var send = this.store.createRecord('coperturacreate', {
          province: provinces
        });
        /** effettuo chiamata */
        send.save().then(function () {
          /** visualizzo caricamneto successo */
          _this.set('success', "Province aggiunte correttamente");
          _this.set('myProvinceList', _ember['default'].ArrayProxy.create({
            content: _ember['default'].A()
          }));
          /** setto timeout messggio successo */
          setTimeout(function () {
            _this.set('success', false);
          }, 5000);
        })['catch'](function (reason) {
          /** visualizzo errore */
          _this.set('error', reason);
        });
      }
    }
  });
});
define('portale-vigilanza/controllers/richiediplesso', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    queryParams: ['provincia', 'comune'],

    arrayTipologie: window.ENV.APP.Tipologie_plesso,

    theme: 'vigilo4you',

    checkCliente: false,

    checkVocale: false,
    checkDigitale: false,
    checkAltro: false,
    allowInvio: false,

    titoloModal: 'Richiesta Nuovo Plesso',
    messaggioModal: '',
    errorModal: false,

    listaProvince: null,
    listComuni: null,

    selezioneTipologia: null,

    init: function init() {
      //recupero l'elenco delle province
      this.set('listaProvince', this.store.find('province'));
    },
    //  ** Modifica elenco comuni  **
    loadComuni: (function () {
      this.set('model.comune', undefined);
      this.set('listComuni', this.store.find('comuni', {
        provincia: this.get('model.provincia'),
        'case': 'upper'
      }));
    }).observes('model.provincia'),

    //  ** Controlla pulsante **
    checkConferma: (function () {
      if (this.get('checkbox_1')) this.set('allowInvio', true);else this.set('allowInvio', false);
    }).observes('checkbox_1'),

    actions: {

      makeInfoRequest: function makeInfoRequest(infoPhone) {
        var _this = this;

        console.log('Make info reuqest');
        /** resetto errore */
        this.set('infoPhoneError', null);
        if (!infoPhone) this.set('infoPhoneError', "Inserisci un numero di telefono");else {
          /** creo la chiamta per il post del numero di telegono */
          var record = this.store.createRecord('inforequest', {
            phone: infoPhone
          });
          /** effettuo la chiamata */
          record.save().then(function (result) {
            /** stampo successo */
            _this.set('infoRequestSuccess', 'Richiesta registrata. <br> La contatteremo il prima possibile');
          })['catch'](function (reason) {
            /** stampo errore */
            _this.set('infoPhoneError', reason);
          });
        }
      },

      //  ** Azione pulsante del modal **
      toggleConfirm: function toggleConfirm() {
        _ember['default'].$('#myModal').modal('hide');
        if (this.get('elencoPlessi')) {
          this.transitionToRoute('elencoplessi');
        } else {
          this.transitionToRoute('plessicliente');
        }
      },

      /** apertura modale della privacy */
      apriPrivacy: function apriPrivacy() {
        _ember['default'].$('#modalPrivacy').modal('show');
      },
      nascondiServizi: function nascondiServizi() {
        this.set('showAltriServizi', false);
      },
      visualizzaServizi: function visualizzaServizi() {
        this.set('showAltriServizi', true);
      },
      /** salvo step 1 */
      procediStep1: function procediStep1() {
        var _this2 = this;

        /** setto data checkbox_1 */
        this.set('model.checkbox_1', moment().format('YYYY-MM-DD HH:mm:ss'));
        /** setto checkbox_2 e checkbox_3 */
        this.set('model.checkbox_2', this.get('checkbox_2') ? moment().format('YYYY-MM-DD HH:mm:ss') : null);
        this.set('model.checkbox_3', this.get('checkbox_3') ? moment().format('YYYY-MM-DD HH:mm:ss') : null);
        /** verifico che il codice reCAPTCHA sia attivato */
        if (grecaptcha.getResponse()) {
          /** visualizzo spin di caricamento */
          _ember['default'].$('body').addClass('loading');
          /** chiamata API per salvare il modello */
          this.get('model').save().then(function () {
            /** setto di default quello inserito in precedenza */
            _this2.set('model.cel_plesso', _this2.get('model.tel_contatto'));
            /** richiedo copertura per il comune inserito */
            _this2.store.find('coperturacomune', {
              provincia: _this2.get('model.provincia'),
              comune: _this2.get('model.comune')
            }).then(function (elenco_servizi) {
              /** visualizzo fase 2 */
              _this2.set('show_step1', false);
              _this2.set('show_step2', true);

              var migliore = null;
              var elenco_altri = [];
              var primo = true;

              /** verifico che siano presenti dei servizi */
              if (elenco_servizi.get('length') > 0) {
                _this2.set('procedi_registrazione', true);
              }
              var peso_migliore = 0;

              /** scorro i servizi disponibili */
              elenco_servizi.forEach(function (servizio) {
                var peso_totale = 0.0;
                var nome_istituto = servizio.get('nome_istituto');

                var opzioni = servizio.get('opzioni');
                opzioni.forEach(function (opzione) {
                  peso_totale = peso_totale + parseFloat(opzione.peso);
                });

                if (peso_totale > peso_migliore) {
                  migliore = servizio;
                  primo = false;
                  peso_migliore = peso_totale;
                }
              });
              elenco_servizi.forEach(function (servizio) {
                if (servizio != migliore) elenco_altri.push(servizio);
              });
              /** controllo se presente servizio migliore */
              if (!primo) {
                _this2.set('migliore', migliore);_this2.set('showMigliore', true);
              } else {
                _this2.set('showMigliore', false);
                _this2.set('AltriServizi', false);
                _this2.set('showProcedi', false);
              }

              /** controllo se sono presenti altri servizi */
              if (elenco_altri.length > 0) _this2.set('AltriServizi', true);
              /** setto lista servizi */
              _this2.set('listaServizi', elenco_altri);
              _this2.set('showAltriServizi', false);
              /** */
              //this.set('step2', true);
              /** rimuovo spin di caricamento */
              _ember['default'].$('body').removeClass('loading');
            }, function () {
              /** errore durante la ricerca della disponibilità */

              _ember['default'].$('body').removeClass('loading');
              /** setto modale per l'errore */
              _this2.set('errorModal', true);
              _this2.set('titoloModal', 'Verifica Copertura Servizio');
              _this2.set('messaggioModal', 'Errore nella verifica coperutra');
              /** visualizzo modale */
              _ember['default'].$('#myModal').modal('show');
            });
          }, function (error) {
            /** errore creazione modello */

            /** resetto reCAPTCHA */
            grecaptcha.reset();
            /** setto modale per l'errore */
            _this2.set('modelError', _this2.get('model'));
            _this2.set('errorModal', true);
            _this2.set('messaggioModal', 'Errore nella procedura di richiesta.');
            /** visualizzo modale */
            _ember['default'].$('#myModal').modal('show');
          });
        } else {
          /** errore durante la verifica reCAPTCHA */
          /** rimuovo spin di caricamento */
          _ember['default'].$('body').removeClass('loading');
          /** setto modale per l'errore */
          this.set('errorModal', true);
          this.set('messaggioModal', 'Manca la verifica del codice reCAPTCHA');
          /** visualizzo modale */
          _ember['default'].$('#myModal').modal('show');
        }
      },
      /** salva step 2 */
      procediStep2: function procediStep2() {
        var _this3 = this;

        /** visualizzo spin di caricamento */
        _ember['default'].$('body').addClass('loading');
        /** chiamata API per salvare il modello */
        this.get('model').save().then(function () {
          /** effettuo la seconda chiamata */
          _this3.get('model').save().then(function () {
            //controllo l'esito dello step 3
            if (_this3.get('model.stato') === '3' && _this3.get('model.sub_stato') === '2')
              /** visualizzo errore di registrazione */
              _this3.set('errore_step3', true);
            /** visualizzo step 3 */
            _this3.set('show_step2', false);
            _this3.set('show_step3', true);
            /** nascondo form */
            _this3.set('procedi_registrazione', false);
            /** rimuovo spin di caricamento */
            _ember['default'].$('body').removeClass('loading');
          }, function () {
            /** errore seconda creazione */
            _this3.set('modelError', _this3.get('model'));
            /** setto errore nella modale */
            _this3.set('errorModal', true);
            _this3.set('messaggioModal', 'Errore nella procedura di richiesta.');
            /** visualizzo modale */
            _ember['default'].$('#myModal').modal('show');
            /** rimuovo spin di caricamento */
            _ember['default'].$('body').removeClass('loading');
          });
        }, function () {
          /** errore prima creazione */
          _this3.set('modelError', _this3.get('model'));
          /** setto errore nella modale */
          _this3.set('errorModal', true);
          _this3.set('messaggioModal', 'Errore nella procedura di richiesta.');
          /** visualizzo modale */
          _ember['default'].$('#myModal').modal('show');
          /** rimuovo spin di caricamento */
          _ember['default'].$('body').removeClass('loading');
        });
      }
    }
  });
});
define('portale-vigilanza/components/js-switch', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    insertSwitch: (function () {

      var elems = Array.prototype.slice.call(document.querySelectorAll('.js-switch'));
      elems.forEach(function (html) {
        var switchery = new Switchery(html, { size: 'medium', color: '#9decff', disabled: false });
      });
    }).on("didInsertElement")

  });
});
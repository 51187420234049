define('portale-vigilanza/controllers/attivazione', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    queryParams: ['code', 'email'],

    error: 'panel-default',
    message: '',
    token: '',
    viewLink: false,

    //  ** TENTO L'ATTIVAZIONE **
    attivaIniziale: function attivaIniziale() {
      var _this = this;
      _this.set('viewLink', false);
      _this.set('needLogout', false);

      if (this.get('session').isAuthenticated) {
        _this.set('error', 'panel-danger');
        _this.set('needLogout', true);
      } else {

        this.store.find('reminder', { code: this.get('code'), email: this.get('email') }).then(function (attivazioni) {
          _this.set('error', 'panel-success');
          _this.set('message', 'Utente attivato correttamente.');
          attivazioni.forEach(function (attivazione) {
            _this.set('token', attivazione.get('token'));
          });
          _this.set('viewLink', true);
        }, function (error) {
          _this.set('error', 'panel-danger');
          _this.set('message', 'Errore nella procedura d\'attivazione.<br>Contattare il suppurto tecnico.');
          console.log(error);
        });
      }
    },

    inviaAttivazione: (function () {
      _ember['default'].run.once(this, 'attivaIniziale');
    }).observes('code', 'email')

  });
});
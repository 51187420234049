define('portale-vigilanza/components/google-maps', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    insertMap: (function () {
      console.log('insertMap');
      var container = _ember['default'].$(".map-canvas");
      var myLatLng = { lat: this.get("latitude"), lng: this.get("longitude") };

      var map = new google.maps.Map(container[0], {
        zoom: this.get("zoom"),
        center: myLatLng
      });

      google.maps.event.addListener(map, 'click', function (event) {
        console.log(event.LatLng);
        new google.maps.Marker({
          map: map,
          position: event.LatLng,
          title: 'Plesso',
          icon: 'img/icon-plesso-evidenziato.png'
        });
      });

      /*
      AGGIUNTA MARKER AL CLICK SULLA MAPPA
      map.addListener('click', function(e) {
        new google.maps.Marker({
          map: map,
          position: e.latLng,
        });
      });*/

      //CICLO PER I MARKER DEGLI ALTRI PLESSI
      var elencoPlessi = this.get('markers');
      elencoPlessi.forEach(function (plesso) {

        /*
        if(!plesso.get('format_lat')||plesso.get('format_long')){
          //plesso.get('geocode')
        }else{
        */

        //aggiungo il marker
        var marker = new google.maps.Marker({
          position: new google.maps.LatLng(plesso.get('format_lat'), plesso.get('format_long')),
          map: map,
          title: plesso.get('ragione_sociale'),
          icon: 'img/icon-plesso-default.png'
        });
        //creo la finestra con le info
        var id_encoded = escape(plesso.get('id'));
        var contentString = '<div id="content">' + '<h4 id="firstHeading" class="firstHeading"><a href="plessicliente/dettagli?id_plesso=' + id_encoded + '&panel_visualizza=localizzazione">' + plesso.get("ragione_sociale") + '</a></h4>' + '</div>';
        var infowindow = new google.maps.InfoWindow({
          content: contentString,
          maxWidth: 250
        });
        marker.addListener('click', function () {
          infowindow.open(map, marker);
        });

        //}
      }, this); // fine ciclo markers

      // AGGIUNGO IL MARKER EVIDENZIATO
      //aggiungo il marker
      var marker = new google.maps.Marker({
        position: myLatLng,
        map: map,
        title: 'Plesso',
        icon: 'img/icon-plesso-evidenziato.png'
      });
      //creo la finestra con le info
      var contentString = '<div id="content">' + '<h4 id="firstHeading" class="firstHeading">' + this.get("title") + '</h4>' + '</div>';
      var infowindow = new google.maps.InfoWindow({
        content: contentString,
        maxWidth: 250
      });
      marker.addListener('click', function () {
        infowindow.open(map, marker);
      });
    }).on('didInsertElement')

  });
});
define('portale-vigilanza/routes/indirizzos/show', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        model: function model(params) {

            return this.store.findRecord('indirizzo', params.indirizzo_id);
        }
    });
});
/**
 * Created by alessandro.fornasari on 09/09/2015.
 */
define('portale-vigilanza/helpers/format-euro', ['exports', 'ember'], function (exports, _ember) {
    exports.formatEuro = formatEuro;

    function formatEuro(input) {
        var valore = '';
        if (input) {
            if (typeof input == 'number') input = input.toString();

            input = parseFloat(input);

            var i = input * Math.pow(10, 2);
            i = Math.round(i);
            valore = i / Math.pow(10, 2);

            input = valore.toString();

            if (input.indexOf('.') > 0) {
                valore = input.replace('.', ",");
                var array_split = valore.split(",");
                var decimali = array_split[1].toString();
                if (decimali.length == 1) valore = valore + '0';
            } else {
                valore = input + ',00';
            }

            valore += ' €';
        }

        return new _ember['default'].Handlebars.SafeString(valore);
    }

    exports['default'] = _ember['default'].Handlebars.makeBoundHelper(formatEuro);
});
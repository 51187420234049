define('portale-vigilanza/controllers/kit/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    namePage: 'kit',

    queryParams: ["plesso", "tag", "selezione"],
    plesso: null,
    tag: null,
    selezione: null,
    selezioneAttiva: false,

    modelIsLoad: null,

    listKit: null

  });
});
define('portale-vigilanza/helpers/sort-icon', ['exports', 'ember'], function (exports, _ember) {
    exports.sortIcon = sortIcon;

    function sortIcon(colon, sortProperties, sortAscending) {
        var img = '';
        if (colon === sortProperties) {
            if (sortAscending == 'asc') {
                img = '<i class="fa fa-sort-amount-asc"></i>';
            } else {
                img = '<i class="fa fa-sort-amount-desc"></i>';
            }
        } else {
            img = '<i class="fa fa-sort-amount-asc" style="opacity:0.2;"></i>';
        }
        return new _ember['default'].Handlebars.SafeString(img);
    }

    exports['default'] = _ember['default'].Handlebars.makeBoundHelper(sortIcon);
});
define('portale-vigilanza/mixins/datifatturazionefornitore/datifatturazione-controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({

    /*
    queryParams: ['richiedibile_type','richiedibile_id'],
    richiedibile_type: null,
    richiedibile_id: null,
    */

    init: function init() {
      //recupero l'elenco delle province
      var province = this.store.find('province');
      this.set('listaProvince', province);
    },

    //  ** Modifica elenco comuni SEDE **
    loadComuni: (function () {
      this.set('datifatturazionenuovi.sede_legale_comune', null);
      this.set('listComuni', this.store.find('comuni', { provincia: this.get('datifatturazionenuovi.sede_legale_provincia'), 'case': 'upper' }));
    }).observes('datifatturazionenuovi.sede_legale_provincia'),

    actions: {

      //  ** Azione pulsante del modal **
      toggleConfirm: function toggleConfirm() {
        location.reload();
      },
      //  ** FINE - Azione pulsante del modal **

      //  ** Azione per RICHIEDERE NUOVI o MODIFICA dati di Fatturazione **
      showFormNuovi: function showFormNuovi(type) {

        this.set('datifatturazionenuovi', this.store.createRecord('datifatturazionefornitore'));

        if (type === 'modifica') {
          this.set('datifatturazionenuovi.sede_legale_provincia', this.get('datifatturazionefornitore.sede_legale_provincia'));

          //copio i dati esistenti
          this.set('datifatturazionenuovi.codice_fatturazione', this.get('datifatturazionefornitore.id'));
          this.set('datifatturazionenuovi.ragione_sociale', this.get('datifatturazionefornitore.ragione_sociale'));
          this.set('datifatturazionenuovi.codice_fiscale', this.get('datifatturazionefornitore.codice_fiscale'));
          this.set('datifatturazionenuovi.partita_iva', this.get('datifatturazionefornitore.partita_iva'));
          this.set('datifatturazionenuovi.banca', this.get('datifatturazionefornitore.banca'));
          this.set('datifatturazionenuovi.iban', this.get('datifatturazionefornitore.iban'));
          this.set('datifatturazionenuovi.sede_legale_indirizzo', this.get('datifatturazionefornitore.sede_legale_indirizzo'));
          this.set('datifatturazionenuovi.sede_legale_cap', this.get('datifatturazionefornitore.sede_legale_cap'));
          this.set('datifatturazionenuovi.sede_legale_nazione', this.get('datifatturazionefornitore.sede_legale_nazione'));
          this.set('datifatturazionenuovi.sede_legale_comune', this.get('datifatturazionefornitore.sede_legale_comune'));
          this.set('datifatturazionenuovi.codice_assoggettamento_fiscale', this.get('datifatturazionefornitore.codice_assoggettamento_fiscale'));
        }

        //configuro il richiedibile_type e richiedibile_id
        this.set('datifatturazionenuovi.richiedibile_type', this.get('richiedibile_type'));
        this.set('datifatturazionenuovi.richiedibile_id', this.get('richiedibile_id'));

        //cambio visualizzazione
        this.set('showDatiFatturazione', false);
        this.set('showRichiediNuovi', false);
        this.set('showRichiediModifica', false);
        this.set('showFormNuovi', true);
      },
      //  ** FINE RICHIEDI O MODIFICA DATI FATTURAZIONE **

      //  ** Azione Invio Richiesta **
      inviaRichiesta: function inviaRichiesta() {

        //salvo il modello
        var _this = this;
        this.get('datifatturazionenuovi').save().then(function (dati) {
          _this.set('errorModal', false);
          _this.set('titoloModal', 'Richiesta dati di fatturazione');
          _this.set('messaggioModal', 'Richiesta dati di fatturazione inviata correttamente.<br>Verrai avvisato via email quando la richiesta verrà accettata');
          _this.set('confermaModal', 'modificaDatiFatturazione');
          _ember['default'].$('#myModal').modal('show');
          _this.set('datifatturazionefornitore', dati);
        }, function (error) {
          _this.set('modelError', _this.get('datifatturazionenuovi'));
          _this.set('errorModal', true);
          _this.set('titoloModal', 'Richiesta dati di fatturazione');
          _this.set('messaggioModal', 'Errore nell\'invio della richiesta');
          _ember['default'].$('#myModal').modal('show');
        });
      },
      //  ** FINE - Azione Invio Richiesta **

      //  ** Azione Nascondi Nuovi  Dati Fatturazione **
      hideNuoviDati: function hideNuoviDati() {
        var datifatturazionenuovi = this.get('datifatturazionenuovi');
        if (datifatturazionenuovi.get('isNew')) {
          datifatturazionenuovi.destroyRecord();
        }
        //Cambio visualizzazione
        //se ho i dati di fatturazione
        if (this.get('datifatturazionefornitore.id')) {
          this.set('showDatiFatturazione', true);
          this.set('showRichiediModifica', true);
        } else {
          this.set('showDatiFatturazione', false);
          this.set('showRichiediModifica', false);
        }
        this.set('showRichiediNuovi', true);
        this.set('showFormNuovi', false);
      },
      //  ** FINE - Azione Nascondi Nuovi  Dati Fatturazione **

      //  ** Azione visualizza richiesta in sospeso Dati Fatturazione **
      visualizzaRichiesta: function visualizzaRichiesta(richiesta_id) {
        this.set('richiestaDati', this.store.findById('datifatturazionefornitorerichiestum', richiesta_id));
        _ember['default'].$('#modalRichiesta').modal('show');
      },
      //  ** FINE - Azione visualizza richiesta in sospeso Dati Fatturazione **

      //  ** Azione pulsante del modal Richiesta dati Fatturaizone **
      chiudiModalRichiesta: function chiudiModalRichiesta() {
        _ember['default'].$('#modalRichiesta').modal('hide');
      }
    }
  });
});
//  **  FINE - Azione pulsante del modal Richiesta dati Fatturaizone **

//  ** FINE - Action
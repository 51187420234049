define('portale-vigilanza/controllers/utente/nuovo', ['exports', 'ember', 'portale-vigilanza/mixins/utente/nuovo'], function (exports, _ember, _portaleVigilanzaMixinsUtenteNuovo) {
  exports['default'] = _ember['default'].Controller.extend(_portaleVigilanzaMixinsUtenteNuovo['default'], {

    namePage: 'utente',

    show_note: false,
    note: null,
    tipi_utenti: null,

    tipo_creazione: null,

    //verifica selezione tipologia
    hasTipoCreazione: _ember['default'].computed.notEmpty('tipo_creazione'),
    isValid: _ember['default'].computed.and('hasTipoCreazione'),
    activeProcedi: false,
    activeSignModifica: (function () {
      if (this.get('isValid')) {
        this.set('activeProcedi', true);
      } else {
        this.set('activeProcedi', false);
      }
    }).observes('tipo_creazione')

  });
});
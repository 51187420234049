define('portale-vigilanza/routes/indirizzos/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    actions: {
      remove: function remove(model) {
        if (confirm('Are you sure?')) {
          model.destroyRecord();
        }
      }
    },
    model: function model() {
      return this.store.findAll('indirizzo');
    }
  });
});
define('portale-vigilanza/controllers/ordini/dettagli', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    namePage: 'ordini',

    visualizzaDatiFatturazione: null,
    items: null,
    viewPaga: false,
    viewBonifico: false,

    actions: {

      //  ** Azione pulsante del modal **
      toggleConfirm: function toggleConfirm() {
        _ember['default'].$('#myModal').modal('hide');
      },

      facsimileBonifico: function facsimileBonifico() {
        this.set('errorModal', false);
        this.set('titoloModal', 'Dati Bonifico');

        var messaggio = '<p class="alert-info">';
        if (this.get('model.societa') == window.ENV.APP.V4Y_NomeSocieta) {
          messaggio += this.get('session.bonifico_vigilo4you');
        } else {
          messaggio += this.get('session.bonifico_vigilanza');
        }
        messaggio += '<br><b>Valore: </b>' + this.get('model.totale_iva') + ' €' + '<br><b>Causale:</b> numero ordine: ' + this.get('model.id') + ' - codice fatturazione: ' + this.get('model.codice_fatturazione') + '</p>';
        this.set('messaggioModal', messaggio);
        _ember['default'].$('#myModal').modal('show');
      },

      ripetiPagamento: function ripetiPagamento() {
        _ember['default'].$('body').addClass('loading');
        var _this = this;
        this.store.find('ordine', { ordine_id: this.get('model.id'), recupera: true }).then(function (ordini) {
          var url = false;
          ordini.forEach(function (ordine) {
            if (ordine.get('redirect_url')) {
              url = ordine.get('redirect_url');
            }
          });
          if (url) {
            window.location = url;
          } else {
            _this.set('modelError', _this.get('model'));
            _this.set('errorModal', true);
            _this.set('titoloModal', 'Recupero Pagamento');
            _this.set('messaggioModal', 'Errore nel recupero pagamento <br>');
            _ember['default'].$('body').removeClass('loading');
            _ember['default'].$('#myModal').modal('show');
          }
        }, function (error) {
          _this.set('modelError', _this.get('model'));
          _this.set('errorModal', true);
          _this.set('titoloModal', 'Recupero Pagamento');
          _this.set('messaggioModal', 'Errore nel recupero ordine <br>');
          _ember['default'].$('body').removeClass('loading');
          _ember['default'].$('#myModal').modal('show');
        });
      }
    }

  });
});
define('portale-vigilanza/components/js-range', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    insertRange: (function () {
      var input = '#' + this.get('id_range');

      _ember['default'].$(input).attr({
        min: this.get('min'),
        max: this.get('max'),
        step: this.get('step')
      });

      _ember['default'].$(input).val(0);

      _ember['default'].$(input).rangeslider({
        polyfill: false,
        // Default CSS classes
        rangeClass: 'rangeslider',
        disabledClass: 'rangeslider--disabled',
        horizontalClass: 'rangeslider--horizontal',
        verticalClass: 'rangeslider--vertical',
        fillClass: 'rangeslider__fill',
        handleClass: 'rangeslider__handle'
      });
    }).on("didInsertElement")

  });
});
define('portale-vigilanza/models/datifatturazionecliente', ['exports', 'portale-vigilanza/models/richiestamodifica'], function (exports, _portaleVigilanzaModelsRichiestamodifica) {
  exports['default'] = _portaleVigilanzaModelsRichiestamodifica['default'].extend({

    codice_plesso: DS.attr('string'),
    stato: DS.attr('string'),
    ragione_sociale: DS.attr('string'),
    nome: DS.attr('string'),
    codice_fiscale: DS.attr('string'),
    partita_iva: DS.attr('string'),
    flag_mail_pec: DS.attr('boolean', { defaultValue: false }),
    mail: DS.attr('string'),
    sede_legale_indirizzo: DS.attr('string'),
    sede_legale_cap: DS.attr('string'),
    sede_legale_comune: DS.attr('string'),
    sede_legale_provincia: DS.attr('string'),
    sede_legale_nazione: DS.attr('string', { defaultValue: 'IT' }),
    codice_assoggettamento_fiscale: DS.attr('string'),
    invio_fattura_ragione_sociale: DS.attr('string'),
    invio_fattura_ragione_sociale2: DS.attr('string'),
    invio_fattura_nome: DS.attr('string'),
    invio_fattura_indirizzo: DS.attr('string'),
    invio_fattura_cap: DS.attr('string'),
    invio_fattura_comune: DS.attr('string'),
    invio_fattura_provincia: DS.attr('string'),
    invio_fattura_nazione: DS.attr('string', { defaultValue: 'IT' }),

    nuovo: DS.attr('boolean'),

    codice_fattura: DS.attr('string'),

    // CREO LA VOCE PER IL MENU A TENDINA
    label_path: (function () {
      return this.get('id') + ' - ' + this.get('ragione_sociale');
    }).property('ragione_sociale')

  });
});
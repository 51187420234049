define('portale-vigilanza/controllers/application', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        currentPathChanged: (function () {
            window.scrollTo(0, 0);
        }).observes('currentPath'),

        actions: {
            annullaAttesa: function annullaAttesa() {
                _ember['default'].$('body').removeClass('loading');
                //this.send("refreshPage");
                location.reload();
            }
        },

        init: function init() {
            //Raven.config('https://7ca2e7718e8948f2be992cb318290220@sentry.3dsteam.it/13').addPlugin(Raven.Plugins.Ember).install();

            //Raven.config(window.ENV.APP.SENTRYURL,{environment: window.ENV.environment,release:window.ENV.APP.VERSIONE}).addPlugin(Raven.Plugins.Ember).install();
            this.updateNetworkStatus();
        },
        isOnline: true, // assume we're online until proven wrong
        onlineTimeMax: 600000,
        onlineTimeMin: 600,
        onLine: 6000,
        updateNetworkStatus: function updateNetworkStatus() {
            var appController = this;
            if (!navigator.onLine) {
                console.log('No Ping- no online');
                appController.set('isOnline', false);
                return; // return early, no point in pinging the server if we have no LAN
            }
            _ember['default'].$.get(window.ENV.APP.API_HOST + '/status').done(function () {
                // todo: consider checking the result
                console.log('Perfect! - we are online');
                appController.set('onLine', parseInt(appController.get('onlineTimeMax')));

                appController.set('isOnline', true);
            }).fail(function () {
                console.log('Fail - no online');
                if (parseInt(appController.get('onLine')) > parseInt(appController.get('onlineTimeMin'))) {
                    appController.set('onLine', parseInt(appController.get('onLine')) / 2);
                }
                appController.set('isOnline', false);
            }).always(function () {
                console.log(' updateNetworkStatus from' + appController.get('onLine'));
                _ember['default'].run.later(appController, 'updateNetworkStatus', appController.get('onLine'));
            });
            return true;
        },
        sentryBase: function sentryBase(strLevel, strKey, strMsg) {
            Raven.captureMessage(strMsg, {
                level: strLevel,
                tags: {
                    key: strKey
                }
            });
        },
        sentryInfo: function sentryInfo(strKey, strMsg) {
            console.log('sentryInfo');
            this.sentryBase('info', strKey, strMsg);
        },
        sentryError: function sentryError(strKey, strMsg) {
            this.sentryBase('error', strKey, strMsg);
        },
        sentryUser: function sentryUser(utente) {
            console.log('setUserContext');
            console.log(utente);
            Raven.setUserContext({
                id: utente.get('email'),
                email: utente.get('email'),
                gid: utente.get('id'),
                ruolo: utente.get('ruolo'),
                azienda: utente.get('azienda')
            });
        }
    });
});
define('portale-vigilanza/controllers/home', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].ArrayController.extend({
    namePage: 'home',

    queryParams: ['azione'],
    azione: null,

    //  ** Osservo se devo aprire il modal **
    caricaModal: (function () {
      if (this.get('azione') === 'agente_statuto') {
        setTimeout(function () {
          _ember['default'].$('#modalAgenteStatuto').modal('show');
        }, 500);
      }
    }).observes('azione'),

    actions: {
      //  ** Azione pulsante del modal **
      confermaStatuto: function confermaStatuto() {

        _ember['default'].$('#modalAgenteStatuto').modal('hide');

        this.set('azione', null);

        var messaggio = this.store.createRecord('messaggio');
        messaggio.set('tipologia', 'statutoPresaVisioneAccount');
        var dati = { user_id: this.get('session.id') };
        messaggio.set('dati', dati);

        messaggio.save();
      }

    }

  });
});
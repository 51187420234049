define('portale-vigilanza/mixins/datifatturazionefornitore/dati-fatturazione', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({

    setupController: function setupController(controller, model) {
      this._super(controller, model);

      //reimposto le variabili
      controller.set('showFormNuovi', false);
      controller.set('showDatiFatturazione', false);
      controller.set('showRichiediNuovi', true);
      controller.set('showRichiediModifica', false);
      controller.set('showRichiestaInCorso', false);
      controller.set('showElencoDatiFatturazione', false);

      //recupero i dati di fatturazione se esiste un codice_fatturazione
      //if( controller.get('richiedibile_type')!= null && controller.get('richiedibile_id')!= null){

      console.log('Route mixing');
      var _this = this;

      this.store.find('datifatturazionefornitore', { richiedibile_type: controller.get('richiedibile_type'), richiedibile_id: controller.get('session.id') }).then(function (dati) {
        if (dati.get('length') > 0) {

          dati.forEach(function (dato) {
            controller.set('datifatturazionefornitore', dato);
            controller.set('showRichiediModifica', true);
            controller.set('showDatiFatturazione', true);
          });

          //cerco se ci sono delle richieste Dati di Fatturazione in corso
          _this.store.unloadAll('datifatturazionefornitorerichiestum');
          _this.store.find('datifatturazionefornitorerichiestum', { richiedibile_type: controller.get('richiedibile_type'), richiedibile_id: controller.get('session.id') }).then(function (dati) {
            if (dati.get('length') > 0) {
              controller.set('showRichiestaInCorso', true);
              controller.set('showRichiediNuovi', false);
              controller.set('showRichiediModifica', false);
              dati.forEach(function (dato) {
                controller.set('id_richiestaInCorso', dato.get('id'));
              });
            }
          });
        }
      });

      //};
    }

  });
});
define('portale-vigilanza/controllers/veicoli', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].ArrayController.extend({

        namePage: 'veicoli',
        queryParams: ['id', 'guardia', 'cliente'],

        sortAscending: false,

        sortProperties: ["mostraConferma:desc", "isAllarm:desc", "eventoOra:asc", "username:asc"],
        sortedList: _ember['default'].computed.sort("model", "sortProperties"),

        numberAllarm: (function () {
            return this.get('model').get('length') - this.filterBy('eventoStato', '').get('length');
        }).property('@each.eventoStato'),

        numberConfirm: (function () {
            return this.filterBy('mostraConferma', true).get('length');
        }).property('@each.mostraConferma'),

        actions: {

            refresh: function refresh() {
                //this.get('model').update();
                //this.refresh();
                location.reload();
            },

            send_confirm: function send_confirm(account) {
                //account.set('confirm', true);
                //account.set('mostra_conferma',false);
                //account.save();

                //invio post con conferma dello stato
                var postConfirm = this.store.createRecord('confirmstatus');
                postConfirm.set('username', account.get('username'));
                postConfirm.set('eventId', account.get('eventId'));
                postConfirm.set('eventoStato', account.get('eventoStato'));
                postConfirm.set('confirm', true);
                postConfirm.save().then(function () {
                    account.set('mostraConferma', false);
                    account.set('isEditing', false);
                })['catch'](function (reason) {
                    console.log(reason);
                });
            }
        }

    });
});
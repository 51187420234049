define('portale-vigilanza/transforms/recapiti', ['exports', 'ember-data'], function (exports, _emberData) {

  // Converts centigrade in the JSON to fahrenheit in the app
  exports['default'] = _emberData['default'].Transform.extend({
    deserialize: function deserialize(serialized) {
      return serialized;
    },
    serialize: function serialize(deserialized) {
      return deserialized;
    }
  });
});
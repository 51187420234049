define('portale-vigilanza/routes/plessicliente/nuovo', ['exports', 'ember', 'simple-auth/mixins/authenticated-route-mixin', 'portale-vigilanza/mixins/checkMessage/check-message'], function (exports, _ember, _simpleAuthMixinsAuthenticatedRouteMixin, _portaleVigilanzaMixinsCheckMessageCheckMessage) {
  exports['default'] = _ember['default'].Route.extend(_simpleAuthMixinsAuthenticatedRouteMixin['default'], _portaleVigilanzaMixinsCheckMessageCheckMessage['default'], {

    model: function model() {
      this.store.unloadAll('creaplesso');
      return this.store.createRecord('creaplesso');
    },

    deactivate: function deactivate() {
      var model = this.modelFor('plessicliente/nuovo');
      if (model.get('isNew')) {
        model.destroyRecord();
        console.log('Pulizia modello');
      }
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);

      model.set('provincia', controller.get('provincia'));
      model.set('comune', controller.get('comune'));
      model.set('ragioneSociale', this.get('session.ragione_sociale'));

      controller.set('tipo', this.get('session.crea_plesso_tipoInserimento'));

      //Creo l'arrey dei tags
      controller.set('tags', null);
      if (this.get('session.show_tags')) {
        var elenco_tags = [];
        var tags = this.get('session.tags');
        var _this = this;
        tags.forEach(function (tag, index) {
          var suggerimenti = [];
          if (0 == index) {
            //suggerimenti = _this.get('session.suggerimentiTags')[0]
            suggerimenti = Object.keys(_this.get('session.suggerimentiTags'));
          }

          elenco_tags.push({
            etichetta: tag.etichetta,
            descrizione: tag.descrizione,
            placeholder: tag.placeholder,
            suggerimenti: suggerimenti
          });
        });

        controller.set('tags', elenco_tags);
      }

      /*
      controller.set('quota',  null);
      controller.set('checkCliente',  false);
      controller.set('nuovoCliente',  false);
      controller.set('vecchioCliente',  false);
      controller.set('vecchioClienteNoUtente',  false);
      controller.set('inserisciComeCliente',  false);
        //setto le variabili di controllo
      var list = [];
      var option = null;
        //cliente
      if(this.get('session.CreaPlesso_Cliente')){
        controller.set('CreaPlesso_Cliente',true );
        model.set('tipoinserimento','Cliente' );
        controller.set('checkCliente',  true);
        option = { id: 'Cliente', text: 'Nessun Agente'};
        list.push((option));
      }
        //procacciatore
      if(this.get('session.CreaPlesso_Procacciatore')){
        controller.set('CreaPlesso_Procacciatore',true );
        model.set('tipoinserimento','Procacciatore' );
        option = { id: 'Procacciatore', text: 'Con Referente Installazione'};
        list.push((option));
      }
      //installatore
      if(this.get('session.CreaPlesso_Installatore')){
        controller.set('CreaPlesso_Installatore',true );
        model.set('tipoinserimento','Installatore' );
        option = { id: 'Installatore', text: 'Standard '};
        list.push((option));
      }
          //Controllo se creo il plesso come cliente
      if( this.get('session.CreaPlesso_Cliente') && !this.get('session.CreaPlesso_Procacciatore') && !this.get('session.CreaPlesso_Installatore') && !this.get('session.CreaPlesso_Terzi') ){
        //controllo se ha i dati di fatturazione
        controller.set('showNuovoPlesso',false);
        this.store.unloadAll('datifatturazionecliente');
        this.store.find('datifatturazionecliente').then(function(dati){
          controller.set('showNuovoPlesso',true);
          dati.forEach(function(data){
            if(data.get('id')) {controller.set('showNuovoPlesso',false);}
          });
        });
          model.set('tipo','vecchioCliente' );
        controller.set('vecchioCliente',  true);
        controller.set('inserisciComeCliente',  true);
      }
        if(list.length>1) {controller.set('showTipoinserimento',true);}
      else  {controller.set('showTipoinserimento',false);}
        controller.set('listInserimenti',  list);
       */
    }

  });
});
define('portale-vigilanza/routes/ordini/nuovo', ['exports', 'ember', 'simple-auth/mixins/authenticated-route-mixin', 'portale-vigilanza/mixins/checkMessage/check-message'], function (exports, _ember, _simpleAuthMixinsAuthenticatedRouteMixin, _portaleVigilanzaMixinsCheckMessageCheckMessage) {
  exports['default'] = _ember['default'].Route.extend(_simpleAuthMixinsAuthenticatedRouteMixin['default'], _portaleVigilanzaMixinsCheckMessageCheckMessage['default'], {

    queryParams: {
      plesso: { refreshModel: true },
      tipologia: { refreshModel: true }
    },

    model: function model() {
      return this.store.createRecord('ordine');
    },

    deactivate: function deactivate() {
      this.store.unloadAll('prodotto');
      this.store.unloadAll('datifatturazionecliente');
      var model = this.modelFor('ordini/nuovo');
      if (model.get('isNew')) {
        model.destroyRecord();
      }
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);

      //reset delle variabili
      controller.set('isRicarica', false);
      controller.set('hasRicarica', false);
      controller.set('hasPagamento', false);
      controller.set('hasPaypal', false);
      controller.set('hasBonifico', false);
      controller.set('hasContanti', false);
      controller.set('hasDatiFatturazione', false);
      controller.set('showPaga', false);
      controller.set('showConferma', false);
      controller.set('creditoResiduo', this.get('session.crediti'));
      controller.set('hasProdotto', false);
      controller.set('showSpedizione', false);
      controller.set('hasSpedizione', true);
      controller.set('showSconto', false);
      controller.set('showErroreSconto', false);
      controller.set('showMessaggioSconto', false);
      controller.set('codice_sconto', '');
      controller.set('sconto_messaggio', '');
      controller.set('sconto_iva', '');
      controller.set('sconto_netto', '');
      controller.set('hasSconto', false);
      controller.set('check_activation', false);
      controller.set('activation', false);

      //carico i DATI DI FATTURAZIONE
      this.store.unloadAll('clientefatturaplesso');
      //this.store.find('clientefatturaplesso').then(function(data){
      this.store.query('clientefatturaplesso', { group_by: 'codice_fattura' }).then(function (data) {
        if (data.get('length') === 0) {
          _ember['default'].$('#modalDatiFatturazione').modal('show');
        } else {
          controller.set('listDatiFatturazione', data);

          if (data.get('length') === 1) {
            model.set('codice_fatturazione', data.get('content')[0]._data.codice_fattura);
          }
        }
      });

      //carico i PRODOTTI
      controller.set('numero_extra', 0);
      controller.set('totale_extra', 0);
      controller.set('totale_ivato_extra', 0);
      controller.set('ordine_quantita', 0);
      controller.set('ordine_totale', 0);
      controller.set('ordine_totale_iva', 0);

      controller.set('listExtra', null);

      if (controller.get('ricarica')) {
        controller.set('ricarica.quantita', 0);
      }
      if (controller.get('prodotto')) {
        controller.set('prodotto.quantita', 0);
      }

      this.store.unloadAll('prodotto');
      var arrayProdotti = [];

      if (controller.get('tipologia')) {

        this.store.query('nuovoordine', { tipologia: controller.get('tipologia'), plesso: controller.get('plesso') }).then(function (nuoviordine) {
          nuoviordine.forEach(function (ordine) {
            //Ciclo dei metodi di pagamento
            var metodi_pagamento = ordine.get('metodi_pagamento');
            metodi_pagamento.forEach(function (pagamento) {
              switch (pagamento) {
                case 'paypal':
                  controller.set('hasPaypal', true);
                  break;
                case 'bonifico':
                  controller.set('hasBonifico', true);
                  break;
                case 'contanti':
                  controller.set('hasContanti', true);
                  break;
              }
            });
            //Fine ciclo pagamenti

            //Ciclo dei prodotti
            controller.set('ordine_V4Y', false);
            controller.set('check_condizioni_V4Y', true);

            var prodotti = ordine.get('prodotto');
            //Imposto i prodotti
            prodotti.forEach(function (prod) {

              if (prod['tipologia'] === 'ricarica') {

                //E' una ricarica
                controller.set('isRicarica', true);
                controller.set('ricarica', prod);
                controller.set('hasRicarica', true);
              } else {

                if (prod['has_polizza']) {
                  controller.set('activation', true);
                  model.set('has_polizza', true);
                }
                //E' un prodotto
                var quantita = prod['quantita'] = parseInt(prod['quantita']);
                var costo = prod['costo'] = parseFloat(prod['costo']);
                var totale = quantita * costo;
                var totaleivato = totale + totale / 100 * prod['iva'];
                prod['totale'] = totale;
                prod['totale_ivato'] = totaleivato;
                controller.set('prodotto', prod);
                controller.set('hasProdotto', prod['tipologia'] !== 'accessori' ? true : false);

                //controllo se ha bisogno dell'indirizzo di spedizione
                if (prod['spedizione_required']) {
                  controller.set('showSpedizione', true);
                  controller.set('hasSpedizione', false);
                }
              }

              //controllo se è vigilo4Y
              if (prod['societa'] == window.ENV.APP.V4Y_NomeSocieta) {
                controller.set('ordine_V4Y', true);
                controller.set('check_condizioni_V4Y', false);
              }
            });
            //Fine ciclo prodotti

            //Imposto gli extra
            var extras = ordine.get('extra');
            var arrayExtras = [];
            if (extras.length) {

              extras.forEach(function (extra) {
                extra.showQuantita = true;
                extra.show = false;

                //controllo se ha bisogno dell'indirizzo di spedizione
                if (extra.spedizione_required) {
                  controller.set('showSpedizione', true);
                  controller.set('hasSpedizione', false);
                }
                //controllo se è vigilo4Y
                if (extra.societa == window.ENV.APP.V4Y_NomeSocieta) {
                  controller.set('ordine_V4Y', true);
                  controller.set('check_condizioni_V4Y', false);
                }

                arrayExtras.push(extra);
              });
              controller.set('listExtra', arrayExtras);
            } else {

              controller.set('listExtra', null);
            }
            //FINE extra

            //Verifico lo sconto
            var sconto = ordine.get('sconto');
            if (sconto['abilita']) {
              controller.set('showSconto', true);
            }
            //FINE sconto
          });
        });
      }
    }

  });
});
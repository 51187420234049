define('portale-vigilanza/routes/confermaattivazione', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({

    model: function model() {
      return this.store.createRecord('confermaattivazione');
    },

    deactivate: function deactivate() {
      var model = this.modelFor('confermaattivazione');
      if (model.get('isNew')) {
        model.destroyRecord();
      }
    }

  });
});
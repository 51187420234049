define('portale-vigilanza/models/user', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({

    nominativo: _emberData['default'].attr('string'),
    email: _emberData['default'].attr('string'),
    codiceFatturazione: _emberData['default'].attr('string'),
    codiceFiscale: _emberData['default'].attr('string'),
    quota: _emberData['default'].attr('string'),
    ruolo: _emberData['default'].attr('string'),
    azienda: _emberData['default'].attr('string')

  });
});
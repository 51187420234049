define('portale-vigilanza/controllers/plessicliente/nuovo', ['exports', 'ember', 'portale-vigilanza/mixins/utente/nuovo'], function (exports, _ember, _portaleVigilanzaMixinsUtenteNuovo) {
  exports['default'] = _ember['default'].Controller.extend(_portaleVigilanzaMixinsUtenteNuovo['default'], {

    queryParams: ['provincia', 'comune'],

    arrayTipologie: window.ENV.APP.Tipologie_plesso,

    contatore: 0,

    abilitaCrea: true,
    provvigioneRimanente: 0,
    agente_corrente: null,

    namePage: 'nuovoplesso',

    listInserimenti: null,
    showTipoinserimento: false,

    CreaPlesso_Cliente: false,
    CreaPlesso_Procacciatore: false,
    CreaPlesso_Installatore: false,
    CreaPlesso_Terzi: false,

    checkCliente: false,

    titoloModal: 'Nuovo Plesso',
    messaggioModal: '',
    errorModal: false,

    nuovoCliente: false,
    vecchioCliente: false,
    vecchioClienteSenzaUtente: false,
    vecchioClienteNoUtente: false,
    list_Province: null,
    listComuni: null,
    listInstallatori: null,

    inserisciComeCliente: false,
    show_selezione_cliente: false,

    agenti: null,

    tags: null,

    init: function init() {
      this.set('provvigioneRimanente', this.get('session.MaxQuota'));
      //recupero l'elenco delle procince
      this.set('list_Province', this.store.peekAll('province'));
      //Verifico il tipo d'inserimento
      this.send('verificaTipoInserimento');
    },

    //  ** Modifica session.crea_plesso_tipo **
    cambiaTipoInserimento: (function () {
      this.send('verificaTipoInserimento');
    }).observes('session.crea_plesso_tipo'),

    //  ** MODIFICA QUOTA **
    cambiaQuota: (function () {

      var totale = 0;
      //sommo le provvigioni
      console.log("cambiaQuota");
      console.log(this.get('agenti'));
      this.get('agenti').forEach(function (agente) {
        if (agente.provvigione) totale += parseFloat(agente.provvigione);
      });
      //calcolo la rimanenza
      var rimanenza = parseFloat(this.get('session.MaxQuota')) - totale;
      this.set('provvigioneRimanente', rimanenza);
      //controllo la rimanenza
      if (rimanenza < 0) {
        this.set('abilitaCrea', false);
        this.set('provvigioneRimanente', 0);
      } else this.set('abilitaCrea', true);
    }).observes('agenti.@each.provvigione'),

    actions: {

      onKeyPressTag: function onKeyPressTag(tag) {
        var new_valore = tag.valore.replace('_', '');
        _ember['default'].set(tag, 'valore', new_valore);
        this.send('setSuggerimenti');
      },

      setTag: function setTag(sug, tag) {
        _ember['default'].set(tag, 'valore', sug);
        this.send('onKeyPressTag', tag);
        /*    this.send('onKeyPressTag',val,index);
            var newIndex = index+1;
          var mytags = this.get('session.tags');
          if(mytags[index+1] != undefined){
              console.log(mytags[newIndex].suggerimenti[sug]);
              this.set('tags.'+newIndex, mytags[index+1].suggerimenti[sug] );
          }
        */
      },

      setSuggerimenti: function setSuggerimenti() {

        var sugg = this.get('session.suggerimentiTags');
        /*
        for (i = 1; i < sugg.length; i++) {
              //text += cars[i] + "<br>";
            if(i > index ){
                this.set('suggerimenti'+i, [] );
            }else{
              }
        }
        */
        //var sugArray  =  Object.keys(sugg);
        var tags = this.get('tags');
        var valori = [];
        console.log('_____ sugg _____');
        console.log(sugg);
        tags.forEach(function (tag, index) {
          console.log('________ per ' + _ember['default'].get(tag, 'valore'));
          console.log(index);
          console.log(_ember['default'].get(tag, 'suggerimenti'));
          if (sugg == null) {
            //tag.set('suggerimenti', sugg[0]);
            //tag.set('suggerimenti', sugg[0]);
            _ember['default'].set(tag, 'suggerimenti', []);
          } else {
            if (Array.isArray(sugg)) {
              _ember['default'].set(tag, 'suggerimenti', sugg);
            } else {
              _ember['default'].set(tag, 'suggerimenti', Object.keys(sugg));
            }

            if (tag.valore in sugg) {
              sugg = sugg[tag.valore];
            } else {
              sugg = null;
            }
          }

          console.log(_ember['default'].get(tag, 'suggerimenti'));
          console.log('Nuovo Sugg');
          console.log(sugg);

          /* if(oldTag == null){
               //tag.set('suggerimenti', sugg[0]);
               //tag.set('suggerimenti', sugg[0]);
               Ember.set(tag, 'suggerimenti',  sugg[0]);
           }else{
               if( oldTag.valore != '' &&  oldTag.valore in sugg[index]){
                   //tag.set('suggerimenti', sugg[index][oldTag.valore]);
                   Ember.set(tag, 'suggerimenti',  sugg[index][oldTag.valore]);
                 }else{
                   //tag.set('suggerimenti', []);
                   Ember.set(tag, 'suggerimenti',  []);
               }
           }
             console.log( Ember.get(tag,'suggerimenti') );
           oldTag = tag;
           */
        });
      },

      cambiacliente: function cambiacliente() {

        this.set('tipo', null);
        this.set('model.tipo', null);
      },

      verificaTipoInserimento: function verificaTipoInserimento() {

        var array_tipologie = this.get('session.crea_plesso_tipologie');
        var tipologia = this.get('session.crea_plesso_tipo');
        var _this = this;

        array_tipologie.filter(function (obj) {

          if (obj.id == tipologia) {
            _this.set('agente_corrente', obj.agente_corrente);
            if (obj.cliente_corrente) {
              _this.set('inserisciComeCliente', true);
              _this.set('show_selezione_cliente', false);
              _this.set('tipo', 'vecchioCliente');
              _this.set('cliente_corrente', obj.cliente_corrente);
            } else {
              _this.set('inserisciComeCliente', false);
              _this.set('show_selezione_cliente', true);
            }
            _this.set('agenti', obj.agenti);
          }
        });
      },

      //  ** Azione pulsante del modal **
      toggleConfirm: function toggleConfirm() {
        _ember['default'].$('#myModal').modal('hide');
        this.transitionToRoute('home');
      },

      //  ** Azione check codice fiscale del cliente **
      checkCliente: function checkCliente() {

        //richiedo che tipo di cliente è
        var _this = this;
        this.get('model').save().then(function (data) {
          var tipo = _this.get('model.tipo');
          var cf = _this.get('model.codiceFiscale');
          var tipoinserimento = _this.get('model.tipoinserimento');
          var provincia = _this.get('model.provincia');
          var comune = _this.get('model.comune');
          var nominativo = _this.get('model.nominativo');
          var email = _this.get('model.email');
          var codiceFatturazione = _this.get('model.codiceFatturazione');
          var tipologia_plesso = _this.get('model.tipologia_plesso');
          var ragioneSociale = _this.get('model.ragioneSociale');
          var cap = _this.get('model.cap');
          var indirizzo = _this.get('model.indirizzo');
          var civico = _this.get('model.civico');
          var cel_plesso = _this.get('model.cel_plesso');
          var tel_plesso = _this.get('model.tel_plesso');

          //pulisco il vecchio modello
          _this.store.unloadAll('creaplesso');

          _this.set('model', _this.store.createRecord('creaplesso'));

          _this.set('model.tipo', tipo);
          _this.set('tipo', tipo);
          _this.set('model.codiceFiscale', cf);
          _this.set('model.tipoinserimento', tipoinserimento);
          _this.set('model.provincia', provincia);
          _this.set('model.comune', comune);
          _this.set('model.nominativo', nominativo);
          _this.set('model.email', email);
          _this.set('model.codiceFatturazione', codiceFatturazione);
          _this.set('model.tipologia_plesso', tipologia_plesso);
          _this.set('model.ragioneSociale', ragioneSociale);
          _this.set('model.cap', cap);
          _this.set('model.indirizzo', indirizzo);
          _this.set('model.civico', civico);
          _this.set('model.cel_plesso', cel_plesso);
          _this.set('model.tel_plesso', tel_plesso);
          _this.set('show_selezione_cliente', false);
          switch (tipo) {
            case 'nuovoCliente':
              _this.set('nuovoCliente', true);
              break;
            case 'vecchioCliente':
              _this.set('vecchioCliente', true);
              break;
            case 'vecchioClienteSenzaUtente':
              _this.set('vecchioClienteSenzaUtente', true);
              break;
          }
        }, function (error) {
          _this.set('modelError', _this.get('model'));
          _this.set('errorModal', true);
          _this.set('messaggioModal', 'Errore nella verifica');
          _ember['default'].$('#myModal').modal('show');
        });
      },

      //  *** RICHIESTE DELLE SELECT
      // per creare metodi dinamicamente, ma le actions?
      // http://stackoverflow.com/questions/22820811/dynamically-add-properties-to-a-controller-in-ember-js

      queryTipologia: function queryTipologia(query, deferred, item) {
        this.store.find('user', {
          nominativo: query.term,
          ruolo: item.tipologia_selezionata.id
        }).then(function (data) {
          deferred.resolve(data);
        }, deferred.reject);
      },
      //  *** FINE -> RICHIESTE DELLE SELECT

      //  ** Salvataggio modello CreaPlessi **
      save: function save() {

        this.set('creation', true);

        var _this = this;
        var error = false;
        var messaggioErrore = '';

        //controllo i dati generici
        if (!this.get('model.ragioneSociale') && this.get('tipo') != 'vecchioCliente') {
          messaggioErrore += '- manca la ragione sociale <br>';
          error = true;
        }
        //if(!this.get('model.ragioneSociale')) { messaggioErrore += '- manca la ragione sociale <br>'; error = true; }
        if (!this.get('model.provincia')) {
          messaggioErrore += '- manca la provincia <br>';
          error = true;
        }
        if (!this.get('model.comune')) {
          messaggioErrore += '- manca il comune <br>';
          error = true;
        }
        if (!this.get('model.cap')) {
          messaggioErrore += '- manca il cap <br>';
          error = true;
        }
        if (!this.get('model.indirizzo')) {
          messaggioErrore += '- manca l\'indirizzo <br>';
          error = true;
        }
        if (!this.get('model.civico')) {
          messaggioErrore += '- manca il numero civico <br>';
          error = true;
        }
        if (!this.get('model.cel_plesso')) {
          messaggioErrore += '- inserire il numero di cellulare operativo<br>';
          error = true;
        }

        var array_agenti = [];
        //controllo se c'è da aggiungere anche se stesso
        if (this.get('agente_corrente')) {
          var user_id = this.get('session.id');
          var provvigione = this.get('provvigioneRimanente');
          var agente = {
            tipo: this.get('session.tipo_creatore_corrente'),
            user_id: user_id,
            quota: provvigione
          };
          array_agenti.push(agente);
        }
        //Aggiunta degli eventuali agenti al plesso
        if (this.get('agenti')) {
          this.get('agenti').forEach(function (agente) {
            if (agente.agente_selezionato) {
              if (agente.provvigione) {
                var agente = {
                  tipo: agente.tipologia_selezionata.id,
                  user_id: agente.agente_selezionato.id,
                  quota: agente.provvigione
                };
                array_agenti.push(agente);
              } else {
                error = true;
                messaggioErrore += '- non hai selezionato la provvigione <br>';
              }
            } else {
              error = true;
              messaggioErrore += '- non hai selezionato l\'agente <br>';
            }
          });
        }

        //imposto lo user
        this.set('model.user_creator_id', this.get('session.id'));
        //imposto gli agenti
        this.set('model.agenti', JSON.stringify(array_agenti));
        //imposto il tipo cliente
        this.set('model.tipo', this.get('tipo'));
        //imposto il tipo inserimento
        this.set('model.tipoinserimento', this.get('session.crea_plesso_tipo'));

        //creo il tag
        var tags = '';
        if (this.get('tags')) {
          this.get('tags').forEach(function (tag) {
            tags += tag.valore + '_';
          });
        }
        tags = tags.substring(0, tags.length - 1); // tolgo l'ultimo _
        this.set('model.tag', tags);

        if (!error) {

          //salvo il modello
          this.get('model').save().then(function (response) {
            console.log(_this.get('session.tipo_creatore_corrente'));
            console.log(_this.get('session.crea_plesso_tipo'));

            if (_this.get('session.crea_plesso_tipo') === "UnAgente" || _this.get('session.crea_plesso_tipo') === "SelfAgente" && _this.get('session.tipo_creatore_corrente') != 'istituto_amministrativo') {
              /** è un agente */
              if (_this.get('session.tipo_creatore_corrente') != 'installatore') {
                _this.transitionToRoute('plessicommercialeinterno.index');
              } else {
                //if(_this.get('session.tipo_creatore_corrente') === 'istituto_amministrativo')
                _this.transitionToRoute('plessiquote.index');
              }
            } else if (_this.get('session.crea_plesso_tipo').toLowerCase().includes("azienda") || _this.get('session.crea_plesso_tipo').toLowerCase().includes("istituto"))
              /** è un'azienda o venditore_azeinda */
              _this.transitionToRoute('plessiquote.index');else
              /** è un cliente */
              _this.transitionToRoute('plessicliente.index');
          }, function (error) {
            _this.set('modelError', _this.get('model'));
            _this.set('errorModal', true);
            _this.set('messaggioModal', 'Errore');
            _ember['default'].$('#myModal').modal('show');
          });
        } else {

          this.set('errorModal', true);
          this.set('messaggioModal', 'Errore nei dati inseriti: <br /> <p class="alert-danger">' + messaggioErrore + '</p>');
          _ember['default'].$('#myModal').modal('show');
        }
      } // Fine funzione save()

    } // Fine Actions

  });
});
define('portale-vigilanza/models/plessiistituto', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({

    ragione_sociale: _emberData['default'].attr('string'),
    categoria: _emberData['default'].attr('string'),
    codice_fatturazione_1: _emberData['default'].attr('string'),
    codice_fatturazione_2: _emberData['default'].attr('string'),
    tipologia_contratto: _emberData['default'].attr('string'),
    lat: _emberData['default'].attr('string'),
    long: _emberData['default'].attr('string'),
    mail1: _emberData['default'].attr('string'),
    mail2: _emberData['default'].attr('string'),
    attesa_chiavi1: _emberData['default'].attr('string'),
    tag: _emberData['default'].attr('string'),
    codice_chiavi1: _emberData['default'].attr('string'),
    attesa_chiavi2: _emberData['default'].attr('string'),
    codice_chiavi2: _emberData['default'].attr('string'),
    sede: _emberData['default'].attr('string'),
    isAttivo: _emberData['default'].attr('boolean'),
    attivato_at: _emberData['default'].attr('string'),
    attivato_ago: _emberData['default'].attr('string'),
    quota: _emberData['default'].attr('string'),
    agenti: _emberData['default'].attr('agenti'),
    comune: _emberData['default'].attr('string'),
    provincia: _emberData['default'].attr('string')

  });
});
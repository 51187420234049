define('portale-vigilanza/helpers/button-allarm', ['exports', 'ember'], function (exports, _ember) {
    exports.buttonAllarm = buttonAllarm;

    function buttonAllarm(evento_stato, evento_stato_ora, evento_eta) {
        var button = '';
        var colore = '';
        var icona = '';
        var stato = '';

        switch (evento_stato) {
            case "0":
                colore = "class='btn btn-allarm azzurro'";
                icona = "class='fa fa-location-arrow fa-fw fa-lg pull-left'";
                stato = 'Inviato';
                evento_eta = '';
                break;
            case "1":
                colore = "class='btn btn-allarm azzurro'";
                icona = "class='fa fa-location-arrow fa-fw fa-lg pull-left'";
                stato = 'Ricevuto';
                evento_eta = '';
                break;
            case "2":
                colore = "class='btn btn-allarm verde'";
                icona = "class='fa fa-location-arrow fa-fw fa-lg pull-left'";
                stato = 'Confermato';
                evento_eta = '';
                break;
            case "3":
                colore = "class='btn btn-allarm verde'";
                icona = "class='fa fa-home fa-fw fa-lg pull-left'";
                stato = 'Check in';
                evento_eta = '';
                break;
            case "4":
                colore = "class='btn btn-allarm rosso'";
                icona = "class='fa fa-home fa-fw fa-lg pull-left'";
                stato = 'Check out';
                evento_eta = '';
                break;
            case "5":
                colore = "class='btn btn-allarm giallo'";
                icona = "class='fa fa-times fa-fw fa-lg pull-left'";
                stato = 'Revoca inviata';
                evento_eta = '';
                break;
            case "6":
                colore = "class='btn btn-allarm giallo'";
                icona = "class='fa fa-times fa-fw fa-lg pull-left'";
                stato = 'Revoca inviata';
                evento_eta = '';
                break;
            case "7":
                colore = "class='btn btn-allarm blu'";
                icona = "class='fa fa-check fa-fw fa-lg pull-left'";
                stato = 'Evento chiuso';
                evento_eta = '';
                break;
            case "spento":
                colore = "class='btn btn-allarm rosso'";
                icona = "class='fa fa-power-off fa-fw fa-lg pull-left'";
                stato = 'Spento';
                evento_eta = '';
                break;
            case "timeout":
                colore = "class='btn btn-allarm rosso'";
                icona = "class='fa fa-spinner fa-fw fa-lg pull-left'";
                stato = 'Time out';
                evento_eta = '';
                break;
            default:
                return new _ember['default'].Handlebars.SafeString(button);
        }

        button = "<div " + colore + " >";
        button += "<span class='pull-left'><i " + icona + " ></i></span>";
        button += "<span class='pull-right'>" + evento_stato_ora + "</span><br />";
        button += "<span class='pull-left'>" + stato + "</span>";
        button += "<span class='pull-right'>" + evento_eta + "</span></span>";
        button += "</div>";

        return new _ember['default'].Handlebars.SafeString(button);
    }

    exports['default'] = _ember['default'].Handlebars.makeBoundHelper(buttonAllarm);
});
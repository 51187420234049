define('portale-vigilanza/components/btn-popover', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    insertPopover: (function () {

      _ember['default'].$('.' + this.get('id')).popover({
        html: true
      });
    }).on("didInsertElement")

  });
});
define('portale-vigilanza/routes/utente/nuovo', ['exports', 'ember', 'simple-auth/mixins/authenticated-route-mixin', 'portale-vigilanza/mixins/checkMessage/check-message'], function (exports, _ember, _simpleAuthMixinsAuthenticatedRouteMixin, _portaleVigilanzaMixinsCheckMessageCheckMessage) {
  exports['default'] = _ember['default'].Route.extend(_simpleAuthMixinsAuthenticatedRouteMixin['default'], _portaleVigilanzaMixinsCheckMessageCheckMessage['default'], {

    model: function model() {},

    deactivate: function deactivate() {},

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      /*
          //reset variabili
          controller.set('show_note',false);
          controller.set('note',null);
          controller.set('tipi_utenti',null);
      
          //CARICO L'ELENCO DEI TIPI DI UTENTI
          var configurazioni = this.get('session').get('configurazioni');
          console.log('Configurazioni: '+configurazioni);
          if(configurazioni){
            var crea_utente = configurazioni['crea_utente'];
            if(crea_utente){
      
              if(crea_utente.Nota){
                controller.set('show_note',true);
                controller.set('note',crea_utente.Nota);
              }
              var array = crea_utente.tipologia;
              //console.log(array.get('length'));
              controller.set('tipi_utenti',array);
              console.log('Fatto')
              console.log(crea_utente.tipologia)
      
            }
          }
          */
    }

  });
});
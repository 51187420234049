define('portale-vigilanza/routes/verificacopertura', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('provincia_copertura', null);
      controller.set('comune_copertura', null);
      controller.set('step1', true);
      controller.set('step2', false);
      controller.set('step3', false);
      controller.set('showVerifica', false);
      controller.set('AltriServizi', false);
      controller.set('showAltriServizi', false);
      controller.set('showProcedi', false);
    }

  });
});
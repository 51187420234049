define('portale-vigilanza/routes/ordini/dettagli', ['exports', 'ember', 'simple-auth/mixins/authenticated-route-mixin', 'portale-vigilanza/mixins/checkMessage/check-message'], function (exports, _ember, _simpleAuthMixinsAuthenticatedRouteMixin, _portaleVigilanzaMixinsCheckMessageCheckMessage) {
  exports['default'] = _ember['default'].Route.extend(_simpleAuthMixinsAuthenticatedRouteMixin['default'], _portaleVigilanzaMixinsCheckMessageCheckMessage['default'], {

    model: function model(params) {
      this.store.unloadAll('ordine');
      return this.store.findById('ordine', params.ordine_id);
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);

      //recupero i dati di fatturazione
      controller.set('visualizzaDatiFatturazione', this.store.findById('datifatturazionecliente', model.get('codice_fatturazione')));

      //creo gli items corretti
      var items = model.get('items');
      if (items) {
        console.log(items);
        var new_items = [];
        items.forEach(function (item) {

          var costo_ivato = item.costo_iva.split('.');
          var totaleivato_intero = costo_ivato[0] * item.quantita;
          var totaleivato_decimale = costo_ivato[1] * item.quantita;
          totaleivato_decimale = totaleivato_decimale / 100;
          var totale_ivato = totaleivato_intero + totaleivato_decimale;
          totale_ivato = Math.floor(totale_ivato * 100);
          totale_ivato = totale_ivato / 100;
          console.log('desc breve ' + item.descrizione_breve);
          var new_item = {
            descrizione: item.descrizione,
            descrizione_breve: item.descrizione_breve,
            plesso_id: item.plesso_id,
            quantita: item.quantita,
            costo_iva: item.costo_iva,
            iva: item.iva,
            totale_ivato: totale_ivato
          };
          new_items.push(new_item);
        });
        controller.set('items', new_items);
      } else {
        controller.set('items', null);
      }

      //controllo se visualizzare pulsante paga ancora
      if (model.get('stato') === 'pendente' && model.get('metodo_pagamento_id') === 'paypal') {
        controller.set('viewPaga', true);
      } else {
        controller.set('viewPaga', false);
      }

      //controllo se visualizzare pulsante bonifico
      if (model.get('stato') === 'pendente' && model.get('metodo_pagamento_id') === 'bonifico') {
        controller.set('viewBonifico', true);
      } else {
        controller.set('viewBonifico', false);
      }

      var totale_netto = parseFloat(model.get('totale_netto')) * 100;
      var totale_iva = parseFloat(model.get('totale_iva')) * 100;
      var valore_iva = (totale_iva - totale_netto) / 100;
      controller.set('valore_iva', valore_iva);
      //Controllo se c'è lo sconto
      if (model.get('totale_sconto') > 0) {
        controller.set('showSconto', true);
        var totale_sconto = parseFloat(model.get('totale_sconto')) * 100;
        var totale_non_scontato = (totale_iva + totale_sconto) / 100;
        controller.set('totale_non_scontato', totale_non_scontato);
      } else {
        controller.set('showSconto', false);
      }
    }

  });
});
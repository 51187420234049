define('portale-vigilanza/models/gestione', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({

    codice_plesso: _emberData['default'].attr('string'),
    data_apertura: _emberData['default'].attr('string'),
    codice_apertura: _emberData['default'].attr('string'),
    data_chiusura: _emberData['default'].attr('string'),
    codice_chiusura: _emberData['default'].attr('string'),
    tipologia: _emberData['default'].attr('string'),
    crediti_scalati: _emberData['default'].attr('string')

  });
});
define('portale-vigilanza/models/vigilanzastandard', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({

    note_intervento: _emberData['default'].attr('string'),
    controlloe: _emberData['default'].attr('string'),
    controlloi: _emberData['default'].attr('string'),
    avista: _emberData['default'].attr('string'),
    orologi: _emberData['default'].attr('string'),
    serdiufer: _emberData['default'].attr('string'),
    serdiupre: _emberData['default'].attr('string'),
    serdiufes: _emberData['default'].attr('string'),
    sernotfer: _emberData['default'].attr('string'),
    sernotpre: _emberData['default'].attr('string'),
    sernotfes: _emberData['default'].attr('string'),
    accettazione_contratto: _emberData['default'].attr('boolean', { defaultValue: true }),

    showControlloe: (function () {
      if (this.get('controlloe') == 'N') return false;else return true;
    }).property('controlloe'),

    showControlloi: (function () {
      if (this.get('controlloi') == 'N') return false;else return true;
    }).property('controlloi'),

    showAvista: (function () {
      if (this.get('avista') == 'N') return false;else return true;
    }).property('avista'),

    showOrologi: (function () {
      if (this.get('orologi') == "0") return false;else return true;
    }).property('orologi')

  });
});
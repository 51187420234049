define('portale-vigilanza/controllers/index', ['exports', 'ember'], function (exports, _ember) {
  //import permission from '../objects/permission';

  exports['default'] = _ember['default'].Controller.extend({

    queryParams: ['theme', 'id', 'pwd', 'weblogin', 'mode'],
    theme: null,
    mode: null,
    isVigilo: false,
    needs: ['application'],
    titoloModal: 'Login',
    messaggioModal: '',
    errorModal: false,

    hasEmail: _ember['default'].computed.notEmpty('identification'),
    hasPassword: _ember['default'].computed.notEmpty('password'),
    isValid: _ember['default'].computed.and('hasEmail', 'hasPassword'),
    signActive: false,
    activeSign: (function () {
      if (this.get('isValid')) {
        this.set('signActive', true);
      } else {
        this.set('signActive', false);
      }
    }).observes('identification', 'password'),

    checkTheme: (function () {
      if (sessionStorage.getItem('theme') === 'vigilo4you') {
        this.set('isVigilo', true);
      } else {
        this.set('isVigilo', false);
      }
    }).observes('theme'),
    init: function init() {

      if (sessionStorage.getItem('theme')) this.set('theme', sessionStorage.getItem('theme'));

      //recupero l'elenco delle province
      if (localStorage.idUser && localStorage.pwdUser) {
        localStorage.checkAutologin = 'true';
        this.set('weblogin', 'true');
      }
    },
    checkAutologin: (function () {
      // non funziona, da rivedere
      console.log('checkAutologin');

      if (localStorage.checkAutologin != undefined && localStorage.checkAutologin === 'true') {
        console.log('checkAutologin');
        this.set('identification', localStorage.idUser);
        this.set('password', localStorage.pwdUser);

        localStorage.removeItem('checkAutologin');
        localStorage.removeItem('pwdUser');
        localStorage.removeItem('idUser');

        console.log(localStorage);
        console.log('Forzo WEB LOGIN 2');

        this.send('authenticate');
        console.log('authenticate');
      } else if (this.get('weblogin') === 'true') {
        console.log('weblogin');
        if (this.get('session').isAuthenticated) {
          console.log('invalidateSession');

          localStorage.idUser = this.get('id');
          localStorage.pwdUser = this.get('pwd');
          console.log(localStorage);
          this.get('session').invalidate();
          console.log(localStorage);
        } else {

          this.set('identification', this.get('id'));
          this.set('password', this.get('pwd'));
          this.send('authenticate');
          console.log('authenticate');
        }
      }

      if (this.get('session').isAuthenticated) {
        console.log('transitionTo home');
        this.transitionTo('home');
      }
    }).observes('weblogin'),
    checkMode: (function () {
      if (this.get('mode') === 'recuperapwd') this.send('recuperaPassword');
    }).observes('mode'),

    showMail: false,
    showLoading: false,

    authenticator: 'authenticator:django-rest',
    actions: {

      formatElenco: function formatElenco(input, output) {
        var array = $.map(input, function (value, index) {
          var voce = {
            id: index,
            text: value
          };
          return voce;
        });
        this.set(output, array);
      },

      //  ** Azione pulsante del modal **
      toggleConfirm: function toggleConfirm() {
        _ember['default'].$('#myModal').modal('hide');
        this.set('showMail', false);
        this.set('email', '');
      },

      //  ** Azione visualizza redirect **
      avvisoCambio: function avvisoCambio() {
        _ember['default'].$('#modalRedirect').modal('show');
        var _this = this;
        setTimeout(function () {
          _this.set('isVigilo', false);
          _ember['default'].$('#modalRedirect').modal('hide');
        }, 2000);
      },

      authenticate: function authenticate() {
        var _this = this;

        var data = this.getProperties('identification', 'password');
        this.get('session').authenticate('authenticator:django-rest', data).then(function () {

          //***VARIABILI GENERALI
          _this.set('session.view_message', true);
          _this.set('session.count_check_message', window.ENV.APP.NCheckMessage);
          _this.set('session.showreloadconf', window.ENV.APP.SHOWRELOADCONF);
          //_this.set('session.MaxQuota',window.ENV.APP.MaxQuota);
          _this.set('session.MessaggioCompletaTestImpianto', window.ENV.APP.MessaggioCompletaTestImpianto);
          _this.set('session.MessaggioRipartizioneProvvigione', window.ENV.APP.MessaggioRipartizioneProvvigione);

          //***DATI UTENTE
          _this.store.find('currentuser').then(function (utenti) {
            utenti.forEach(function (utente) {
              _this.set('session.id', utente.get('id'));
              _this.set('session.nominativo', utente.get('nominativo'));
              _this.set('session.email', utente.get('email'));
              _this.set('session.cellulare', utente.get('telefono'));
              _this.set('session.telefono_confermato', utente.get('telefono_confermato'));
              _this.set('session.checkbox_1', moment(utente.get('checkbox_1')).format("DD/MM/YYYY, HH:mm"));
              _this.set('session.checkbox_2', moment(utente.get('checkbox_2')).format("DD/MM/YYYY, HH:mm"));
              _this.set('session.checkbox_3', moment(utente.get('checkbox_3')).format("DD/MM/YYYY, HH:mm"));

              _this.set('session.temp_cell', utente.get('temp_cell'));
              _this.set('session.documento_utente_presente', utente.get('documento_utente_presente'));
              // todo implementare warning per crediti insufficienti al servizio di costo massimo #crediti_ok
              _this.set('session.crediti', utente.get('crediti'));
              _this.set('session.costo_minimo_crediti', utente.get('costo_minimo_crediti'));
              if (parseInt(_this.get('session.crediti')) > parseInt(_this.get('session.costo_minimo_crediti'))) {
                _this.set('session.crediti_ok', true);
              } else {
                _this.set('session.crediti_ok', false);
              }

              if (Math.floor(parseInt(_this.get('session.crediti')) / parseInt(_this.get('session.costo_minimo_crediti'))) === 1) {
                //console.log(" un solo inter");
                _this.set('session.crediti_uno', true);
              } else {
                _this.set('session.crediti_uno', false);
                //console.log(" un solo inter!!!!!");
              }

              _this.set('session.is_vigilo', utente.get('is_vigilo'));
              _this.set('session.is_vigilo_attivo', utente.get('is_vigilo_attivo'));
              _this.set('session.is_royalty', utente.get('is_royalty'));
              _this.set('session.fatturazione_fornitore', utente.get('fatturazione_fornitore'));

              _this.get('controllers.application').sentryUser(utente);
              _this.get('controllers.application').sentryInfo('Login', 'Login eseguito con successo');
            });
          });

          //***GRUPPI
          _this.store.find('group').then(function (groups) {
            groups.forEach(function (group) {
              switch (group.get('name')) {
                case 'cliente':
                  _this.set('session.isCliente', true);
                  break;
                case 'procacciatore':
                  _this.set('session.isProcacciatore', true);
                  break;
                case 'installatore':
                  _this.set('session.isInstallatore', true);
                  break;
                default:
                  _this.set('session.idUser', group.get('name'));
              }
            });
          });

          //***PERMESSI
          _this.store.find('permission').then(function (permissions) {

            permissions.forEach(function (permission) {
              switch (permission.get('name')) {

                case 'Vedi_fatture':
                  _this.set('session.Vedi_fatture', true);
                  break;
                case 'VediPlessi_Completi':
                  _this.set('session.VediPlessi_Completi', true);
                  break;
                case 'VediPlessi_Quote':
                  _this.set('session.VediPlessi_Quote', true);
                  break;
                case 'VediPlessi_Istituto':
                  _this.set('session.VediPlessi_Istituto', true);
                  break;
                case 'Istituto_Gestioni':
                  _this.set('session.Istituto_Gestioni', true);
                  break;
                case 'VediPlessi_CommercialeInterno':
                  _this.set('session.VediPlessi_CommercialeInterno', true);
                  break;
                case 'VediGestione_eventi':
                  _this.set('session.VediGestione_eventi', true);
                  break;
                case 'Istituto_View':
                  _this.set('session.Istituto_View', true);
                  break;
                case 'Vedi_Provvigioni':
                  _this.set('session.Vedi_Provvigioni', true);
                  break;
                case 'Vedi_MonitoraggioVeicoli':
                  _this.set('session.Vedi_MonitoraggioVeicoli', true);
                  break;
                case 'Plesso_Servizi':
                  _this.set('session.Plesso_Servizi', true);
                  break;
                case 'Plesso_Localizzazione':
                  _this.set('session.Plesso_Localizzazione', true);
                  break;
                case 'Plesso_Recapiti':
                  _this.set('session.Plesso_Recapiti', true);
                  break;
                case 'Plesso_DatiFatturazione':
                  _this.set('session.Plesso_DatiFatturazione', true);
                  break;
                case 'Plesso_Gestioni':
                  _this.set('session.Plesso_Gestioni', true);
                  break;
                case 'Plesso_RecapitiSulPosto':
                  _this.set('session.Plesso_RecapitiSulPosto', true);
                  break;
                case 'Plesso_Timbrature':
                  _this.set('session.Plesso_Timbrature', true);
                  break;
                case 'Vedi_Contratto':
                  _this.set('session.Vedi_Contratto', true);
                  break;
                case 'Inserisci_Copertura':
                  _this.set('session.Inserisci_Copertura', true);
                  break;
                case 'Inserisci_Userdata':
                  _this.set('session.Inserisci_Userdata', true);
                  break;
              }
            });
          });

          // todo eliminare permesso manuale per visualizzazione veicoli
          //_this.set('session.Vedi_MonitoraggioVeicoli',true);

          //  *** VARIABILI GENERICHE CONFIG
          _this.set('session.configurazioni', null);
          _this.store.unloadAll('configurazione');
          _this.store.find('configurazione').then(function (configs) {
            configs.forEach(function (config) {

              //_this.set('session.configurazioni',config);
              _this.get('session').set('configurazioni', config);

              //RECUPERO I DATI PER IL BONIFICO
              _this.set('session.bonifico_vigilanza', config.get('bonifico_vigilanza'));
              _this.set('session.bonifico_vigilo4you', config.get('bonifico_vigilo4you'));

              //SALVO LE CONFIGURAZIONI PER I TAGS
              _this.set('session.show_tags', false);
              _this.set('session.tags', null);
              var show_tags = config.get('show_tags');
              if (show_tags) {
                _this.set('session.show_tags', show_tags);
                _this.set('session.suggerimentiTags', config.get('suggerimentiTags'));
                console.log("suggerimentiTags");
                console.log(_this.get('session.suggerimentiTags'));
                //Recupero l'array dei tags
                var elenco_tags = [];
                config.get('tags').forEach(function (tag) {

                  elenco_tags.push({
                    etichetta: tag.etichetta,
                    descrizione: tag.descrizione,
                    placeholder: tag.placeholder

                  });
                });

                _this.set('session.tags', elenco_tags);
              }
              //FINE TAGS

              //SALVO VARIABILI GENERALI
              _this.set('session.tipo_creatore_corrente', config.get('tipo_creatore_corrente'));
              //_this.set('session.MaxQuota', config.get('percentuale_agenti'));
              _this.set('session.MaxQuota', window.ENV.APP.MaxQuota);

              _this.set('session.htmlHome', config.get('home'));
              _this.set('session.plessi_tutti_attivi_home', true);
              _this.set('session.tag_kit', config.get('tag_kit'));

              if (config.get('plessi_non_attivi_messaggi') !== '') {
                //recupero il messaggio
                var messaggio = config.get('plessi_non_attivi_messaggio');
                //recupero i plessi che non sono attivi
                var elenco_plessi = '';
                if (config.get('plessi_non_attivi')) {
                  if (config.get('plessi_non_attivi').get('length') > 0) {

                    // elenco_plessi += '<ul>'
                    config.get('plessi_non_attivi').forEach(function (opzione) {
                      elenco_plessi += '<a href="plessicliente/dettagli?id_plesso=' + opzione['id'] + '&panel_visualizza=servizi&scroll=no"><u>' + opzione['nome'] + '</u></a><br>';
                    });
                    // elenco_plessi += '</ul>'

                    _this.set('session.plessi_tutti_attivi_home', false);
                    _this.set('session.plessi_non_attivi_messaggio_home', elenco_plessi);
                    messaggio += elenco_plessi;
                    _this.set('session.plessi_non_attivi_messaggio', messaggio);
                    _this.set('session.plessi_non_attivi_visualizza', true);
                  }
                }
              }

              //SALVO L'ELENCO DOCUMENTI UTENTE
              var elencoDocumenti = config.get('elencoDocumentoUtente');
              _this.set('session.elenco_documenti_tipo', config.get('elencoDocumentoUtente'));
              //formatto
              console.log(elencoDocumenti);
              _this.send('formatElenco', elencoDocumenti, 'session.elenco_documenti_tipo');

              //SALVO LE CONFIGURAZIONI PER LA CREAZIONE UTENTI
              //permesso per creare gli utenti
              if (config.get('crea_utente') && config.get('crea_utente').tipologia) {
                if (config.get('crea_utente').tipologia.get('length') > 0) {
                  _this.set('session.crea_utente_show', true);
                }
                //array con le tipologie
                _this.set('session.crea_utente_tipologie', config.get('crea_utente').tipologia);
                //note
                if (config.get('crea_utente').Nota) {
                  _this.set('session.crea_utente_show_note', true);
                  _this.set('session.crea_utente_note', config.get('crea_utente').Nota);
                }
              }

              //SALVO LE CONFIGURAZIONI PER I PLESSI
              _this.set('session.crea_plesso_show', false);
              _this.set('session.crea_plesso_show_scelta', false);
              _this.set('session.crea_plesso_tipo', null);
              _this.set('session.ragione_sociale', null);
              if (config.get('crea_plesso')) {
                if (config.get('crea_plesso').get('length') > 0) {
                  //ha il permesso per creare plessi
                  _this.set('session.crea_plesso_show', true);

                  var elenco_opzioni = [];
                  config.get('crea_plesso').forEach(function (opzione) {
                    elenco_opzioni.push({
                      id: opzione.id,
                      text: opzione.etichetta,
                      etichetta: opzione.etichetta,
                      agente_corrente: opzione.agente_corrente,
                      cliente_corrente: opzione.cliente_corrente,
                      agenti: opzione.agenti,
                      descrizione: opzione.descrizione,
                      tipoinserimento: opzione.tipoinserimento,
                      provvigione: null
                    });
                    if (opzione.id == 'NoAgente' && opzione.cliente_corrente) {
                      _this.set('session.crea_plesso_tipoInserimento', 'vecchioCliente');
                      _this.set('session.ragione_sociale', _this.get('session.nominativo'));
                    }
                    _this.set('session.crea_plesso_tipo', opzione.id);
                  });
                  _this.set('session.crea_plesso_tipologie', elenco_opzioni);
                  if (config.get('crea_plesso').get('length') === 1) {
                    //ha solo un'opzione e non si deve visualizzare il menù di scelta
                    _this.set('session.crea_plesso_show_scelta', false);
                  } else {
                    //ha più opzioni: visualizzo il menù di scelta e pulisco l'opzione predefinita
                    _this.set('session.crea_plesso_show_scelta', true);
                    _this.set('session.crea_plesso_tipo', null);
                  }
                }
              }
            });
          });

          //REINDIRIZZO ALLA HOME
          var timeout = 0;
          //controllo se è vigilo per visualizzare il messaggio di reindirizzamento
          if (_this.get('isVigilo')) {
            timeout = 1990;
            _this.send('avvisoCambio');
          }
          setTimeout(function () {
            _this.transitionToRoute('home');
          }, timeout);
        }, function (error) {

          // _this.get('controllers.application').sentryError('Login', 'Login fallito');

          var messaggio_errore = '';
          _this.get('controllers.application').updateNetworkStatus();
          if (error['error'] === 'invalid_credentials') {
            messaggio_errore = 'Credenziali non correte';
          } else {
            messaggio_errore = error['error_description'];
          }
          _this.set('errorModal', true);
          _this.set('titoloModal', 'Login');
          _this.set('messaggioModal', 'Errore accesso <br>' + messaggio_errore);
          _ember['default'].$('#myModal').modal('show');
        });
      },

      recuperaPassword: function recuperaPassword() {
        var timeout = 0;
        if (this.get('isVigilo')) {
          timeout = 1990;
          this.send('avvisoCambio');
        }

        var _this = this;
        setTimeout(function () {
          if (_this.get('showMail')) {
            _this.set('showMail', false);
            _this.set('email', '');
          } else {
            _this.set('showMail', true);
          }
        }, timeout);
      },

      inviaMail: function inviaMail() {

        this.set('showLoading', true);
        this.set('model', this.store.createRecord('emailreminder'));
        this.set('model.email', this.get('email'));

        var _this = this;

        this.get('model').save().then(function () {
          _this.set('errorModal', false);
          _this.set('titoloModal', 'Recupero Password');
          _this.set('messaggioModal', 'Mail con la nuova password inviata.');
          _this.set('showLoading', false);
          _ember['default'].$('#myModal').modal('show');
        }, function (error) {
          console.log(error);
          _this.set('errorModal', true);
          _this.set('titoloModal', 'Recupero Password');
          _this.set('messaggioModal', 'Mail non riconosciuta.');
          _this.set('showLoading', false);
          _ember['default'].$('#myModal').modal('show');
        });
      }

    }

  });
});
define('portale-vigilanza/controllers/sedes/new', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        listaProvince: null,
        listComuni: null,
        loadComuni: (function () {

            var model = this.model;

            if (typeof model !== 'undefined') {

                var a = this.store.find('comuni', { provincia: model.get('indirizzoProvincia'), 'case': 'upper' });
                this.set('listComuni', a);
                console.log('cambio provincia' + this.get('province'));
            }
        }).observes('model.indirizzoProvincia')

    });
});
/**
 * Created by alessandro.fornasari on 24/09/2015.
 */
define('portale-vigilanza/controllers/royalty/index', ['exports', 'ember', 'portale-vigilanza/mixins/plessi/checkdisabilitato'], function (exports, _ember, _portaleVigilanzaMixinsPlessiCheckdisabilitato) {
  exports['default'] = _ember['default'].ArrayController.extend(_portaleVigilanzaMixinsPlessiCheckdisabilitato['default'], {
    namePage: 'royalty',

    actions: {

      visualizzaRichiesta: function visualizzaRichiesta(richiesta_id) {
        this.set('richiestaDati', this.store.findById('datifatturazionerichiestum', richiesta_id));
        _ember['default'].$('#modalRichiesta').modal('show');
      }

    }

  });
});
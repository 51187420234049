define('portale-vigilanza/controllers/ordini/nuovo', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    namePage: 'ordini',

    titoloModal: 'Nuovo Ordine',
    messaggioModal: 'Nuovo ordine creato',
    errorModal: false,

    queryParams: ['tipologia', 'plesso'],
    tipologia: null,
    plesso: null,

    prodotto: null,
    ricarica: null,

    isRicarica: false,

    quantita_barra: 0,

    esempiocrediti: 0,
    creditoResiduo: 0,
    nuovoResiduo: 0,
    quantita: 1,
    numero_extra: 0,
    totale_extra: 0,
    totale_ivato_extra: 0,
    valore_iva: 0,

    hasRicarica: false,
    hasPagamento: false,
    hasDatiFatturazione: false,
    showPaga: false,
    showConferma: false,
    hasQuantita: false,

    hasSpedizione: true,
    showSpedizione: false,

    codice_sconto: '',
    sconto_errore: '',
    sconto_iva: '',
    sconto_netto: '',
    showSconto: false,
    showErroreSconto: false,

    listDatiFatturazione: null,
    visualizzaDatiFatturazione: null,

    max_ricarica: window.ENV.APP.maxRicarica,

    selectProdotto: null,
    showDettagliProdotto: null,

    arrayProdotti: null,
    listExtra: null,

    visualizzaModalDatiFatturazione: null, //Modal con l'errore se non ci sono dati di fatturazione

    ordine_quantita: 0,
    ordine_totale: 0,
    ordine_totale_iva: 0,

    //barra modificata
    impostoQuantitaRicarica: (function () {
      console.log('Barra modificata');
      this.set('ricarica.quantita', this.get('quantita_barra'));
    }).observes('quantita_barra'),

    //calcoli da fare al cambio della quantità del prodotto
    calcolaNuovoRicarica: (function () {
      /*
          var quantita_temp = 0;
          var ordine_temp = 0;
          if(parseFloat(this.get('ricarica.quantita'))) {quantita_temp = parseFloat(this.get('ricarica.quantita'));}
          if(parseInt(this.get('prodotto.crediti'))) {ordine_temp = parseInt(this.get('prodotto.crediti'));}
          var nuovo = parseInt(this.get('creditoResiduo')) + ordine_temp + quantita_temp;
          this.set('nuovoResiduo',nuovo);
            //CALCOLO TOTALE
          var costo_ivato = this.get('ricarica.costo_ivato').split('.');
          var totaleivato_intero = costo_ivato[0] * quantita_temp;
          var totaleivato_decimale = costo_ivato[1] * quantita_temp;
          totaleivato_decimale = totaleivato_decimale / 100;
          var totaleivato = totaleivato_intero + totaleivato_decimale;
          totaleivato = Math.floor(totaleivato*100);
          totaleivato = totaleivato/100;
          this.set('ricarica.totale_ivato', totaleivato);
            Ember.$('#div_crediti').val(quantita_temp).change();
          this.set('esempiocrediti', Math.floor(nuovo/ +this.get('session.costo_minimo_crediti')));
          */

      var quantita_temp = 0;
      var ordine_temp = 0;
      if (parseFloat(this.get('ricarica.quantita'))) {
        quantita_temp = parseFloat(this.get('ricarica.quantita'));
      }
      if (parseInt(this.get('prodotto.crediti'))) {
        ordine_temp = parseInt(this.get('prodotto.crediti'));
      }
      var nuovo = parseInt(this.get('creditoResiduo')) + ordine_temp + quantita_temp;
      this.set('nuovoResiduo', nuovo);
      if (quantita_temp == 0) {
        this.set('ricarica.quantita', null);
      }
      //CALCOLO TOTALE
      var totaleivato = (this.get('ricarica.costo_ivato') * 100 * quantita_temp / 100).toFixed(2);
      this.set('ricarica.totale_ivato', totaleivato);

      _ember['default'].$('#div_crediti').val(quantita_temp).change();
      this.set('esempiocrediti', Math.floor(nuovo / +this.get('session.costo_minimo_crediti')));
    }).observes('ricarica.quantita'),

    //calcoli da fare al cambio della quantità del prodotto
    calcolaNuovoProdotto: (function () {
      /*
          var quantita_temp =parseInt(this.get('prodotto.quantita'));
          var totale = this.get('prodotto.costo')*quantita_temp;
          var totaleivato = totale + (totale/100 * this.get('prodotto.iva') );
            //CALCOLO TOTALE
          var costo_ivato = this.get('prodotto.costo_ivato').split('.');
          var totaleivato_intero = costo_ivato[0] * quantita_temp;
          var totaleivato_decimale = costo_ivato[1] * quantita_temp;
          totaleivato_decimale = totaleivato_decimale / 100;
          var totaleivato = totaleivato_intero + totaleivato_decimale;
          totaleivato = Math.floor(totaleivato*100);
          totaleivato = totaleivato/100;
          this.set('prodotto.totale_ivato', totaleivato);
        */

      /*silvia commentato per problema con decimali
      var quantita_temp =parseInt(this.get('prodotto.quantita'));
      var totale = this.get('prodotto.costo')*quantita_temp* 100;
      var totaleivato = totale + (totale/100 * this.get('prodotto.iva') );
        //CALCOLO TOTALE
      totaleivato = (totaleivato/100).toFixed(2);*/

      //silvia
      var totaleivato = Math.floor(this.get('prodotto.costo_ivato') * 1000 * this.get('prodotto.quantita')) / 1000;

      this.set('prodotto.totale_ivato', totaleivato);
    }).observes('prodotto.quantita'),

    //Modifica quantità extra
    modificaExtra: (function () {

      var numero_extra = 0;
      var totale_extra = 0;
      var totale_ivato_extra = 0;
      var extra_iva = 0;

      var listExtra = this.get('listExtra');
      if (listExtra) {
        listExtra.forEach(function (extra) {

          if (extra.quantita > 0) {

            //Visualizzazione dell'extra
            _ember['default'].set(extra, 'show', true);
            //Recupero la quantità
            var quantita = parseInt(extra.quantita);

            //CALCOLO TOTALE
            /*var costo_ivato = extra.costo_ivato.split('.');
            var totaleivato_intero = costo_ivato[0] * quantita;
            var totaleivato_decimale = costo_ivato[1] * quantita;
            totaleivato_decimale = totaleivato_decimale / 100;
            var totale_ivato = totaleivato_intero + totaleivato_decimale;
            totale_ivato = Math.floor(totale_ivato*100);
            totale_ivato = totale_ivato/100;*/
            //silvia test per decimali
            var totale_ivato = Math.floor(extra.costo_ivato * 1000 * quantita) / 1000;

            _ember['default'].set(extra, 'totale_ivato', totale_ivato);
            totale_ivato_extra += totale_ivato;

            //Sommo alla quantità totale
            numero_extra += quantita;

            extra_iva = extra.iva;
          } else {

            //Elimino dal riepilogo l'extra
            _ember['default'].set(extra, 'show', false);
          } //else quantità > 0
        });
      }

      //imposto i totali per gli extra
      this.set('totale_extra', totale_extra);
      this.set('totale_ivato_extra', totale_ivato_extra);
      this.set('numero_extra', numero_extra);
      this.set('extra_iva', extra_iva);
    }).observes('listExtra.@each.quantita'),

    //calcoli TOTALI
    calcoloTotali: (function () {

      var ordine_totale_iva = 0;
      if (this.get('prodotto.quantita')) ordine_totale_iva += parseFloat(this.get('prodotto.totale_ivato'));
      if (this.get('hasRicarica')) {
        if (this.get('ricarica.quantita')) ordine_totale_iva += parseFloat(this.get('ricarica.totale_ivato'));
      }
      if (this.get('totale_ivato_extra')) ordine_totale_iva += parseFloat(this.get('totale_ivato_extra'));
      this.set('ordine_totale_iva', Math.round(ordine_totale_iva * 100) / 100);

      var iva = 0;
      if (this.get('prodotto.iva')) iva = this.get('prodotto.iva');
      if (this.get('ricarica.iva')) iva = this.get('ricarica.iva');
      if (this.get('extra_iva')) iva = this.get('extra_iva');
      iva = parseInt(iva);
      var ordine_totale = 100 * ordine_totale_iva / (100 + iva);
      this.set('ordine_totale', Math.round(ordine_totale * 100) / 100);

      var ordine_quantita = 0;
      if (this.get('prodotto.quantita')) ordine_quantita += parseInt(this.get('prodotto.quantita'));
      if (this.get('hasRicarica')) {
        if (this.get('ricarica.quantita')) ordine_quantita += parseInt(this.get('ricarica.quantita'));
      }
      if (this.get('numero_extra')) ordine_quantita += parseInt(this.get('numero_extra'));
      this.set('ordine_quantita', ordine_quantita);

      if (ordine_quantita > 0) this.set('hasQuantita', true);else this.set('hasQuantita', false);

      ordine_totale_iva = ordine_totale_iva * 100;
      ordine_totale = ordine_totale * 100;
      var valore_iva = (ordine_totale_iva - ordine_totale) / 100;
      this.set('valore_iva', valore_iva);
    }).observes('ricarica.totale_ivato', 'prodotto.totale_ivato', 'totale_ivato_extra'),

    visualizzaPagaConferma: (function () {
      this.set('showPaga', false);
      this.set('showConferma', false);

      if (this.get('hasQuantita') && this.get('hasPagamento') && this.get('hasDatiFatturazione') && this.get('hasSpedizione') && this.get('check_condizioni_V4Y')) {
        if (this.get('model.metodo_pagamento_id') === 'paypal') {
          this.set('showPaga', true);
        } else {
          this.set('showConferma', true);
        }
      }
    }).observes('hasPagamento', 'hasDatiFatturazione', 'hasSpedizione', 'hasQuantita', 'check_condizioni_V4Y'),

    controllaDatiFatturazione: (function () {
      this.set('hasDatiFatturazione', false);

      if (this.get('model.codice_fatturazione')) {
        //Recupero i dati di fatturazione
        var _this = this;

        this.store.findById('datifatturazionecliente', this.get('model.codice_fatturazione')).then(function (dati) {
          //E' un codice di fatturazione normale
          _this.set('visualizzaDatiFatturazione', dati);
          _this.set('hasDatiFatturazione', true);
        }, function (error) {
          //E' un codice di una richiesta in corso
          _this.store.find('datifatturazioneclienterichiestum', {
            codice_fatturazione: _this.get('model.codice_fatturazione'),
            stato: 'pending'
          }).then(function (dati) {
            if (dati.get('length') > 0) {
              dati.forEach(function (dato) {
                _this.set('visualizzaDatiFatturazione', dato);
                _this.set('hasDatiFatturazione', true);
              });
            }
          });
        });
      } else {
        this.set('visualizzaDatiFatturazione', null);
      }
    }).observes('model.codice_fatturazione'),

    hasNominativo: _ember['default'].computed.notEmpty('model.nominativo'),
    hasIndirizzo: _ember['default'].computed.notEmpty('model.indirizzo'),
    hasComune: _ember['default'].computed.notEmpty('model.comune'),
    hasCap: _ember['default'].computed.notEmpty('model.cap'),
    hasProvincia: _ember['default'].computed.notEmpty('model.provincia'),
    isValidSpedizione: _ember['default'].computed.and('hasNominativo', 'hasIndirizzo', 'hasComune', 'hasCap', 'hasProvincia'),
    controllaIndirizzoSpedizione: (function () {
      if (this.get('isValidSpedizione')) {
        this.set('hasSpedizione', true);
      } else {
        this.set('hasSpedizione', false);
      }
    }).observes('model.nominativo', 'model.indirizzo', 'model.comune', 'model.cap', 'model.provincia'),

    actions: {

      applicaSconto: function applicaSconto() {

        if (this.get('hasSconto')) {
          //Calcolo il totale
          var ordine_totale = this.get('ordine_totale');
          var sconto_netto = this.get('sconto_netto');
          ordine_totale = ordine_totale - sconto_netto;
          this.set('ordine_totale_scontato', ordine_totale);

          //Calcolo il totale ivato
          var ordine_totale_iva = this.get('ordine_totale_iva');
          var sconto_iva = this.get('sconto_iva');
          ordine_totale_iva = ordine_totale_iva - sconto_iva;
          this.set('ordine_totale_scontato_iva', ordine_totale_iva);
          ordine_totale_iva = ordine_totale_iva * 100;
          ordine_totale = ordine_totale * 100;
          var valore_iva = (ordine_totale_iva - ordine_totale) / 100;
          this.set('valore_iva', valore_iva);
        }
      },

      //  ** Azione pulsante del modal **
      toggleConfirm: function toggleConfirm() {
        _ember['default'].$('#myModal').modal('hide');
        console.log(this.get('model.id'));
        this.transitionToRoute('ordini.dettagli', this.get('model.id'));
      },

      //  ** Azione pulsante del modal Dati di Fatturazione **
      chiudiModalDatiFatturazione: function chiudiModalDatiFatturazione() {
        _ember['default'].$('#modalDatiFatturazione').modal('hide');
        history.go(-1);
      },

      //  ** Azione al cambio del check sul tipo di pagamento
      controllaPagamento: function controllaPagamento() {
        console.log('Pagamento cambiato');
        this.set('hasPagamento', false);
        if (this.get('model.metodo_pagamento_id') !== '') {
          this.set('hasPagamento', true);
        }
      },

      //  ** Azione per caricare l'indirizzo del plesso e inserirlo nell'indirizzo di spedizione
      usaDatiPlesso: function usaDatiPlesso() {
        var _this = this;
        this.store.query('plessicliente', {
          id_plesso: this.get('plesso')
        }).then(function (plessi) {
          plessi.forEach(function (plesso) {
            _this.set('model.nominativo', plesso.get('ragione_sociale'));
            _this.set('model.indirizzo', plesso.get('indirizzo'));
            _this.set('model.comune', plesso.get('comune'));
            _this.set('model.cap', plesso.get('cap'));
            _this.set('model.provincia', plesso.get('provincia'));
          });
        });
      },
      //  ** Azione per caricare l'indirizzo dei dati di fatturazione e inserirlo nell'indirizzo di spedizione
      usaDatiFattura: function usaDatiFattura() {
        console.log("usa dati fattura");
        var _this = this;

        this.store.findById('datifatturazionecliente', this.get('model.codice_fatturazione')).then(function (datifatturazione) {

          _this.set('model.nominativo', datifatturazione.get('ragione_sociale'));
          _this.set('model.indirizzo', datifatturazione.get('sede_legale_indirizzo'));
          _this.set('model.comune', datifatturazione.get('sede_legale_comune'));
          _this.set('model.cap', datifatturazione.get('sede_legale_cap'));
          _this.set('model.provincia', datifatturazione.get('sede_legale_provincia'));
        });
      },

      //  ** Verifica codice sconto **
      verificaCodiceSconto: function verificaCodiceSconto() {
        var _this = this;
        this.store.query('nuovoordine', {
          tipologia: this.get('tipologia'),
          plesso: this.get('plesso'),
          codice: this.get('codice_sconto')
        }).then(function (nuoviordine) {
          nuoviordine.forEach(function (ordine) {
            var sconto = ordine.get('sconto');
            if (sconto['valido']) {
              _this.set('hasSconto', true);
              _this.set('sconto_percentuale', sconto['percentuale']);
              _this.set('sconto_iva', sconto['sconto_iva']);
              _this.set('sconto_netto', sconto['sconto_netto']);
              _this.send('applicaSconto');
            } else {
              _this.set('hasSconto', false);
            }
            _this.set('showMessaggioSconto', true);
            _this.set('sconto_messaggio', sconto['messaggio']);
          });
        });
      },

      //  **Attivo la quantità dell'extra
      attivaAcquisto: function attivaAcquisto(extra) {
        _ember['default'].set(extra, 'showQuantita', false);
      },
      confermaAcquisto: function confermaAcquisto(extra) {
        _ember['default'].set(extra, 'showQuantita', true);
      },
      annullaAcquisto: function annullaAcquisto(extra) {
        _ember['default'].set(extra, 'quantita', 0);
        _ember['default'].set(extra, 'showQuantita', true);
      },
      menoQuantita: function menoQuantita(extra) {
        var quantita = extra.quantita - 1;
        if (quantita < 0) _ember['default'].set(extra, 'quantita', 0);else _ember['default'].set(extra, 'quantita', quantita);
      },
      piuQuantita: function piuQuantita(extra) {
        var quantita = extra.quantita + 1;
        _ember['default'].set(extra, 'quantita', quantita);
      },

      //  ** Azione per inviare l'ordine
      confermaOrdine: function confermaOrdine() {
        _ember['default'].$('body').addClass('loading');
        var _this = this;

        if (this.get('activation') && !this.get('check_activation')) {
          _this.set('modelError', _this.get('model'));
          _this.set('errorModal', true);
          _this.set('messaggioModal', 'Condizioni contratto assicurazione non accettate');
          _ember['default'].$('body').removeClass('loading');
          _ember['default'].$('#myModal').modal('show');
        } else {
          this.set('model.tipologia', this.get('prodotto.tipologia'));
          this.set('model.return_url', window.ENV.APP.PayPal_return_url);
          this.set('model.cancel_url', window.ENV.APP.PayPal_cancel_url);
          this.set('model.totale_netto', this.get('ordine_totale'));
          this.set('model.totale_iva', this.get('ordine_totale_iva'));

          var items = [];

          //Controllo se c'è un kit
          if (this.get('prodotto.quantita') > 0 && this.get('prodotto.tipologia') !== 'accessori') {
            var item = {
              prodotto_id: this.get('prodotto.id'),
              plesso_id: this.get('plesso'),
              quantita: this.get('prodotto.quantita'),
              costo: this.get('prodotto.costo'),
              iva: this.get('prodotto.iva')
            };
            items.push(item);
          }
          //Controllo se c'è la ricarica
          if (this.get('ricarica.quantita') > 0) {
            var item = {
              prodotto_id: this.get('ricarica.id'),
              plesso_id: this.get('plesso'),
              quantita: this.get('ricarica.quantita'),
              costo: this.get('ricarica.costo'),
              iva: this.get('ricarica.iva')
            };
            items.push(item);
          }
          //Controllo se ci sono gli extra
          if (this.get('numero_extra') > 0) {
            var listExtra = this.get('listExtra');
            listExtra.forEach(function (extra) {
              if (extra.quantita > 0) {
                var item = {
                  prodotto_id: extra.id,
                  plesso_id: _this.get('plesso'),
                  quantita: extra.quantita,
                  costo: extra.costo,
                  iva: extra.iva
                };
                items.push(item);
              }
            });
          }
          //Controllo se c'è lo sconto
          if (this.get('hasSconto')) {
            this.set('model.codice', this.get('codice_sconto'));
            this.set('model.totale_netto', this.get('ordine_totale_scontato'));
            this.set('model.totale_iva', this.get('ordine_totale_scontato_iva'));
          } else {
            this.set('model.codice', null);
          }

          this.set('model.items', JSON.stringify(items));

          console.log(this.model.get('check_marketing'));

          this.model.save().then(function () {

            //se è con paypal ridirigo la pagina
            if (_this.get('model.metodo_pagamento_id') === 'paypal') {
              _this.set('errorModal', false);

              var url = _this.get('model.redirect_url');
              window.location = url;
            } else if (_this.get('model.metodo_pagamento_id') === 'bonifico') {
              _this.set('errorModal', false);

              var messaggio = 'Nuovo ordine creato.<br>' + 'Per completare l\'ordine è necessario effettuare il pagamento.<br><br>' + '<b>Dati per il bonifico</b><br>' + '<p class="alert-info">';
              if (_this.get('ordine_V4Y')) {
                //Dati Vigilo4Y
                messaggio += _this.get('session.bonifico_vigilo4you');
              } else {
                //Dati Vigilanza
                messaggio += _this.get('session.bonifico_vigilanza');
              }
              messaggio += '<br><b>Valore: </b>' + _this.get('model.totale_iva') + ' €' + '<br><b>Causale:</b> numero ordine: ' + _this.get('model.id') + ' - codice fatturazione: ' + _this.get('model.codice_fatturazione') + '</p>';

              _this.set('messaggioModal', messaggio);

              _ember['default'].$('body').removeClass('loading');
              _ember['default'].$('#myModal').modal('show');
            } else if (_this.get('model.metodo_pagamento_id') === 'contanti') {
              _this.set('errorModal', false);

              var messaggio = 'Nuovo ordine creato con successo.<br>';
              _this.set('messaggioModal', messaggio);

              _ember['default'].$('body').removeClass('loading');
              _ember['default'].$('#myModal').modal('show');
            }
          }, function (error) {
            _this.set('modelError', _this.get('model'));
            _this.set('errorModal', true);
            _this.set('messaggioModal', 'Errore nel salvataggio');
            _ember['default'].$('body').removeClass('loading');
            _ember['default'].$('#myModal').modal('show');
          });
        }
      }
    }
  });
});
define('portale-vigilanza/routes/plessicliente/index', ['exports', 'ember', 'simple-auth/mixins/authenticated-route-mixin', 'portale-vigilanza/mixins/checkMessage/check-message', 'ember-cli-pagination/remote/route-mixin'], function (exports, _ember, _simpleAuthMixinsAuthenticatedRouteMixin, _portaleVigilanzaMixinsCheckMessageCheckMessage, _emberCliPaginationRemoteRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_simpleAuthMixinsAuthenticatedRouteMixin['default'], _portaleVigilanzaMixinsCheckMessageCheckMessage['default'], _emberCliPaginationRemoteRouteMixin['default'], {

    model: function model(params) {
      this.store.unloadAll('plessicliente');

      var temp = this.findPaged('plessicliente', params);
      console.log(temp);
      return temp;
      /*
        return PagedRemoteArray.create({modelName: 'plessicliente',
            store: this.store,
            page: params.page || 1,
            perPage: params.per_page || 10});
            */
    }

  });
});

//import PagedRemoteArray from 'ember-cli-pagination/remote/paged-remote-array';
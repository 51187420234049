define('portale-vigilanza/routes/richiediplesso', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({

    model: function model() {
      return this.store.createRecord('richiestaplessoconsumo');
    },

    deactivate: function deactivate() {
      var model = this.modelFor('richiediplesso');
      if (model.get('isNew')) {
        model.destroyRecord();
      }
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      model.set('provincia', controller.get('provincia'));
      model.set('comune', controller.get('comune'));

      //Variabili per verifica copertura
      controller.set('provincia_copertura', null);
      controller.set('comune_copertura', null);
      controller.set('step1', false);
      controller.set('step2', false);
      controller.set('step3', false);
      controller.set('showVerifica', false);
      controller.set('AltriServizi', false);
      controller.set('showAltriServizi', false);
      controller.set('showProcedi', false);

      //Variabili procedura richiesta
      controller.set('show_step1', true);
      controller.set('flagDati', false);

      controller.set('show_step2', false);
      controller.set('procedi_registrazione', false); //variabili per step2 se ci sono servizi

      controller.set('show_step3', false);
      controller.set('errore_step3', false);
    }

  });
});
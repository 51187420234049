define('portale-vigilanza/models/currentuser', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({

    nominativo: _emberData['default'].attr('string'),
    email: _emberData['default'].attr('string'),
    telefono: _emberData['default'].attr('string'),
    telefono_confermato: _emberData['default'].attr('boolean'),
    temp_cell: _emberData['default'].attr('string'),
    checkbox_1: _emberData['default'].attr('string'),
    checkbox_2: _emberData['default'].attr('string'),
    checkbox_3: _emberData['default'].attr('string'),

    crediti: _emberData['default'].attr('string'),
    costo_minimo_crediti: _emberData['default'].attr('string'),
    is_vigilo: _emberData['default'].attr('boolean'),
    is_vigilo_attivo: _emberData['default'].attr('boolean', { defaultValue: false }),
    is_royalty: _emberData['default'].attr('boolean'),

    password: _emberData['default'].attr('string'),
    password_new: _emberData['default'].attr('string'),
    password_confirmation: _emberData['default'].attr('string'),

    view_message: _emberData['default'].attr('string'),
    message: _emberData['default'].attr('string'),
    require_message: _emberData['default'].attr('string'),
    route: _emberData['default'].attr('string'),

    is_documento_utente: _emberData['default'].attr('boolean', { defaultValue: false }),
    documento_utente_presente: _emberData['default'].attr('boolean', { defaultValue: false })

  });
});
define('portale-vigilanza/models/coperturacomune', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({

    numero_chiavi: _emberData['default'].attr('string'),
    nome_istituto: _emberData['default'].attr('string'),
    indirizzo_istituto: _emberData['default'].attr('string'),
    cap_istituto: _emberData['default'].attr('string'),
    comune_istituto: _emberData['default'].attr('string'),
    provincia_istituto: _emberData['default'].attr('string'),
    telefono_istituto: _emberData['default'].attr('string'),
    opzioni: _emberData['default'].attr('items')

  });
});
define('portale-vigilanza/models/prodotto', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({

    titolo: _emberData['default'].attr('string'),
    image: _emberData['default'].attr('string'),
    attivazione: _emberData['default'].attr('boolean'),
    tipologia: _emberData['default'].attr('string'),
    descrizione: _emberData['default'].attr('string'),
    descrizione_breve: _emberData['default'].attr('string'),
    crediti: _emberData['default'].attr('string'),
    quantita: _emberData['default'].attr('string', { defaultValue: "0" }),
    opzionale: _emberData['default'].attr('string', { defaultValue: false }),
    costo: _emberData['default'].attr('string'),
    costo_ivato: _emberData['default'].attr('string'),
    iva: _emberData['default'].attr('string'),
    totale: _emberData['default'].attr('string'),
    totale_ivato: _emberData['default'].attr('string'),
    spedizione_required: _emberData['default'].attr('boolean'),
    tag: _emberData['default'].attr('string')

  });
});
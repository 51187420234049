define("portale-vigilanza/templates/plessiquote/index", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@1.13.11",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 63,
                    "column": 30
                  },
                  "end": {
                    "line": 65,
                    "column": 30
                  }
                },
                "moduleName": "portale-vigilanza/templates/plessiquote/index.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("th");
                dom.setAttribute(el1, "class", "col-xs-2 col-sm-1 col-md-1 col-lg-1");
                var el2 = dom.createElement("a");
                dom.setAttribute(el2, "href", "#");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode(" Quota");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element5 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createElementMorph(element5);
                morphs[1] = dom.createMorphAt(dom.childAt(element5, [0]), 0, 0);
                return morphs;
              },
              statements: [["element", "action", ["setSortBy", "quota"], [], ["loc", [null, [64, 78], [64, 108]]]], ["inline", "sort-icon", ["quota", ["get", "sort", ["loc", [null, [64, 141], [64, 145]]]], ["get", "order", ["loc", [null, [64, 146], [64, 151]]]]], [], ["loc", [null, [64, 121], [64, 153]]]]],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "revision": "Ember@1.13.11",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 66,
                    "column": 30
                  },
                  "end": {
                    "line": 68,
                    "column": 30
                  }
                },
                "moduleName": "portale-vigilanza/templates/plessiquote/index.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("th");
                dom.setAttribute(el1, "class", "col-xs-2 col-sm-1 col-md-1 col-lg-1");
                var el2 = dom.createElement("a");
                dom.setAttribute(el2, "href", "#");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode(" Creato da");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element4 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createElementMorph(element4);
                morphs[1] = dom.createMorphAt(dom.childAt(element4, [0]), 0, 0);
                return morphs;
              },
              statements: [["element", "action", ["setSortBy", "quota"], [], ["loc", [null, [67, 78], [67, 108]]]], ["inline", "sort-icon", ["quota", ["get", "sort", ["loc", [null, [67, 141], [67, 145]]]], ["get", "order", ["loc", [null, [67, 146], [67, 151]]]]], [], ["loc", [null, [67, 121], [67, 153]]]]],
              locals: [],
              templates: []
            };
          })();
          var child2 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@1.13.11",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 79,
                      "column": 36
                    },
                    "end": {
                      "line": 79,
                      "column": 105
                    }
                  },
                  "moduleName": "portale-vigilanza/templates/plessiquote/index.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["inline", "format-date", [["get", "plessoQuote.attivato_at", ["loc", [null, [79, 80], [79, 103]]]]], [], ["loc", [null, [79, 64], [79, 105]]]]],
                locals: [],
                templates: []
              };
            })();
            var child1 = (function () {
              return {
                meta: {
                  "revision": "Ember@1.13.11",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 79,
                      "column": 105
                    },
                    "end": {
                      "line": 79,
                      "column": 114
                    }
                  },
                  "moduleName": "portale-vigilanza/templates/plessiquote/index.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("-");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() {
                  return [];
                },
                statements: [],
                locals: [],
                templates: []
              };
            })();
            var child2 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "revision": "Ember@1.13.11",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 83,
                        "column": 36
                      },
                      "end": {
                        "line": 83,
                        "column": 85
                      }
                    },
                    "moduleName": "portale-vigilanza/templates/plessiquote/index.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("% ");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                    return morphs;
                  },
                  statements: [["content", "plessoQuote.quota", ["loc", [null, [83, 62], [83, 83]]]]],
                  locals: [],
                  templates: []
                };
              })();
              var child1 = (function () {
                var child0 = (function () {
                  return {
                    meta: {
                      "revision": "Ember@1.13.11",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 84,
                          "column": 63
                        },
                        "end": {
                          "line": 84,
                          "column": 124
                        }
                      },
                      "moduleName": "portale-vigilanza/templates/plessiquote/index.hbs"
                    },
                    arity: 1,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode(" ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode(" ");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                      return morphs;
                    },
                    statements: [["content", "agente.nominativo", ["loc", [null, [84, 102], [84, 123]]]]],
                    locals: ["agente"],
                    templates: []
                  };
                })();
                return {
                  meta: {
                    "revision": "Ember@1.13.11",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 84,
                        "column": 36
                      },
                      "end": {
                        "line": 84,
                        "column": 133
                      }
                    },
                    "moduleName": "portale-vigilanza/templates/plessiquote/index.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [["block", "each", [["get", "plessoQuote.agenti", ["loc", [null, [84, 81], [84, 99]]]]], [], 0, null, ["loc", [null, [84, 63], [84, 133]]]]],
                  locals: [],
                  templates: [child0]
                };
              })();
              return {
                meta: {
                  "revision": "Ember@1.13.11",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 82,
                      "column": 32
                    },
                    "end": {
                      "line": 86,
                      "column": 32
                    }
                  },
                  "moduleName": "portale-vigilanza/templates/plessiquote/index.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("td");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                                ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element1 = dom.childAt(fragment, [1]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(element1, 0, 0);
                  morphs[1] = dom.createMorphAt(element1, 2, 2);
                  return morphs;
                },
                statements: [["block", "if", [["get", "plessoQuote.quota", ["loc", [null, [83, 42], [83, 59]]]]], [], 0, null, ["loc", [null, [83, 36], [83, 92]]]], ["block", "if", [["get", "plessoQuote.agenti", ["loc", [null, [84, 42], [84, 60]]]]], [], 1, null, ["loc", [null, [84, 36], [84, 140]]]]],
                locals: [],
                templates: [child0, child1]
              };
            })();
            var child3 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "revision": "Ember@1.13.11",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 89,
                        "column": 36
                      },
                      "end": {
                        "line": 89,
                        "column": 85
                      }
                    },
                    "moduleName": "portale-vigilanza/templates/plessiquote/index.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("% ");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                    return morphs;
                  },
                  statements: [["content", "plessoQuote.quota", ["loc", [null, [89, 62], [89, 83]]]]],
                  locals: [],
                  templates: []
                };
              })();
              var child1 = (function () {
                var child0 = (function () {
                  return {
                    meta: {
                      "revision": "Ember@1.13.11",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 90,
                          "column": 63
                        },
                        "end": {
                          "line": 90,
                          "column": 124
                        }
                      },
                      "moduleName": "portale-vigilanza/templates/plessiquote/index.hbs"
                    },
                    arity: 1,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode(" ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode(" ");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                      return morphs;
                    },
                    statements: [["content", "agente.nominativo", ["loc", [null, [90, 102], [90, 123]]]]],
                    locals: ["agente"],
                    templates: []
                  };
                })();
                return {
                  meta: {
                    "revision": "Ember@1.13.11",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 90,
                        "column": 36
                      },
                      "end": {
                        "line": 90,
                        "column": 133
                      }
                    },
                    "moduleName": "portale-vigilanza/templates/plessiquote/index.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [["block", "each", [["get", "plessoQuote.agenti", ["loc", [null, [90, 81], [90, 99]]]]], [], 0, null, ["loc", [null, [90, 63], [90, 133]]]]],
                  locals: [],
                  templates: [child0]
                };
              })();
              return {
                meta: {
                  "revision": "Ember@1.13.11",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 88,
                      "column": 32
                    },
                    "end": {
                      "line": 92,
                      "column": 32
                    }
                  },
                  "moduleName": "portale-vigilanza/templates/plessiquote/index.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("td");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                                ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element0 = dom.childAt(fragment, [1]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(element0, 0, 0);
                  morphs[1] = dom.createMorphAt(element0, 2, 2);
                  return morphs;
                },
                statements: [["block", "if", [["get", "plessoQuote.quota", ["loc", [null, [89, 42], [89, 59]]]]], [], 0, null, ["loc", [null, [89, 36], [89, 92]]]], ["block", "if", [["get", "plessoQuote.agenti", ["loc", [null, [90, 42], [90, 60]]]]], [], 1, null, ["loc", [null, [90, 36], [90, 140]]]]],
                locals: [],
                templates: [child0, child1]
              };
            })();
            return {
              meta: {
                "revision": "Ember@1.13.11",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 75,
                    "column": 26
                  },
                  "end": {
                    "line": 96,
                    "column": 26
                  }
                },
                "moduleName": "portale-vigilanza/templates/plessiquote/index.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("tr");
                var el2 = dom.createTextNode("\n                                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("td");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("td");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("br");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode(" (");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode(")");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("td");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("td");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment(" l'istituto non deve vedere le quote mentre le altre figure (es installatore) sì");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("                                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment(" l'istituto deve vedere chi ha creato il plesso ");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n\n                            ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element2 = dom.childAt(fragment, [1]);
                var element3 = dom.childAt(element2, [3]);
                var morphs = new Array(8);
                morphs[0] = dom.createMorphAt(dom.childAt(element2, [1]), 0, 0);
                morphs[1] = dom.createMorphAt(element3, 0, 0);
                morphs[2] = dom.createMorphAt(element3, 2, 2);
                morphs[3] = dom.createMorphAt(element3, 4, 4);
                morphs[4] = dom.createMorphAt(dom.childAt(element2, [5]), 0, 0);
                morphs[5] = dom.createMorphAt(dom.childAt(element2, [7]), 0, 0);
                morphs[6] = dom.createMorphAt(element2, 11, 11);
                morphs[7] = dom.createMorphAt(element2, 15, 15);
                return morphs;
              },
              statements: [["content", "plessoQuote.id", ["loc", [null, [77, 36], [77, 54]]]], ["content", "plessoQuote.ragione_sociale", ["loc", [null, [78, 36], [78, 67]]]], ["content", "plessoQuote.comune", ["loc", [null, [78, 71], [78, 93]]]], ["content", "plessoQuote.provincia", ["loc", [null, [78, 95], [78, 120]]]], ["block", "if", [["get", "plessoQuote.isAttivo", ["loc", [null, [79, 42], [79, 62]]]]], [], 0, 1, ["loc", [null, [79, 36], [79, 121]]]], ["content", "plessoQuote.categoria", ["loc", [null, [80, 36], [80, 61]]]], ["block", "unless", [["get", "session.VediPlessi_Istituto", ["loc", [null, [82, 42], [82, 69]]]]], [], 2, null, ["loc", [null, [82, 32], [86, 43]]]], ["block", "if", [["get", "session.VediPlessi_Istituto", ["loc", [null, [88, 38], [88, 65]]]]], [], 3, null, ["loc", [null, [88, 32], [92, 39]]]]],
              locals: ["plessoQuote"],
              templates: [child0, child1, child2, child3]
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.11",
              "loc": {
                "source": null,
                "start": {
                  "line": 48,
                  "column": 10
                },
                "end": {
                  "line": 106,
                  "column": 10
                }
              },
              "moduleName": "portale-vigilanza/templates/plessiquote/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("\n            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "row dettagli_plessi");
              var el2 = dom.createTextNode("\n\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "col-xs-12 col-sm-12 col-md-12 col-lg-12");
              var el3 = dom.createTextNode("\n\n                    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3, "class", "table-responsive");
              var el4 = dom.createTextNode("\n                      ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("table");
              dom.setAttribute(el4, "class", "table table-striped table-hover");
              var el5 = dom.createTextNode("\n\n                          ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("thead");
              var el6 = dom.createTextNode("\n                          ");
              dom.appendChild(el5, el6);
              var el6 = dom.createElement("tr");
              var el7 = dom.createTextNode("\n                              ");
              dom.appendChild(el6, el7);
              var el7 = dom.createElement("th");
              dom.setAttribute(el7, "class", "col-xs-1 col-sm-1 col-md-1 col-lg-1");
              var el8 = dom.createElement("a");
              dom.setAttribute(el8, "href", "#");
              var el9 = dom.createComment("");
              dom.appendChild(el8, el9);
              dom.appendChild(el7, el8);
              var el8 = dom.createTextNode(" Codice");
              dom.appendChild(el7, el8);
              dom.appendChild(el6, el7);
              var el7 = dom.createTextNode("\n                              ");
              dom.appendChild(el6, el7);
              var el7 = dom.createElement("th");
              dom.setAttribute(el7, "class", "col-xs-2 col-sm-2 col-md-2 col-lg-2");
              var el8 = dom.createElement("a");
              dom.setAttribute(el8, "href", "#");
              var el9 = dom.createComment("");
              dom.appendChild(el8, el9);
              dom.appendChild(el7, el8);
              var el8 = dom.createTextNode(" Nominativo cliente");
              dom.appendChild(el7, el8);
              dom.appendChild(el6, el7);
              var el7 = dom.createTextNode("\n                              ");
              dom.appendChild(el6, el7);
              var el7 = dom.createElement("th");
              dom.setAttribute(el7, "class", "col-xs-1 col-sm-1 col-md-1 col-lg-1");
              var el8 = dom.createElement("a");
              dom.setAttribute(el8, "href", "#");
              var el9 = dom.createComment("");
              dom.appendChild(el8, el9);
              dom.appendChild(el7, el8);
              var el8 = dom.createTextNode(" Attivo Dal");
              dom.appendChild(el7, el8);
              dom.appendChild(el6, el7);
              var el7 = dom.createTextNode("\n                              ");
              dom.appendChild(el6, el7);
              var el7 = dom.createElement("th");
              dom.setAttribute(el7, "class", "col-xs-1 col-sm-1 col-md-1 col-lg-1");
              var el8 = dom.createElement("a");
              dom.setAttribute(el8, "href", "#");
              var el9 = dom.createComment("");
              dom.appendChild(el8, el9);
              dom.appendChild(el7, el8);
              var el8 = dom.createTextNode(" Categoria");
              dom.appendChild(el7, el8);
              dom.appendChild(el6, el7);
              var el7 = dom.createTextNode("\n");
              dom.appendChild(el6, el7);
              var el7 = dom.createComment("");
              dom.appendChild(el6, el7);
              var el7 = dom.createComment("");
              dom.appendChild(el6, el7);
              var el7 = dom.createTextNode("\n\n\n                          ");
              dom.appendChild(el6, el7);
              dom.appendChild(el5, el6);
              var el6 = dom.createTextNode("\n                          ");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n                          ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("tbody");
              var el6 = dom.createTextNode("\n");
              dom.appendChild(el5, el6);
              var el6 = dom.createComment("");
              dom.appendChild(el5, el6);
              var el6 = dom.createTextNode("                          ");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n\n                      ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                    ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n\n                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element6 = dom.childAt(fragment, [1, 1, 1, 1]);
              var element7 = dom.childAt(element6, [1, 1]);
              var element8 = dom.childAt(element7, [1]);
              var element9 = dom.childAt(element7, [3]);
              var element10 = dom.childAt(element7, [5]);
              var element11 = dom.childAt(element7, [7]);
              var morphs = new Array(12);
              morphs[0] = dom.createElementMorph(element8);
              morphs[1] = dom.createMorphAt(dom.childAt(element8, [0]), 0, 0);
              morphs[2] = dom.createElementMorph(element9);
              morphs[3] = dom.createMorphAt(dom.childAt(element9, [0]), 0, 0);
              morphs[4] = dom.createElementMorph(element10);
              morphs[5] = dom.createMorphAt(dom.childAt(element10, [0]), 0, 0);
              morphs[6] = dom.createElementMorph(element11);
              morphs[7] = dom.createMorphAt(dom.childAt(element11, [0]), 0, 0);
              morphs[8] = dom.createMorphAt(element7, 9, 9);
              morphs[9] = dom.createMorphAt(element7, 10, 10);
              morphs[10] = dom.createMorphAt(dom.childAt(element6, [3]), 1, 1);
              morphs[11] = dom.createMorphAt(fragment, 3, 3, contextualElement);
              return morphs;
            },
            statements: [["element", "action", ["setSortBy", "id"], [], ["loc", [null, [59, 78], [59, 105]]]], ["inline", "sort-icon", ["id", ["get", "sort", ["loc", [null, [59, 135], [59, 139]]]], ["get", "order", ["loc", [null, [59, 140], [59, 145]]]]], [], ["loc", [null, [59, 118], [59, 147]]]], ["element", "action", ["setSortBy", "ragione_sociale"], [], ["loc", [null, [60, 78], [60, 118]]]], ["inline", "sort-icon", ["ragione_sociale", ["get", "sort", ["loc", [null, [60, 161], [60, 165]]]], ["get", "order", ["loc", [null, [60, 166], [60, 171]]]]], [], ["loc", [null, [60, 131], [60, 173]]]], ["element", "action", ["setSortBy", "attivato_at"], [], ["loc", [null, [61, 78], [61, 114]]]], ["inline", "sort-icon", ["attivato_at", ["get", "sort", ["loc", [null, [61, 153], [61, 157]]]], ["get", "order", ["loc", [null, [61, 158], [61, 163]]]]], [], ["loc", [null, [61, 127], [61, 165]]]], ["element", "action", ["setSortBy", "categoria"], [], ["loc", [null, [62, 78], [62, 112]]]], ["inline", "sort-icon", ["categoria", ["get", "sort", ["loc", [null, [62, 149], [62, 153]]]], ["get", "order", ["loc", [null, [62, 154], [62, 159]]]]], [], ["loc", [null, [62, 125], [62, 161]]]], ["block", "unless", [["get", "session.VediPlessi_Istituto", ["loc", [null, [63, 40], [63, 67]]]]], [], 0, null, ["loc", [null, [63, 30], [65, 41]]]], ["block", "if", [["get", "session.VediPlessi_Istituto", ["loc", [null, [66, 36], [66, 63]]]]], [], 1, null, ["loc", [null, [66, 30], [68, 37]]]], ["block", "each", [["get", "model", ["loc", [null, [75, 49], [75, 54]]]]], [], 2, null, ["loc", [null, [75, 26], [96, 35]]]], ["inline", "page-numbers", [], ["content", ["subexpr", "@mut", [["get", "content", ["loc", [null, [105, 35], [105, 42]]]]], [], []]], ["loc", [null, [105, 12], [105, 44]]]]],
            locals: [],
            templates: [child0, child1, child2]
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.11",
            "loc": {
              "source": null,
              "start": {
                "line": 46,
                "column": 8
              },
              "end": {
                "line": 107,
                "column": 8
              }
            },
            "moduleName": "portale-vigilanza/templates/plessiquote/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "model.length", ["loc", [null, [48, 16], [48, 28]]]]], [], 0, null, ["loc", [null, [48, 10], [106, 17]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.11",
            "loc": {
              "source": null,
              "start": {
                "line": 107,
                "column": 8
              },
              "end": {
                "line": 109,
                "column": 8
              }
            },
            "moduleName": "portale-vigilanza/templates/plessiquote/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "partial", ["partial/loading"], [], ["loc", [null, [108, 10], [108, 39]]]]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.11",
          "loc": {
            "source": null,
            "start": {
              "line": 6,
              "column": 2
            },
            "end": {
              "line": 115,
              "column": 2
            }
          },
          "moduleName": "portale-vigilanza/templates/plessiquote/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "id", "section");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "container");
          var el3 = dom.createTextNode("\n\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "col-xs-12 col-sm-7 col-md-7 col-lg-4");
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("h1");
          dom.setAttribute(el4, "class", "page-title txt-color-blueDark");
          var el5 = dom.createTextNode("\n\n                    ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment(" PAGE HEADER ");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                   ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("br");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode(" ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("i");
          dom.setAttribute(el5, "class", "fa fa-fw fa-list ");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                    Elenco Immobili\n\n                ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "clearfix");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("p");
          var el4 = dom.createTextNode(" ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("form");
          dom.setAttribute(el3, "class", "form-inline");
          var el4 = dom.createTextNode("\n\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "row");
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5, "class", "form-group col-sm-2 col-xs-12");
          var el6 = dom.createTextNode("\n                        ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("label");
          dom.setAttribute(el6, "for", "aa");
          var el7 = dom.createTextNode("Parola chiave");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                        ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("br");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                        ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                    ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n\n                    ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5, "class", "form-group col-sm-3 col-xs-12");
          var el6 = dom.createTextNode("\n                        ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("br");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                        ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("button");
          dom.setAttribute(el6, "type", "button");
          dom.setAttribute(el6, "class", "btn btn-default");
          var el7 = dom.createElement("i");
          dom.setAttribute(el7, "class", "fa fa-eraser");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode(" Pulisci");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                        ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("button");
          dom.setAttribute(el6, "type", "button");
          dom.setAttribute(el6, "class", "btn btn-success");
          var el7 = dom.createElement("i");
          dom.setAttribute(el7, "class", "fa fa-search");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode(" Cerca");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                    ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("br");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n\n\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element12 = dom.childAt(fragment, [1, 1]);
          var element13 = dom.childAt(element12, [7, 1]);
          var element14 = dom.childAt(element13, [3]);
          var element15 = dom.childAt(element14, [3]);
          var element16 = dom.childAt(element14, [5]);
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(dom.childAt(element13, [1]), 5, 5);
          morphs[1] = dom.createElementMorph(element15);
          morphs[2] = dom.createElementMorph(element16);
          morphs[3] = dom.createMorphAt(element12, 11, 11);
          return morphs;
        },
        statements: [["inline", "input", [], ["type", "text", "value", ["subexpr", "@mut", [["get", "nome_plesso", ["loc", [null, [30, 50], [30, 61]]]]], [], []], "class", "form-control"], ["loc", [null, [30, 24], [30, 85]]]], ["element", "action", ["pulisci"], [], ["loc", [null, [35, 70], [35, 90]]]], ["element", "action", ["searchPlessi"], [], ["loc", [null, [36, 70], [36, 95]]]], ["block", "if", [["get", "modelIsLoad", ["loc", [null, [46, 14], [46, 25]]]]], [], 0, 1, ["loc", [null, [46, 8], [109, 15]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.11",
          "loc": {
            "source": null,
            "start": {
              "line": 115,
              "column": 2
            },
            "end": {
              "line": 117,
              "column": 2
            }
          },
          "moduleName": "portale-vigilanza/templates/plessiquote/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "partial", ["partial/errorepermessi"], [], ["loc", [null, [116, 4], [116, 40]]]]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@1.13.11",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 120,
            "column": 6
          }
        },
        "moduleName": "portale-vigilanza/templates/plessiquote/index.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "id", "main");
        dom.setAttribute(el1, "role", "main");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment(" /#wrapper ");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[2] = dom.createMorphAt(dom.childAt(fragment, [4]), 3, 3);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "partial", ["partial/header"], [], ["loc", [null, [1, 0], [1, 28]]]], ["inline", "partial", ["partial/menu"], [], ["loc", [null, [2, 0], [2, 26]]]], ["block", "if", [["get", "session.VediPlessi_Quote", ["loc", [null, [6, 8], [6, 32]]]]], [], 0, 1, ["loc", [null, [6, 2], [117, 9]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});
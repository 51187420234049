define('portale-vigilanza/models/configurazione', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({

    elencoDocumentoUtente: _emberData['default'].attr('items'),
    crea_plesso: _emberData['default'].attr('items'),
    crea_utente: _emberData['default'].attr('items'),
    tipo_creatore_corrente: _emberData['default'].attr('items'),
    tag_kit: _emberData['default'].attr('string'),
    percentuale_agenti: _emberData['default'].attr('items'),
    home: _emberData['default'].attr('string'),
    plessi_non_attivi: _emberData['default'].attr('items'),
    plessi_non_attivi_messaggio: _emberData['default'].attr('string'),

    show_tags: _emberData['default'].attr('boolean'),
    tags: _emberData['default'].attr('items'),
    suggerimentiTags: _emberData['default'].attr('items'),

    bonifico_vigilanza: _emberData['default'].attr('string'),
    bonifico_vigilo4you: _emberData['default'].attr('string')
  });
});
define("portale-vigilanza/templates/partial/plessi/-modalcontratto", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.11",
          "loc": {
            "source": null,
            "start": {
              "line": 122,
              "column": 14
            },
            "end": {
              "line": 128,
              "column": 14
            }
          },
          "moduleName": "portale-vigilanza/templates/partial/plessi/-modalcontratto.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                 ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("br");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "align", "center");
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" Accetto\n                  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("hr");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]), 1, 1);
          return morphs;
        },
        statements: [["inline", "input", [], ["type", "checkbox", "checked", ["subexpr", "@mut", [["get", "flagContratto_1", ["loc", [null, [125, 52], [125, 67]]]]], [], []]], ["loc", [null, [125, 20], [125, 69]]]]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.11",
          "loc": {
            "source": null,
            "start": {
              "line": 128,
              "column": 14
            },
            "end": {
              "line": 130,
              "column": 14
            }
          },
          "moduleName": "portale-vigilanza/templates/partial/plessi/-modalcontratto.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("br");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.11",
          "loc": {
            "source": null,
            "start": {
              "line": 139,
              "column": 14
            },
            "end": {
              "line": 144,
              "column": 14
            }
          },
          "moduleName": "portale-vigilanza/templates/partial/plessi/-modalcontratto.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                 ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("br");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "align", "center");
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" Accetto\n                  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]), 1, 1);
          return morphs;
        },
        statements: [["inline", "input", [], ["type", "checkbox", "checked", ["subexpr", "@mut", [["get", "flagContratto_2", ["loc", [null, [142, 52], [142, 67]]]]], [], []]], ["loc", [null, [142, 20], [142, 69]]]]],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.11",
          "loc": {
            "source": null,
            "start": {
              "line": 152,
              "column": 16
            },
            "end": {
              "line": 154,
              "column": 16
            }
          },
          "moduleName": "portale-vigilanza/templates/partial/plessi/-modalcontratto.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          dom.setAttribute(el1, "type", "button");
          dom.setAttribute(el1, "class", "btn btn-default");
          dom.setAttribute(el1, "data-dismiss", "modal");
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("i");
          dom.setAttribute(el2, "class", "fa");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" Chiudi");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child4 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.11",
            "loc": {
              "source": null,
              "start": {
                "line": 156,
                "column": 18
              },
              "end": {
                "line": 158,
                "column": 18
              }
            },
            "moduleName": "portale-vigilanza/templates/partial/plessi/-modalcontratto.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("button");
            dom.setAttribute(el1, "type", "button");
            dom.setAttribute(el1, "class", "btn btn-success");
            var el2 = dom.createElement("i");
            dom.setAttribute(el2, "class", "fa");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" Accetta");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element0);
            return morphs;
          },
          statements: [["element", "action", ["accettaContratto"], [], ["loc", [null, [157, 68], [157, 98]]]]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.11",
            "loc": {
              "source": null,
              "start": {
                "line": 158,
                "column": 18
              },
              "end": {
                "line": 160,
                "column": 18
              }
            },
            "moduleName": "portale-vigilanza/templates/partial/plessi/-modalcontratto.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("button");
            dom.setAttribute(el1, "type", "button");
            dom.setAttribute(el1, "class", "btn btn-success");
            dom.setAttribute(el1, "disabled", "");
            var el2 = dom.createTextNode(" ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("i");
            dom.setAttribute(el2, "class", "fa");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" Accetta");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.11",
          "loc": {
            "source": null,
            "start": {
              "line": 155,
              "column": 16
            },
            "end": {
              "line": 161,
              "column": 16
            }
          },
          "moduleName": "portale-vigilanza/templates/partial/plessi/-modalcontratto.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "acceptActive", ["loc", [null, [156, 24], [156, 36]]]]], [], 0, 1, ["loc", [null, [156, 18], [160, 25]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "revision": "Ember@1.13.11",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 167,
            "column": 0
          }
        },
        "moduleName": "portale-vigilanza/templates/partial/plessi/-modalcontratto.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment(" Modal Contratto ");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "modal fade modal-scrollable");
        dom.setAttribute(el1, "id", "modalContratto");
        dom.setAttribute(el1, "tabindex", "-1");
        dom.setAttribute(el1, "role", "dialog");
        dom.setAttribute(el1, "aria-labelledby", "myModalLabel");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "modal-dialog modal-lg");
        dom.setAttribute(el2, "role", "document");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "modal-content");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "modal-header");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("button");
        dom.setAttribute(el5, "type", "button");
        dom.setAttribute(el5, "class", "close");
        dom.setAttribute(el5, "data-dismiss", "modal");
        dom.setAttribute(el5, "aria-label", "Close");
        var el6 = dom.createElement("span");
        dom.setAttribute(el6, "aria-hidden", "true");
        var el7 = dom.createTextNode("×");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h4");
        dom.setAttribute(el5, "class", "modal-title");
        dom.setAttribute(el5, "id", "exampleModalLabel");
        var el6 = dom.createElement("b");
        var el7 = dom.createTextNode("CONDIZIONI GENERALI DI CONTRATTO");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "modal-body");
        var el5 = dom.createTextNode("\n\n\n\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("  HTML DEL CONTRATTO  ");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("p");
        dom.setAttribute(el5, "align", "center");
        var el6 = dom.createTextNode("\n                  premesso che\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("ul");
        var el6 = dom.createTextNode("\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createTextNode("Vigilanza Group S.c.a.r.l. ha sviluppato un portale web per la promozione e la vendita dei servizi di vigilanza a consumo;");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createTextNode("il cliente acquista gli interventi con modalità “prepagata” su apposita sezione riservata, accessibile dal sito www.vigilo4you.it, in seguito denominato PORTALE;");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createTextNode("l’acquisto dà diritto ad usufruire di un servizio di intervento su allarme presso il sito del cliente;");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createTextNode("Vigilanza Group S.c.a.r.l. (di seguito denominata Vigilanza Group) è un istituto di vigilanza dotato di autorizzazione ex art. 134 del T.U.L.P.S. che è attualmente operativo nelle province di Brescia, Bergamo, Mantova, Verona e L’Aquila. Per le altre zone d’Italia Vigilanza Group si servirà di un network di istituti di vigilanza per l’esecuzione dell’intervento in loco;");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("p");
        dom.setAttribute(el5, "align", "center");
        var el6 = dom.createTextNode("tutto ciò premesso,");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("ol");
        var el6 = dom.createTextNode("\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createTextNode("\n                      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("b");
        var el8 = dom.createTextNode("Oggetto del contratto");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("p");
        dom.setAttribute(el7, "align", "justify");
        var el8 = dom.createTextNode("\n                          Con il presente contratto Vigilanza Group si obbliga ad eseguire, il servizio di pronto intervento presso l’immobile del cliente.\n                          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("br");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("Tale servizio viene effettuato esclusivamente su richiesta del Cliente che, tramite apposita APP “Vigilo4you” in caso di necessità ed a sua discrezione, potrà inoltrare il segnale di richiesta di intervento alla Centrale Operativa di Vigilanza Group. Tale richiesta, una volta inoltrata, non potrà essere revocata.\n                          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("br");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("Alla ricezione dell’allarme, Vigilanza Group svolgerà il servizio di bonifica in loco con Guardie Particolari Giurate armate, in divisa e radio collegate con la centrale operativa dell’istituto le quali, in caso di reato, contatteranno le FF.O.O. Le caratteristiche e le specifiche del servizio sono presenti nella scheda anagrafica e dettagli che il Cliente stesso deve compilare a propria cura e sotto la propria responsabilità. Le modalità di esecuzione del servizio sono descritte analiticamente nella scheda tecnica del Cliente (Sezione “Dettagli Immobile” sul portale), da considerarsi parte integrante del presente accordo e che il cliente dovrà compilare a propria cura e sotto la propria responsabilità. Il corretto inserimento dei dati è ad esclusiva responsabilità del cliente che dovrà farsi carico di aggiornarli anche in caso di successivi cambiamenti.\n                          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("br");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("L’intervento sarà eseguito nelle fasce orarie accettate al momento della compilazione della scheda tecnica. L’intervento richiesto fuori dalle fasce orarie non verrà effettuato e verrà notificato al cliente tramite e-mail e SMS. Eventuali variazioni delle fasce orarie saranno comunicate al cliente tramite medesimo mezzo (e-mail / SMS).\n                          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("br");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("L’intervento di allarme verrà eseguito solo ed esclusivamente a seguito di richiesta del cliente.\n                      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createTextNode("\n                      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("b");
        var el8 = dom.createTextNode("Attivazione del servizio");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("p");
        dom.setAttribute(el7, "align", "justify");
        var el8 = dom.createTextNode("\n                          Per poter usufruire del servizio il Cliente dovrà procedere all’attivazione dello stesso, compilando la scheda tecnica e accettando il presente accordo. Dovrà inoltre avere effettuato il pagamento di almeno un intervento con le modalità indicate all’art. 4.\n                          Il servizio verrà reso operativo entro due giorni lavorativi successivi a quello dell’effettivo pagamento e comunicato tramite e-mail.\n                      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createTextNode("\n                      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("b");
        var el8 = dom.createTextNode("Corrispettivo");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("p");
        dom.setAttribute(el7, "align", "justify");
        var el8 = dom.createTextNode("\n                          Il corrispettivo del servizio è a consumo. Il prezzo di ogni intervento è pari a Euro 99,00 IVA inclusa.\n                          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("br");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("Per i clienti che hanno aderito al servizio di “custodia chiavi” il costo di un intervento è pari a Euro 109,00 IVA inclusa. In questo caso il cliente si attiverà per la consegna delle chiavi (numero e modalità di consegna verranno evidenziati nella scheda anagrafica e dettagli del Cliente).\n                          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("br");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("Ogni qualvolta venga effettuato un intervento il relativo costo pari a Euro 99,00 o 109,00 (ove richiesto il servizio di intervento con chiavi) sarà scalato dall’ammontare del suo credito disponibile e registrato nell’area riservata del portale, rimanendo in tal modo consultabile dal Cliente in qualsiasi momento. Il cliente verrà avvisato tramite e-mail e SMS degli interventi effettuati e del credito residuo.\n                          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("br");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("Esaurito il corrispettivo prepagato, per poter riattivare il servizio e poterne nuovamente usufruire, il Cliente dovrà procedere alla ricarica con le modalità previste nell’art. 4.\n                      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createTextNode("\n                      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("b");
        var el8 = dom.createTextNode("Modalità di pagamento");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("p");
        dom.setAttribute(el7, "align", "justify");
        var el8 = dom.createTextNode("\n                          Il pagamento si intende anticipato con l’acquisto di almeno un intervento pari a Euro 99,00 IVA inclusa o Euro 109,00 IVA inclusa in caso di richiesta del servizio aggiuntivo di custodia chiavi. L’acquisto può essere effettuato tramite:\n                          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("br");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("-	bonifico bancario al seguente iban: IT10W0869211202017000170873\n                          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("br");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("- carta di credito, tramite il circuito PayPal\n                      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createTextNode("\n                      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("b");
        var el8 = dom.createTextNode("Copertura assicurativa");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("p");
        dom.setAttribute(el7, "align", "justify");
        var el8 = dom.createTextNode("\n                          In fase di acquisto del primo intervento Vigilanza Group offre, compresa nel prezzo, una copertura assicurativa\n                          per i danni da furto o tentato furto della durata di 12 mesi.");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("br");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                          Alla scadenza il cliente avrà l’opportunità di rinnovare la copertura al prezzo di Euro 24,40/anno.");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("br");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                          Il cliente prende atto fin da ora che, in caso di richiesta di copertura assicurativa alla scadenza, ai sensi dell’art. 120\n                          quinquies del D. Lgs. 209/2005 in tema di vendita abbinata,\n                          ha la possibilità di acquistare il servizio non assicurativo separatamente dalla copertura assicurativa.");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("br");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                          La documentazione precontrattuale di tale copertura è presente sul sito www.vigilo4you.it e nell’area riservata del portale clienti.\n\n                      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createTextNode("\n                      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("b");
        var el8 = dom.createTextNode("Responsabilità");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("p");
        dom.setAttribute(el7, "align", "justify");
        var el8 = dom.createTextNode("\n                          Vigilanza Group è responsabile nei confronti del Cliente solo per gli eventuali danni che possano derivare dall’esecuzione dell’intervento di allarme. A tal proposito la stessa dichiara di possedere idonee coperture assicurative.\n                          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("br");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("Il Cliente è responsabile, dal canto suo, di fornire tutte le informazioni e/o strumenti utili al corretto svolgimento dell’intervento di allarme, anche attraverso la corretta compilazione della scheda anagrafica e dettagli, fermo restando la fattibilità materiale dello stesso.\n                          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("br");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("Il Cliente si impegna a segnalare eventuali rischi connessi allo stato dei luoghi così come previsto dal D.LGS. n. 81/2008.\n                      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createTextNode("\n                      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("b");
        var el8 = dom.createTextNode("Durata");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("p");
        dom.setAttribute(el7, "align", "justify");
        var el8 = dom.createTextNode("\n                          Il servizio ha durata illimitata ed è condizionato al credito esistente.\n                          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("br");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("Ciò significa che nel caso in cui il Cliente abbia esaurito i crediti, nessun obbligo deriva dal mancato adempimento del servizio.\n                          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("br");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("L’eventuale ricarica del pacchetto di interventi non è subordinata a limiti di tempo.\n                      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createTextNode("\n                      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("b");
        var el8 = dom.createTextNode("Cessazione del servizio su richiesta del cliente");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("p");
        dom.setAttribute(el7, "align", "justify");
        var el8 = dom.createTextNode("\n                          Il Cliente potrà chiedere in ogni momento la cancellazione del servizio e la rimozione dei propri dati anagrafici dal portale inviando una lettera raccomandata A/R o a mezzo PEC al seguente indirizzo: vigilanzagroup@pec.vigilanzagroup.it.\n                          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("br");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("Eventuali crediti già acquistati e non utilizzati non verranno rimborsati.\n                      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createTextNode("\n                      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("b");
        var el8 = dom.createTextNode("Riservatezza");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("p");
        dom.setAttribute(el7, "align", "justify");
        var el8 = dom.createTextNode("\n                          Tutti i dati forniti dal Cliente verranno trattati secondo le norme previste dal R.EU 2016/679.\n                      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createTextNode("\n                      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("b");
        var el8 = dom.createTextNode("Foro competente");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("p");
        dom.setAttribute(el7, "align", "justify");
        var el8 = dom.createTextNode("\n                          Per qualsiasi controversia derivante dal presente accordo le parti stabiliscono in via esclusiva la competenza del foro di Brescia.\n                      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n\n\n              ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("p");
        dom.setAttribute(el5, "align", "justify");
        var el6 = dom.createTextNode("\n                    Ai sensi e per gli effetti degli artt. 1341, 1342 del codice civile il Cliente dichiara espressamente di accettare le seguenti condizioni particolari di contratto e più nello specifico:\n                    Art. 1 Oggetto del contratto, Art. 2 Attivazione del servizio, Art. 3 Corrispettivo, Art. 4 Modalità di pagamento,\n                    Art. 5 Copertura assicurativa, Art. 6 Responsabilità, Art. 7 Durata, Art. 8 Cessazione del servizio, Art. 9 Foro competente.\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("  FINE -> HTML DEL CONTRATTO  ");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "modal-footer");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [2, 1, 1]);
        var element2 = dom.childAt(element1, [3]);
        var element3 = dom.childAt(element1, [5]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(element2, 11, 11);
        morphs[1] = dom.createMorphAt(element2, 15, 15);
        morphs[2] = dom.createMorphAt(element3, 1, 1);
        morphs[3] = dom.createMorphAt(element3, 2, 2);
        return morphs;
      },
      statements: [["block", "unless", [["get", "modelContratto.accettazione_contratto", ["loc", [null, [122, 24], [122, 61]]]]], [], 0, 1, ["loc", [null, [122, 14], [130, 25]]]], ["block", "unless", [["get", "modelContratto.accettazione_contratto", ["loc", [null, [139, 24], [139, 61]]]]], [], 2, null, ["loc", [null, [139, 14], [144, 25]]]], ["block", "link-to", ["plessicliente.dettagli", ["subexpr", "query-params", [], ["id_plesso", ["get", "plessocliente.id", ["loc", [null, [152, 76], [152, 92]]]], "panel_visualizza", "contratto"], ["loc", [null, [152, 52], [152, 122]]]]], [], 3, null, ["loc", [null, [152, 16], [154, 28]]]], ["block", "unless", [["get", "modelContratto.accettazione_contratto", ["loc", [null, [155, 26], [155, 63]]]]], [], 4, null, ["loc", [null, [155, 16], [161, 27]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4]
    };
  })());
});
define('portale-vigilanza/models/nuovoordine', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({

    metodi_pagamento: _emberData['default'].attr('items'),
    prodotto: _emberData['default'].attr('items'),
    extra: _emberData['default'].attr('items'),
    sconto: _emberData['default'].attr('items'),
    has_polizza: _emberData['default'].attr('boolean')

  });
});
define('portale-vigilanza/models/creaplesso', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({

    ragioneSociale: _emberData['default'].attr('string'),
    indirizzo: _emberData['default'].attr('string'),
    civico: _emberData['default'].attr('string'),
    cap: _emberData['default'].attr('string'),
    provincia: _emberData['default'].attr('string'),
    comune: _emberData['default'].attr('string'),
    tel_plesso: _emberData['default'].attr('string'),
    cel_plesso: _emberData['default'].attr('string'),
    tag: _emberData['default'].attr('string'),
    agenti: _emberData['default'].attr('string'),
    tipoinserimento: _emberData['default'].attr('string'),
    nominativo: _emberData['default'].attr('string'),
    email: _emberData['default'].attr('string'),
    codiceFatturazione: _emberData['default'].attr('string'),
    codiceFiscale: _emberData['default'].attr('string'),
    tipo: _emberData['default'].attr('string'),
    user_creator_id: _emberData['default'].attr('string'),
    tipologia_plesso: _emberData['default'].attr('string')

  });
});
define('portale-vigilanza/controllers/istitutos/edit', ['exports', 'ember', 'portale-vigilanza/mixins/datiFatturaziones/manage-model-mixin'], function (exports, _ember, _portaleVigilanzaMixinsDatiFatturazionesManageModelMixin) {
    exports['default'] = _ember['default'].Controller.extend(_portaleVigilanzaMixinsDatiFatturazionesManageModelMixin['default'], {
        /*
        init: function () {
            console.log('ManageDatiFatturazionelMixin esterno');       // other setup here...
        },
        datifatturazione : null,
        datifatturazione_stato : 'view',//'view',
          isView : null,
        isEdit : null,
          setStatus: function(){
            console.log('setStatus interno ' + this.datifatturazione_stato);       // other setup here...
            console.log('datifatturazione not set ' + (this.datifatturazione == null));       // other setup here...
            this.set('isView', this.datifatturazione_stato == 'view');
            this.set('isEdit', this.datifatturazione_stato == 'edit');
          }.observes('datifatturazione_stato', 'datifatturazione').on('init'),
          actions: {
            salvaDf: function() {
                this.set('datifatturazione_stato', 'edit');
            },
            cancellaDf: function() {
                this.set('datifatturazione_stato', 'edit');
            },
            rimuoviDf: function() {
                this.set('datifatturazione_stato', 'edit');
            },
            aggiungiDf: function() {
                this.set('datifatturazione_stato', 'edit');
            },
            modificaDf: function() {
                this.set('datifatturazione_stato', 'view');
            }
        }
            isDatiFatturazioneSet: function(){
            return true;
        },
        hasValidSumCheck : function () {
            this.decrypt('a','b');
        }*/

    });
});
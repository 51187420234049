define('portale-vigilanza/models/indirizzo', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        //
        indirizzo: _emberData['default'].attr('string'),
        cap: _emberData['default'].attr('string'),
        comune: _emberData['default'].attr('string'),
        provincia: _emberData['default'].attr('string'),
        localita: _emberData['default'].attr('string'),
        stato: _emberData['default'].attr('string')
    });
});
define('portale-vigilanza/mixins/sedes/save-model-mixin', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    actions: {
      save: function save() {
        var route = this;
        //this.currentModel.set('indirizzoComune', this.controller.get('provincia'));
        //this.currentModel.set('indirizzoProvincia',this.controller.get('comune'));

        this.currentModel.save().then(function () {
          route.transitionTo('sedes');
        }, function () {
          console.log('Failed to save the model : ');
        });
      },
      cancel: function cancel() {
        var route = this;
        route.transitionTo('sedes');
      }
    },
    deactivate: function deactivate() {
      if (this.currentModel.get('isNew')) {
        this.currentModel.deleteRecord();
      } else {
        this.currentModel.rollbackAttributes();
      }
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      //controller.set('list_selected', model.get('user_categories'));

      controller.set('listaProvince', this.store.find('province'));
      //controller.loadComuni();
      /*
      var comune = '';
      var model = model;
      var provincia = model.get('indirizzoProvincia');
        if((typeof(provincia) !== 'undefined')){
            console.log('provincia ->'+ provincia);
          if(provincia != ''){
              controller.set('province', provincia);
              comune  = model.get('indirizzoComune');
              console.log('comune ->'+ comune);
              controller.loadComuni(comune);
          }
      }
      */
    }

  });
});
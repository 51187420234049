define('portale-vigilanza/models/plessicliente', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({

    ragione_sociale: _emberData['default'].attr('string'),
    etichetta: _emberData['default'].attr('string'),
    categoria: _emberData['default'].attr('string'),
    codice_fatturazione_1: _emberData['default'].attr('string'),
    codice_fatturazione_2: _emberData['default'].attr('string'),
    tipologia_contratto: _emberData['default'].attr('string'),
    lat: _emberData['default'].attr('number'),
    long: _emberData['default'].attr('number'),
    mail1: _emberData['default'].attr('string'),
    mail2: _emberData['default'].attr('string'),
    attesa_chiavi1: _emberData['default'].attr('boolean'),
    codice_chiavi1: _emberData['default'].attr('string'),
    codice_descrizione1: _emberData['default'].attr('string'),
    attesa_chiavi2: _emberData['default'].attr('boolean'),
    codice_chiavi2: _emberData['default'].attr('string'),
    codice_descrizione2: _emberData['default'].attr('string'),
    codice_chiavi2_enable: _emberData['default'].attr('boolean'),

    kit: _emberData['default'].attr('boolean'),

    recapitisulposto: _emberData['default'].attr('recapiti'),

    indirizzo: _emberData['default'].attr('string'),
    comune: _emberData['default'].attr('string'),
    provincia: _emberData['default'].attr('string'),
    cap: _emberData['default'].attr('string'),

    attivazioneProdotto: _emberData['default'].attr('string'),
    attivato_at: _emberData['default'].attr('date'),
    scadenza_assicurazione: _emberData['default'].attr('string'),
    isAttivo: _emberData['default'].attr('boolean'),
    isVigilo: _emberData['default'].attr('boolean'),
    isTestEffettuato: _emberData['default'].attr('boolean'),
    isAccettatoContratto: _emberData['default'].attr('boolean'),
    isNoteIntervento: _emberData['default'].attr('boolean'),
    isDatiFatturazione: _emberData['default'].attr('string'),
    isCoperturaComune: _emberData['default'].attr('boolean'),
    isPagamentoAttivazione: _emberData['default'].attr('string'),
    progression: (function () {
      var totale = 0;
      var items = ['showSuccessPagamento', 'showSuccessDatiFatturazione', 'isNoteIntervento', 'isAccettatoContratto', 'isCoperturaComune', 'isTestEffettuato'];
      var len = items.length;
      var addPercent = 100 / len;
      for (var i = 0, len = items.length; i < len; i++) {
        if (this.get(items[i]) == true) {
          totale += addPercent;
        }
      }
      items = ['showWaitingDatiFatturazione', 'showWaitingDatiPagamento'];
      addPercent = 100 / len;
      for (var i = 0, len = items.length; i < len; i++) {
        if (this.get(items[i]) == true) {
          totale += addPercent / 2;
        }
      }
      console.log('calcolo Progression ' + Math.round(totale));
      return Math.round(totale);
    }).property('showSuccessPagamento', 'showSuccessDatiFatturazione', 'isAttivo', 'isPagamentoAttivazione', 'isDatiFatturazione', 'isNoteIntervento', 'isAccettatoContratto', 'isCoperturaComune', 'isTestEffettuato', 'showWaitingDatiFatturazione', 'showWaitingDatiPagamento'),

    // CHECK DEI DATI DI FATTURAZIONE
    showSuccessDatiFatturazione: (function () {
      if (this.get('isDatiFatturazione') == 'si') return true;else return false;
    }).property('isDatiFatturazione'),

    showErrorDatiFatturazione: (function () {
      if (this.get('isDatiFatturazione') == 'no') return true;else return false;
    }).property('isDatiFatturazione'),

    showWaitingDatiFatturazione: (function () {
      if (this.get('isDatiFatturazione') == 'incorso') return true;else return false;
    }).property('isDatiFatturazione'),

    showWaitingOrSuccesDatiFatturazione: (function () {
      if (this.get('isDatiFatturazione') == 'incorso' || this.get('isDatiFatturazione') == 'si') return true;else return false;
    }).property('isDatiFatturazione'),

    // CHECK DEL PAGAMENTO
    showSuccessPagamento: (function () {
      if (this.get('isPagamentoAttivazione') == 'si') return true;else return false;
    }).property('isPagamentoAttivazione'),

    showErrorDatiPagamento: (function () {
      if (this.get('isPagamentoAttivazione') == 'no') return true;else return false;
    }).property('isPagamentoAttivazione'),

    showWaitingDatiPagamento: (function () {
      if (this.get('isPagamentoAttivazione') == 'incorso') return true;else return false;
    }).property('isPagamentoAttivazione'),

    // CHECK Attivazione prodotto per vedere se può fare il pagamento
    attivaPagamento: (function () {
      if (this.get('attivazioneProdotto') != '') return true;else return false;
    }).property('attivazioneProdotto'),

    format_lat: _emberData['default'].attr('string'),
    format_long: _emberData['default'].attr('string'),

    //SE NON CI SONO LAT E LONG LE TROVO DALL'INDIRIZZO
    geocode: (function () {
      console.log('geocode');

      var format_lat = null;
      var format_long = null;

      if (this.get('lat')) {
        var format_lat = this.get('lat').toString();
        format_lat = parseFloat([format_lat.slice(0, 2), '.', format_lat.slice(2)].join(''));
      }

      if (this.get('long')) {
        var long = this.get('long').toString();
        if (long[0] > 1) format_long = parseFloat([long.slice(0, 1), '.', long.slice(1)].join(''));else format_long = parseFloat([long.slice(0, 2), '.', long.slice(2)].join(''));
      }

      if (!format_lat || !format_long) {
        //if(!this.get('format_lat') || !this.get('format_long')){
        var address = this.get('indirizzo') + ", " + this.get('comune') + ", " + this.get('provincia');
        var geocoder = new google.maps.Geocoder();
        var _this = this;
        geocoder.geocode({
          'address': address
        }, function (results, status) {
          if (status === google.maps.GeocoderStatus.OK) {
            _this.set('format_lat', results[0].geometry.location.lat());
            _this.set('format_long', results[0].geometry.location.lng());
          } else {
            var address = _this.get('comune') + ", " + _this.get('provincia');
            geocoder.geocode({
              'address': address
            }, function (results, status) {
              if (status === google.maps.GeocoderStatus.OK) {
                _this.set('format_lat', results[0].geometry.location.lat());
                _this.set('format_long', results[0].geometry.location.lng());
              }
            });
          }
        });
        return true;
      } else {
        this.set('format_lat', format_lat);
        this.set('format_long', format_long);
        return false;
      }
    }).property('lat', 'long'),

    isAssicurazioneScaduta: (function () {
      if (!this.get('scadenza_assicurazione')) return false;
      return new Date(this.get('scadenza_assicurazione')) < new Date();
    }).property('scadenza_assicurazione'),

    viewPannelloAssicurazione: (function () {
      if (!this.get('scadenza_assicurazione')) return true;
      return new Date(this.get('scadenza_assicurazione')) < new Date();
    }).property('scadenza_assicurazione'),

    formatScadenzaAssicurazione: (function () {
      var today = new Date(this.get('scadenza_assicurazione'));
      var dd = today.getDate();
      var mm = today.getMonth() + 1; //January is 0!

      var yyyy = today.getFullYear();
      if (dd < 10) {
        dd = '0' + dd;
      }
      if (mm < 10) {
        mm = '0' + mm;
      }

      console.log(dd + '/' + mm + '/' + yyyy);

      return dd + '/' + mm + '/' + yyyy;
    }).property('scadenza_assicurazione')
  });
});
define("portale-vigilanza/templates/components/circle-perc", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@1.13.11",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 138
          }
        },
        "moduleName": "portale-vigilanza/templates/components/circle-perc.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "easy-pie-chart txt-color-orange easyPieChart");
        var el2 = dom.createElement("span");
        dom.setAttribute(el2, "class", "percent percent-sign");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(2);
        morphs[0] = dom.createAttrMorph(element0, 'data-percent');
        morphs[1] = dom.createMorphAt(dom.childAt(element0, [0]), 0, 0);
        return morphs;
      },
      statements: [["attribute", "data-percent", ["concat", [["get", "perc", ["loc", [null, [1, 74], [1, 78]]]]]]], ["content", "perc", ["loc", [null, [1, 117], [1, 125]]]]],
      locals: [],
      templates: []
    };
  })());
});
define('portale-vigilanza/models/pagamento', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({

    anno: _emberData['default'].attr('string'),
    trimestre: _emberData['default'].attr('string'),
    anticipato: _emberData['default'].attr('string'),
    debito: _emberData['default'].attr('string'),
    effettivo: _emberData['default'].attr('string'),
    importo: _emberData['default'].attr('string'),
    richiedibile: _emberData['default'].attr('boolean'),
    stato: _emberData['default'].attr('string'),
    vecchio_debito: _emberData['default'].attr('string'),
    codice_fatturazione: _emberData['default'].attr('string'),
    file_fattura: _emberData['default'].attr('items'),
    nome_fattura_originale: _emberData['default'].attr('items'),

    showFirst: (function () {
      if (this.get('importo') == this.get('anticipato')) return true;else return false;
    }).property('importo'),

    isRichiesto: (function () {
      if (this.get('stato') == 'richiesto') return true;else return false;
    }).property('stato'),

    isRichiedibile: (function () {
      if (this.get('stato') == 'richiedibile') return true;else return false;
    }).property('stato'),

    visualizza_stato: (function () {

      if (this.get('stato') == 'pronto') {
        if (this.get('richiedibile')) {
          return 'richiedibile';
        } else {
          return 'chiuso';
        }
      } else {
        if (this.get('stato') == 'calcolato') {

          var d = new Date();
          var anno_corrente = d.getFullYear();
          var trimestre_corrente = Math.floor(d.getMonth() / 3) + 1;

          if (this.get('anno') == anno_corrente && this.get('trimestre') >= trimestre_corrente) return 'incorso';else {
            if (this.get('anno') > anno_corrente) {
              return 'incorso';
            } else {
              return 'chiuso';
            }
          }
        } else return this.get('stato');
      }
    }).property('stato')

  });
});
define('portale-vigilanza/routes/plessicliente/verificacopertura', ['exports', 'ember', 'simple-auth/mixins/authenticated-route-mixin', 'portale-vigilanza/mixins/checkMessage/check-message'], function (exports, _ember, _simpleAuthMixinsAuthenticatedRouteMixin, _portaleVigilanzaMixinsCheckMessageCheckMessage) {
  exports['default'] = _ember['default'].Route.extend(_simpleAuthMixinsAuthenticatedRouteMixin['default'], _portaleVigilanzaMixinsCheckMessageCheckMessage['default'], {

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('provincia_copertura', null);
      controller.set('comune_copertura', null);
      controller.set('step1', true);
      controller.set('step2', false);
      controller.set('step3', false);
      controller.set('showVerifica', false);
      controller.set('AltriServizi', false);
      controller.set('showAltriServizi', false);
      controller.set('showProcedi', false);

      controller.set('nominativo', this.get('session.nominativo'));
      controller.set('email', this.get('session.email'));
      controller.set('telefono', this.get('session.telefono'));

      if (this.get('session.crea_plesso_tipologie').get('length') > 1) {
        this.set('session.crea_plesso_tipo', null);
      }
    }

  });
});
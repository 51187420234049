define('portale-vigilanza/objects/permission', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    name: 'DefaultName',
    gruppi: null,

    actions: {
      load: function load(model) {
        this.set('session.test', 'CIAO');
      }
    }

  });
});
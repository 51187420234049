define('portale-vigilanza/models/fattura', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({

    url: _emberData['default'].attr('string'),
    codice_fatturazione: _emberData['default'].attr('string'),
    numero: _emberData['default'].attr('string'),
    data_fattura: _emberData['default'].attr('string'),
    data_scadenza: _emberData['default'].attr('string'),
    importo: _emberData['default'].attr('string')

  });
});
define('portale-vigilanza/models/spidersataccount', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    /*
    istituto_id: DS.attr('string'),
      sede_id: DS.attr('string'),
      username: DS.attr('string'),
      guardia: DS.attr('string'),
      veicolo: DS.attr('string'),
      event_id: DS.attr('string'),
      evento_ora: DS.attr('string'),
      evento_dettaglio: DS.attr('string'),
      evento_cliente: DS.attr('string'),
      evento_indirizzo: DS.attr('string'),
      evento_stato: DS.attr('string'),
      evento_stato_dettaglio: DS.attr('string'),
      evento_stato_ora: DS.attr('string'),
      evento_eta: DS.attr('string'),
      evento_stato_colore: DS.attr('string'),
      evento_stato_icona: DS.attr('string'),
      mostra_conferma: DS.attr('boolean'),
      confirm: DS.attr('boolean'),
        isAllarm: function() {
          if(this.get('evento_ora') != '') return true;
          else return false;
      }.property('evento_ora')
      */
    istitutoId: _emberData['default'].attr('string'),
    sedeId: _emberData['default'].attr('string'),
    username: _emberData['default'].attr('string'),
    guardia: _emberData['default'].attr('string'),
    veicolo: _emberData['default'].attr('string'),
    eventId: _emberData['default'].attr('string'),
    eventoOra: _emberData['default'].attr('string'),
    eventoDettaglio: _emberData['default'].attr('string'),
    eventoCliente: _emberData['default'].attr('string'),
    eventoIndirizzo: _emberData['default'].attr('string'),
    eventoStato: _emberData['default'].attr('string'),
    eventoStatoDettaglio: _emberData['default'].attr('string'),
    eventoStatoOra: _emberData['default'].attr('string'),
    eventoEta: _emberData['default'].attr('string'),
    eventoStatoColore: _emberData['default'].attr('string'),
    eventoStatoIcona: _emberData['default'].attr('string'),
    mostraConferma: _emberData['default'].attr('boolean'),
    confirm: _emberData['default'].attr('boolean'),

    isAllarm: (function () {
      if (this.get('eventoOra') != '') return true;else return false;
    }).property('eventoOra')

  });
});
define("portale-vigilanza/templates/partial/-modalnuovoutente", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.11",
          "loc": {
            "source": null,
            "start": {
              "line": 29,
              "column": 16
            },
            "end": {
              "line": 42,
              "column": 16
            }
          },
          "moduleName": "portale-vigilanza/templates/partial/-modalnuovoutente.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "form-group");
          var el2 = dom.createTextNode("\n                        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          dom.setAttribute(el2, "class", "control-label col-md-3 col-sm-3 col-xs-12");
          dom.setAttribute(el2, "for", "first-name");
          var el3 = dom.createTextNode("Azienda di provenienza ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3, "class", "required");
          var el4 = dom.createTextNode("*");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "col-md-6 col-sm-6 col-xs-12");
          var el3 = dom.createTextNode("\n                            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 3]), 1, 1);
          return morphs;
        },
        statements: [["inline", "select-2", [], ["content", ["subexpr", "@mut", [["get", "session.crea_utente_tipologia_selezionata.elenco_aziende", ["loc", [null, [35, 36], [35, 92]]]]], [], []], "value", ["subexpr", "@mut", [["get", "azienda", ["loc", [null, [36, 34], [36, 41]]]]], [], []], "optionValuePath", "id", "optionLabelPath", "valore"], ["loc", [null, [34, 28], [39, 30]]]]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.11",
          "loc": {
            "source": null,
            "start": {
              "line": 43,
              "column": 18
            },
            "end": {
              "line": 72,
              "column": 18
            }
          },
          "moduleName": "portale-vigilanza/templates/partial/-modalnuovoutente.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "form-group");
          var el2 = dom.createTextNode("\n                          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          dom.setAttribute(el2, "class", "control-label col-md-3 col-sm-3 col-xs-12");
          dom.setAttribute(el2, "for", "last-name");
          var el3 = dom.createTextNode("\n                              Ripartizione provvigione ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3, "class", "required");
          var el4 = dom.createTextNode("*");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("button");
          dom.setAttribute(el3, "type", "button");
          dom.setAttribute(el3, "class", "popover-info btn-popover btn btn-xs btn-default");
          dom.setAttribute(el3, "data-toggle", "popover");
          dom.setAttribute(el3, "data-placement", "top");
          dom.setAttribute(el3, "title", "Provvigioni");
          var el4 = dom.createTextNode("\n                                  ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("i");
          dom.setAttribute(el4, "class", "fa fa-info");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          dom.setAttribute(el2, "class", "control-label col-md-1 col-sm-1 col-xs-1");
          dom.setAttribute(el2, "for", "last-name");
          var el3 = dom.createTextNode("0");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "col-md-4 col-sm-4 col-xs-10");
          var el3 = dom.createTextNode("\n                              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n                          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          dom.setAttribute(el2, "class", "control-label col-md-1 col-sm-1 col-xs-1");
          dom.setAttribute(el2, "for", "last-name");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "form-group");
          var el2 = dom.createTextNode("\n                          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          dom.setAttribute(el2, "class", "control-label col-md-3 col-sm-3 col-xs-12");
          dom.setAttribute(el2, "for", "last-name");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "col-md-6 col-sm-6 col-xs-12");
          dom.setAttribute(el2, "align", "center");
          var el3 = dom.createTextNode("\n                              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("h4");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var element2 = dom.childAt(element1, [1, 3]);
          var morphs = new Array(5);
          morphs[0] = dom.createAttrMorph(element2, 'data-content');
          morphs[1] = dom.createMorphAt(dom.childAt(element1, [5]), 1, 1);
          morphs[2] = dom.createMorphAt(dom.childAt(element1, [7]), 0, 0);
          morphs[3] = dom.createMorphAt(dom.childAt(fragment, [3, 3, 1]), 0, 0);
          morphs[4] = dom.createMorphAt(fragment, 5, 5, contextualElement);
          return morphs;
        },
        statements: [["attribute", "data-content", ["concat", [["get", "info_percentuale", ["loc", [null, [49, 54], [49, 70]]]]]]], ["inline", "input", [], ["id", "quota_range", "type", "range", "min", "0", "max", ["subexpr", "@mut", [["get", "session.MaxQuota", ["loc", [null, [55, 80], [55, 96]]]]], [], []], "step", "0.5", "data-orientation", "vertical", "value", ["subexpr", "@mut", [["get", "quota", ["loc", [null, [55, 143], [55, 148]]]]], [], []]], ["loc", [null, [55, 30], [55, 152]]]], ["content", "session.MaxQuota", ["loc", [null, [58, 98], [58, 118]]]], ["content", "quota", ["loc", [null, [63, 34], [63, 43]]]], ["inline", "btn-popover", [], ["id", "popover-info"], ["loc", [null, [67, 20], [69, 22]]]]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.11",
          "loc": {
            "source": null,
            "start": {
              "line": 80,
              "column": 14
            },
            "end": {
              "line": 82,
              "column": 14
            }
          },
          "moduleName": "portale-vigilanza/templates/partial/-modalnuovoutente.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          dom.setAttribute(el1, "class", "btn  btn-success");
          var el2 = dom.createElement("i");
          dom.setAttribute(el2, "class", "fa fa-arrow-right");
          dom.setAttribute(el2, "title", "Crea Utente");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" Crea Utente");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element0);
          return morphs;
        },
        statements: [["element", "action", ["salvaUtente"], [], ["loc", [null, [81, 51], [81, 75]]]]],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.11",
          "loc": {
            "source": null,
            "start": {
              "line": 82,
              "column": 14
            },
            "end": {
              "line": 84,
              "column": 14
            }
          },
          "moduleName": "portale-vigilanza/templates/partial/-modalnuovoutente.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          dom.setAttribute(el1, "class", "btn  btn-success");
          dom.setAttribute(el1, "disabled", "");
          var el2 = dom.createElement("i");
          dom.setAttribute(el2, "class", "fa fa-arrow-right");
          dom.setAttribute(el2, "title", "Crea Utente");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" Crea Utente");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@1.13.11",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 91,
            "column": 0
          }
        },
        "moduleName": "portale-vigilanza/templates/partial/-modalnuovoutente.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment(" Modal Ordine Chiavi ");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "modal fade modal-scrollable");
        dom.setAttribute(el1, "id", "modalNuovoUtente");
        dom.setAttribute(el1, "tabindex", "-1");
        dom.setAttribute(el1, "role", "dialog");
        dom.setAttribute(el1, "aria-labelledby", "myModalLabel");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "modal-dialog modal-lg");
        dom.setAttribute(el2, "role", "document");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "modal-content");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "modal-header");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("button");
        dom.setAttribute(el5, "type", "button");
        dom.setAttribute(el5, "class", "close");
        dom.setAttribute(el5, "data-dismiss", "modal");
        dom.setAttribute(el5, "aria-label", "Close");
        var el6 = dom.createElement("span");
        dom.setAttribute(el6, "aria-hidden", "true");
        var el7 = dom.createTextNode("×");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h4");
        dom.setAttribute(el5, "class", "modal-title");
        dom.setAttribute(el5, "id", "exampleModalLabel");
        var el6 = dom.createElement("b");
        var el7 = dom.createTextNode("Nuovo Utente: ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "modal-body");
        var el5 = dom.createTextNode("\n\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("form");
        dom.setAttribute(el5, "id", "form-nuovo_plesso");
        dom.setAttribute(el5, "data-parsley-validate", "");
        dom.setAttribute(el5, "class", "form-horizontal form-label-left");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6, "class", "form-group");
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        dom.setAttribute(el7, "class", "control-label col-md-3 col-sm-3 col-xs-12");
        dom.setAttribute(el7, "for", "first-name");
        var el8 = dom.createTextNode("Nominativo ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        dom.setAttribute(el8, "class", "required");
        var el9 = dom.createTextNode("*");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7, "class", "col-md-6 col-sm-6 col-xs-12");
        var el8 = dom.createTextNode("\n                          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6, "class", "form-group");
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        dom.setAttribute(el7, "class", "control-label col-md-3 col-sm-3 col-xs-12");
        dom.setAttribute(el7, "for", "first-name");
        var el8 = dom.createTextNode("Email ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        dom.setAttribute(el8, "class", "required");
        var el9 = dom.createTextNode("*");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7, "class", "col-md-6 col-sm-6 col-xs-12");
        var el8 = dom.createTextNode("\n                          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "modal-footer");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("button");
        dom.setAttribute(el5, "type", "button");
        dom.setAttribute(el5, "class", "btn btn-default");
        dom.setAttribute(el5, "data-dismiss", "modal");
        var el6 = dom.createElement("i");
        dom.setAttribute(el6, "class", "fa");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode(" Annulla");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element3 = dom.childAt(fragment, [2, 1, 1]);
        var element4 = dom.childAt(element3, [3]);
        var element5 = dom.childAt(element4, [3]);
        var morphs = new Array(7);
        morphs[0] = dom.createMorphAt(dom.childAt(element3, [1, 3, 0]), 1, 1);
        morphs[1] = dom.createMorphAt(element4, 1, 1);
        morphs[2] = dom.createMorphAt(dom.childAt(element5, [1, 3]), 1, 1);
        morphs[3] = dom.createMorphAt(dom.childAt(element5, [3, 3]), 1, 1);
        morphs[4] = dom.createMorphAt(element5, 5, 5);
        morphs[5] = dom.createMorphAt(element5, 6, 6);
        morphs[6] = dom.createMorphAt(dom.childAt(element3, [5]), 3, 3);
        return morphs;
      },
      statements: [["content", "session.crea_utente_tipologia_selezionata.etichetta", ["loc", [null, [7, 80], [7, 135]]]], ["inline", "partial", ["partial/campiobbligatori"], [], ["loc", [null, [11, 14], [11, 52]]]], ["inline", "input", [], ["type", "text", "class", "form-control col-md- col-xs-12", "value", ["subexpr", "@mut", [["get", "nominativo", ["loc", [null, [18, 91], [18, 101]]]]], [], []], "placeholder", "Nominativo"], ["loc", [null, [18, 26], [18, 130]]]], ["inline", "input", [], ["type", "text", "class", "form-control col-md- col-xs-12", "value", ["subexpr", "@mut", [["get", "email", ["loc", [null, [26, 91], [26, 96]]]]], [], []], "placeholder", "Email"], ["loc", [null, [26, 26], [26, 120]]]], ["block", "if", [["get", "flag_azienda", ["loc", [null, [29, 22], [29, 34]]]]], [], 0, null, ["loc", [null, [29, 16], [42, 23]]]], ["block", "if", [["get", "session.crea_utente_tipologia_selezionata.percentuale", ["loc", [null, [43, 24], [43, 77]]]]], [], 1, null, ["loc", [null, [43, 18], [72, 25]]]], ["block", "if", [["get", "signActiveNuovo", ["loc", [null, [80, 20], [80, 35]]]]], [], 2, 3, ["loc", [null, [80, 14], [84, 21]]]]],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  })());
});
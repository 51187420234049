define('portale-vigilanza/routes/istitutogestioni/index', ['exports', 'ember', 'simple-auth/mixins/authenticated-route-mixin', 'portale-vigilanza/mixins/checkMessage/check-message', 'ember-cli-pagination/remote/route-mixin'], function (exports, _ember, _simpleAuthMixinsAuthenticatedRouteMixin, _portaleVigilanzaMixinsCheckMessageCheckMessage, _emberCliPaginationRemoteRouteMixin) {
    exports['default'] = _ember['default'].Route.extend(_simpleAuthMixinsAuthenticatedRouteMixin['default'], _portaleVigilanzaMixinsCheckMessageCheckMessage['default'], _emberCliPaginationRemoteRouteMixin['default'], {

        queryParams: {
            sort: { refreshModel: true },
            order: { refreshModel: true }
        },

        beforeModel: function beforeModel() {
            this.controllerFor('istitutogestioni.index').set('modelIsLoad', false);
        },
        afterModel: function afterModel() {
            this.controllerFor('istitutogestioni.index').set('modelIsLoad', true);
        },

        model: function model(params) {
            return this.findPaged('gestioneistituto', params);
        }

    });
});
define('portale-vigilanza/models/timbratura', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        //
        codice_plesso: _emberData['default'].attr('string'),
        data_timbratura: _emberData['default'].attr('string'),
        codice_tag: _emberData['default'].attr('string'),
        codice_timbratore: _emberData['default'].attr('string'),
        tipo_timbratura: _emberData['default'].attr('string'),
        posizione: _emberData['default'].attr('string'),
        isEntrata: (function () {
            if (this.get('tipo_timbratura') == 'E' || this.get('tipo_timbratura') == 'e') return true;else return false;
        }).property('tipo_timbratura'),
        isUscita: (function () {
            if (this.get('tipo_timbratura') == 'U' || this.get('tipo_timbratura') == 'u') return true;else return false;
        }).property('tipo_timbratura')
    });
});
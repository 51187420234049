define('portale-vigilanza/controllers/ordini/index', ['exports', 'ember', 'portale-vigilanza/mixins/sortList/sort-list', 'portale-vigilanza/mixins/download/download'], function (exports, _ember, _portaleVigilanzaMixinsSortListSortList, _portaleVigilanzaMixinsDownloadDownload) {
  exports['default'] = _ember['default'].ArrayController.extend(_portaleVigilanzaMixinsSortListSortList['default'], _portaleVigilanzaMixinsDownloadDownload['default'], {
    namePage: 'ordini',

    queryParams: ['messaggio', "page", "sort", "order"],
    sort: 'created_at',
    order: null,
    modelIsLoad: false,
    messaggio: null,

    controllaMessaggio: (function () {
      if (this.get('messaggio') !== '') {
        var _this = this;
        setTimeout(function () {
          _this.set('errorModal', true);
          _this.set('titoloModal', 'Messaggio');
          _this.set('messaggioModal', _this.get('messaggio'));
          _ember['default'].$('#myModal').modal('show');
        }, 1000);
      }
    }).observes('messaggio'),

    actions: {
      //  ** Azione pulsante del modal **
      toggleConfirm: function toggleConfirm() {
        _ember['default'].$('#myModal').modal('hide');
      }

    }

  });
});
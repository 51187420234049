define('portale-vigilanza/helpers/check-class', ['exports', 'ember'], function (exports, _ember) {
  exports.checkclasse = checkclasse;

  function checkclasse(input, classtrue, classfalse) {
    var valore = '';
    if (!input) {
      valore = classfalse;
    } else {
      valore = classtrue;
    }

    return new _ember['default'].Handlebars.SafeString(valore);
  }

  exports['default'] = _ember['default'].Handlebars.makeBoundHelper(checkclasse);
});
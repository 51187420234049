define("portale-vigilanza/templates/spiderweblogin", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.11",
          "loc": {
            "source": null,
            "start": {
              "line": 35,
              "column": 12
            },
            "end": {
              "line": 37,
              "column": 12
            }
          },
          "moduleName": "portale-vigilanza/templates/spiderweblogin.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h2");
          var el2 = dom.createTextNode("Autenticato");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.11",
          "loc": {
            "source": null,
            "start": {
              "line": 37,
              "column": 12
            },
            "end": {
              "line": 39,
              "column": 12
            }
          },
          "moduleName": "portale-vigilanza/templates/spiderweblogin.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h2");
          var el2 = dom.createTextNode("Impossibile autenticarsi");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@1.13.11",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 43,
            "column": 6
          }
        },
        "moduleName": "portale-vigilanza/templates/spiderweblogin.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "section");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "container");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "row");
        var el4 = dom.createTextNode("\n\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("form class=\"form-horizontal\">\n                <div class=\"col-md-12\">\n                    <h1 align=\"center\">LOGIN FROM SPIDERWEB</h1>\n                </div>\n                <div class=\"col-md-3\" style=\"height: 100%;\"></div>\n                <div class=\"col-md-6\" style=\"height: 100%;\">\n                    <div class=\"form-group\">\n                        <label for=\"user\" class=\"col-sm-4 control-label\">Utente</label>\n                        <div class=\"col-sm-8\">\n                            {{input type='text' value=identification id='identification' class='form-control' placeholder='Utente'}}\n                        </div>\n                    </div>\n                    <div class=\"form-group\">\n                        <label for=\"password\" class=\"col-sm-4 control-label\">Password</label>\n                        <div class=\"col-sm-8\">\n                            {{input type='text' value=password class='form-control' placeholder='Password'}}\n                        </div>\n                    </div>\n                    <div align=\"center\">\n{{#if activeSign}}\n                            <button type=\"button\" class=\"btn btn-info\" {{action 'authenticate'}}>Login</button>\n                        {{else}}\n                            <button type=\"button\" class=\"btn btn-info disabled\">Login</button>\n                        {{/if}}\n                    </div>\n                </div>\n                <div class=\"col-md-3\" style=\"height: 100%;\"></div>\n            </form");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h4");
        var el5 = dom.createTextNode("Autenticazione in corso......");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0, 1, 1]), 5, 5);
        return morphs;
      },
      statements: [["block", "if", [["get", "activeSign", ["loc", [null, [35, 18], [35, 28]]]]], [], 0, 1, ["loc", [null, [35, 12], [39, 19]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});
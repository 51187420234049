define('portale-vigilanza/controllers/recuperapassword', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    queryParams: ['token', 'email'],

    hasPasswordConfirm: _ember['default'].computed.notEmpty('model.password_confirmation'),
    hasPassword: _ember['default'].computed.notEmpty('model.password'),
    hasEmail: _ember['default'].computed.notEmpty('email'),
    isValid: _ember['default'].computed.and('hasPasswordConfirm', 'hasPassword', 'hasEmail'),

    signActive: false,

    activeSign: (function () {
      if (this.get('isValid')) {
        this.set('signActive', true);
      } else {
        this.set('signActive', false);
      }
    }).observes('email', 'model.password', 'model.password_confirmation'),

    actions: {

      //  ** Azione pulsante del modal **
      toggleConfirm: function toggleConfirm() {
        _ember['default'].$('#myModal').modal('hide');
        this.transitionToRoute('index');
      },

      cambiaPassword: function cambiaPassword() {

        _ember['default'].$('body').addClass('loading');
        this.set('model.token', this.get('token'));
        this.set('model.email', this.get('email'));

        if (this.get('model.password') !== this.get('model.password_confirmation')) {
          this.set('errorModal', true);
          this.set('titoloModal', 'Recupero Password');
          this.set('messaggioModal', 'Le due password inserite non corrispondono.');
          _ember['default'].$('#myModal').modal('show');
          _ember['default'].$('body').removeClass('loading');
        } else {

          var _this = this;
          this.get('model').save().then(function () {
            _this.set('errorModal', false);
            _this.set('titoloModal', 'Recupero Password');
            _this.set('messaggioModal', 'Password reimpostata.');
            _ember['default'].$('#myModal').modal('show');
            _ember['default'].$('body').removeClass('loading');
          }, function (error) {
            _this.set('modelError', _this.get('model'));
            _this.set('errorModal', true);
            _this.set('titoloModal', 'Recupero Password');
            _this.set('messaggioModal', 'Errore nella procedura di impostazione password.<br> La password inserita non rispetta i criteri indicati.');
            _ember['default'].$('#myModal').modal('show');
            _ember['default'].$('body').removeClass('loading');
          });
        }
      }

    }

  });
});
define('portale-vigilanza/helpers/icon-contatto', ['exports', 'ember'], function (exports, _ember) {
    exports.iconContatto = iconContatto;

    function iconContatto(descrizione) {
        var img = '';
        if (descrizione == 'Cellulare') img = '<i class="fa fa-mobile fa-fw"></i>';
        if (descrizione == 'Telefono') img = '<i class="fa fa-phone fa-fw"></i>';
        if (descrizione == 'Mail') img = '<i class="fa fa-envelope-o fa-fw"></i>';
        return new _ember['default'].Handlebars.SafeString(img);
    }

    exports['default'] = _ember['default'].Handlebars.makeBoundHelper(iconContatto);
});
/* jshint ignore:start */

define('portale-vigilanza/config/environment', ['ember'], function(Ember) {
  var prefix = 'portale-vigilanza';
/* jshint ignore:start */

try {
  var metaName = prefix + '/config/environment';
  var rawConfig = Ember['default'].$('meta[name="' + metaName + '"]').attr('content');
  var config = JSON.parse(unescape(rawConfig));

  return { 'default': config };
}
catch(err) {
  throw new Error('Could not read config from meta tag with name "' + metaName + '".');
}

/* jshint ignore:end */

});

if (!runningTests) {
  require("portale-vigilanza/app")["default"].create({"MaxQuota":100,"QuotaFormato":100,"maxRicarica":1100,"NCheckMessage":2,"ZoomMaps":16,"SiteKey":"6LfVNRITAAAAAJTO3ix12D3GUO3vC5dLHTZRT0bC","PathFile":"download","MessaggioCompletaTestImpianto":"Contatta la centrale operativa al 030 3537500 per effettuare le prove sul funzionamento del tuo impianto d'allarme","MessaggioRipartizioneProvvigione":"Ripartizione della provvigione del plesso con il","Tipologie_plesso":[{"id":"Abitazione","text":"Abitazione"},{"id":"Bar","text":"Bar"},{"id":"Negozio","text":"Negozio"},{"id":"Ufficio","text":"Ufficio"}],"Bonifico_Intestazione":"Vigilanza Group","Bonifico_Indirizzo":"Via Fura, 14 - 25125 Brescia - Italy","Bonifico_Banca":"BCC di Brescia","Bonifico_Iban":"IT10 W086 9211 2020 1700 0170 873","V4Y_NomeSocieta":"vigilo4you","Bonifico_V4Y_Intestazione":"Vigilo For You S.r.l","Bonifico_V4Y_Indirizzo":"Via Fura, 18 - 25125 Brescia - Italy","Bonifico_V4Y_Banca":"Banca Valsabbina","Bonifico_V4Y_Iban":"IT88 H051 1611 2000 0000 0057 314","API_NAMESPACE":"api/web/v1","API_HOST":"https://api.vigilanzagroup.it","API_WHITELIST":"https://api.vigilanzagroup.it","SUB_NAME":"","VERSIONE":" 2.1","SENTRYURL":"https://25848eb2fa564252b02c1b929c6d526b@sentry.3dsteam.it/12","SHOWRELOADCONF":false,"name":"portale-vigilanza","version":"0.0.0+38708e70","API_ADD_TRAILING_SLASHES":true});
}

/* jshint ignore:end */

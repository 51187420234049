define('portale-vigilanza/controllers/sedes/edit', ['exports', 'portale-vigilanza/mixins/datiFatturaziones/manage-model-mixin'], function (exports, _portaleVigilanzaMixinsDatiFatturazionesManageModelMixin) {
    exports['default'] = Ember.Controller.extend(_portaleVigilanzaMixinsDatiFatturazionesManageModelMixin['default'], {

        listaProvince: null,
        listComuni: null,
        loadComuni: (function () {

            var model = this.model;
            var comune = model.get('indirizzoComune');
            var a = this.store.find('comuni', { provincia: model.get('indirizzoProvincia'), 'case': 'upper' });
            this.set('listComuni', a);
            var myController = this;

            a.then(function () {
                console.log('cambio comune ' + comune);
                model.set('indirizzoComune', comune);
            });

            console.log('cambio provincia' + model.get('indirizzoProvincia'));
        }).observes('model.indirizzoProvincia')

    });
});
/**
 * Created by alessandro.fornasari on 24/09/2015.
 */
define('portale-vigilanza/routes/kit/index', ['exports', 'ember', 'simple-auth/mixins/authenticated-route-mixin', 'portale-vigilanza/mixins/checkMessage/check-message'], function (exports, _ember, _simpleAuthMixinsAuthenticatedRouteMixin, _portaleVigilanzaMixinsCheckMessageCheckMessage) {
  exports['default'] = _ember['default'].Route.extend(_simpleAuthMixinsAuthenticatedRouteMixin['default'], _portaleVigilanzaMixinsCheckMessageCheckMessage['default'], {

    queryParams: {
      plesso: { refreshModel: true },
      tag: { refreshModel: true }
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      var _this = this;
      setTimeout(function () {
        controller.set('listKit', _this.store.query('prodotto', { plesso: controller.get('plesso'), tag: controller.get('tag') }));
      }, 1);

      if (controller.get('selezione') === 'si') {
        controller.set('selezioneAttiva', true);
      } else {
        controller.set('selezioneAttiva', false);
      }
    }

  });
});
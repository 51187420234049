define('portale-vigilanza/controllers/account/modifica', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    panel_visualizza: 'dati',

    selectTipo: null,
    tipo_select: null,

    arrayPrefissi: [{
      id: "30",
      text: "30"
    }, {
      id: "31",
      text: "31"
    }, {
      id: "32",
      text: "32"
    }, {
      id: "33",
      text: "33"
    }, {
      id: "34",
      text: "34"
    }, {
      id: "35",
      text: "35"
    }, {
      id: "351",
      text: "351"
    }, {
      id: "352",
      text: "352"
    }, {
      id: "353",
      text: "353"
    }, {
      id: "354",
      text: "354"
    }, {
      id: "355",
      text: "355"
    }, {
      id: "356",
      text: "356"
    }, {
      id: "357",
      text: "357"
    }, {
      id: "358",
      text: "358"
    }, {
      id: "359",
      text: "359"
    }, {
      id: "36",
      text: "36"
    }, {
      id: "37",
      text: "37"
    }, {
      id: "370",
      text: "370"
    }, {
      id: "371",
      text: "371"
    }, {
      id: "372",
      text: "372"
    }, {
      id: "373",
      text: "373"
    }, {
      id: "374",
      text: "374"
    }, {
      id: "375",
      text: "375"
    }, {
      id: "376",
      text: "376"
    }, {
      id: "377",
      text: "377"
    }, {
      id: "378",
      text: "378"
    }, {
      id: "379",
      text: "379"
    }, {
      id: "38",
      text: "38"
    }, {
      id: "380",
      text: "380"
    }, {
      id: "381",
      text: "381"
    }, {
      id: "382",
      text: "382"
    }, {
      id: "383",
      text: "383"
    }, {
      id: "385",
      text: "385"
    }, {
      id: "386",
      text: "386"
    }, {
      id: "387",
      text: "387"
    }, {
      id: "388",
      text: "388"
    }, {
      id: "389",
      text: "389"
    }, {
      id: "39",
      text: "39"
    }, {
      id: "40",
      text: "40"
    }, {
      id: "41",
      text: "41"
    }, {
      id: "42",
      text: "42"
    }, {
      id: "420",
      text: "420"
    }, {
      id: "421",
      text: "421"
    }, {
      id: "423",
      text: "423"
    }, {
      id: "43",
      text: "43"
    }, {
      id: "44",
      text: "44"
    }, {
      id: "45",
      text: "45"
    }, {
      id: "46",
      text: "46"
    }, {
      id: "47",
      text: "47"
    }, {
      id: "48",
      text: "48"
    }, {
      id: "49",
      text: "49"
    }],

    queryParams: ['azione'],
    azione: null,

    namePage: 'account',

    titoloModal: 'Modifica Account',
    messaggioModal: 'Modifiche salvate correttamente',
    errorModal: false,

    prefissoCellulare: {
      id: "39",
      text: "39"
    },

    correggiPersonalizzato: true,

    listaDispositivi: null,
    dispositivoEliminare: null,

    //verifica campi modifica
    hasEmail: _ember['default'].computed.notEmpty('model.email'),
    hasNominativo: _ember['default'].computed.notEmpty('model.nominativo'),
    isValidModifica: _ember['default'].computed.and('hasEmail', 'hasNominativo'),
    signActiveModifica: false,
    activeSignModifica: (function () {
      if (this.get('isValidModifica')) {
        this.set('signActiveModifica', true);
      } else {
        this.set('signActiveModifica', false);
      }
    }).observes('model.email', 'model.nominativo'),

    //verifica campi cambia password
    hasPassword: _ember['default'].computed.notEmpty('model.password'),
    hasPasswordNew: _ember['default'].computed.notEmpty('model.password_new'),
    hasPasswordConfirm: _ember['default'].computed.notEmpty('model.password_confirmation'),
    isValidCambia: _ember['default'].computed.and('hasPassword', 'hasPasswordNew', 'hasPasswordConfirm'),
    signActiveCambia: false,
    activeSignCambia: (function () {
      console.log('isValidCambia');
      if (this.get('isValidCambia')) {
        this.set('signActiveCambia', true);
      } else {
        this.set('signActiveCambia', false);
      }
    }).observes('model.password', 'model.password_new', 'model.password_confirmation'),

    //verifica campi documento utente
    hasTipo: _ember['default'].computed.notEmpty('tipo_select'),
    hasNumero: _ember['default'].computed.notEmpty('documentoutente.numero'),
    hasAutorita: _ember['default'].computed.notEmpty('documentoutente.autorita'),
    hasData: _ember['default'].computed.notEmpty('documentoutente.data_emissione_format'),
    isValidDocumento: _ember['default'].computed.and('hasTipo', 'hasNumero', 'hasAutorita', 'hasData'),
    signActiveDocumento: false,
    activeSignDocumento: (function () {
      console.log('activeSignDocumento');
      if (this.get('isValidDocumento')) {
        this.set('signActiveDocumento', true);
      } else {
        this.set('signActiveDocumento', false);
      }
    }).observes('tipo_select', 'documentoutente.numero', 'documentoutente.autorita', 'documentoutente.data_emissione_format'),

    controllaTipo: (function () {
      if (this.get('documentoutente.tipo')) {
        this.set('tipo_select', this.get('documentoutente.tipo'));
      }
    }).observes('documentoutente.tipo'),

    //  ** Modifica elenco comuni  **
    loadComuni: (function () {
      this.set('citySelected', null);
      this.set('listComuni', this.store.find('comuni', {
        provincia: this.get('proviceSelected'),
        'case': 'upper'
      }));
    }).observes('proviceSelected'),

    actions: {
      //  ** Apri modal Privacy **
      apriPrivacy: function apriPrivacy() {
        _ember['default'].$('#modalPrivacy').modal('show');
      },

      //  ** Azione pulsante del modal **
      toggleConfirm: function toggleConfirm(conferma) {
        console.log('reload');
        _ember['default'].$('.modal').modal('hide');
        this.get('model').reload();
        var _this = this;
        console.log('Check conferma. Conferma: ' + conferma);
        if (conferma == 'conferma_cellulare') {
          console.log('conferma del cellulare');
          _this.set('panel_visualizza', 'dati');
          _this.transitionTo('account.modifica', {
            queryParams: {
              azione: 'dati'
            }
          });
        }
      },

      //  ** Salvataggio account **
      save: function save() {
        var _this = this;
        //svuoto le pass
        this.set('model.password', '');
        this.set('model.password_new', '');
        this.set('model.password_confirmation', '');
        //salvo il modello
        this.get('model').save().then(function () {
          _this.set('errorModal', false);
          _this.set('titoloModal', 'Modifica Account');
          _this.set('messaggioModal', 'Modifiche salvate correttamente');
          _ember['default'].$('#myModal').modal('show');
        }, function (error) {
          _this.set('modelError', _this.get('model'));
          _this.set('errorModal', true);
          _this.set('titoloModal', 'Modifica Account');
          _this.set('messaggioModal', 'Errore nel salvataggio');
          _ember['default'].$('#myModal').modal('show');
        });
      },

      //  ** Cambio Password **
      cambiaPassword: function cambiaPassword() {

        var erroreDati = false;
        var messaggioErrore = '';

        //controllo che corrispondano le due nuove password
        if (this.get('model.password_new') !== this.get('model.password_confirmation')) {
          messaggioErrore += '- la nuova password e la conferma non corrispondono<br>';
          erroreDati = true;
        }

        if (!erroreDati) {

          var _this = this;
          //salvo il modello
          this.get('model').save().then(function () {
            _this.set('errorModal', false);
            _this.set('titoloModal', 'Cambio password');
            _this.set('messaggioModal', 'Modifiche salvate correttamente');
            _ember['default'].$('#myModal').modal('show');
          }, function (error) {
            _this.set('modelError', _this.get('model'));
            _this.set('errorModal', true);
            _this.set('titoloModal', 'Cambio password');
            _this.set('messaggioModal', 'Errore nel salvataggio');
            _ember['default'].$('#myModal').modal('show');
          });
        } else {
          this.set('errorModal', true);
          this.set('titoloModal', 'Cambio password');
          this.set('messaggioModal', 'Errore nei dati inseriti: <br /> <p class="alert-danger">' + messaggioErrore + '</p>');
          _ember['default'].$('#myModal').modal('show');
        }
      },

      //  ** Azione modifica del numero di telefono **
      modificaCellulare: function modificaCellulare() {
        if (this.get('nuovoCellulare')) {
          _ember['default'].$('#modificaCel').modal('hide');
          this.store.unloadAll('modificacellulare');
          var modificacellulare = this.store.createRecord('modificacellulare');
          modificacellulare.set('tipo', 'modifica');
          modificacellulare.set('cellulare', this.get('prefissoCellulare.id') + this.get('nuovoCellulare'));

          var _this = this;
          //salvo il modello
          modificacellulare.save().then(function () {
            _ember['default'].$('#confermaCel').modal('show');
            /*
             _this.set('errorModal',false);
             _this.set('titoloModal','Cambio cellulare');
             _this.set('messaggioModal','');
             Ember.$('#myModal').modal('show');*/
          }, function (error) {
            _this.set('modelError', _this.get('modificacellulare'));
            _this.set('errorModal', true);
            _this.set('titoloModal', 'Cambio cellulare');
            //_this.set('messaggioModal','Errore nella modifica del cellulare <br>'+error);
            _this.set('messaggioModal', 'Errore nella modifica del cellulare');
            _ember['default'].$('#myModal').modal('show');
          });
        }
      },

      //  ** Azione conferma del numero di telefono **
      confermaCellulare: function confermaCellulare() {
        if (this.get('codiceConferma')) {
          this.store.unloadAll('modificacellulare');
          var modificacellulare = this.store.createRecord('modificacellulare');
          modificacellulare.set('tipo', 'conferma');
          modificacellulare.set('codice', this.get('codiceConferma'));

          var _this = this;
          //salvo il modello
          modificacellulare.save().then(function () {
            _this.set('errorModal', false);
            _this.set('titoloModal', 'Conferma cellulare');
            _this.set('confermaModal', 'conferma_cellulare');
            _this.set('messaggioModal', 'Cellulare confermato');

            if (!_this.get('session.telefono_confermato')) {
              _this.set('vaiAllaHome', true);
            }

            _this.set('session.telefono_confermato', true);
            _ember['default'].$('#myModal').modal('show');
          }, function (error) {
            _this.set('modelError', _this.get('modificacellulare'));
            _this.set('errorModal', true);
            _this.set('titoloModal', 'Conferma cellulare');
            _this.set('messaggioModal', 'Errore nella conferma del cellulare');
            _ember['default'].$('#myModal').modal('show');
          });
        } else {
          this.set('errorModal', true);
          this.set('titoloModal', 'Conferma cellulare');
          this.set('messaggioModal', 'Devi inserire il codice di conferma');
          _ember['default'].$('#myModal').modal('show');
        }
      },

      //  ** Salvataggio Documento **
      salvaDocumento: function salvaDocumento() {

        //formatto la data
        var documento = this.get('documentoutente');
        var date = moment(documento.get('data_emissione_format'), 'DD/MM/YYYY').format("YYYY-MM-DD");
        documento.set('data_emissione', date);

        //salvo il modello
        var _this = this;
        this.get('documentoutente').save().then(function () {
          _this.set('errorModal', false);
          _this.set('titoloModal', 'Documento');
          _this.set('messaggioModal', 'Salvataggio corretto.');

          if (!_this.get('session.documento_utente_presente')) {
            _this.set('vaiAllaHome', true);
          }

          _this.set('session.documento_utente_presente', true);
          _ember['default'].$('#myModal').modal('show');
        }, function (error) {
          _this.set('modelError', _this.get('documentoutente'));
          _this.set('errorModal', true);
          _this.set('titoloModal', 'Documento');
          _this.set('messaggioModal', 'Errore nel salvataggio');
          _ember['default'].$('#myModal').modal('show');
        });
      },

      confermaEliminazioneDispositivo: function confermaEliminazioneDispositivo(idDispositivo) {
        var _this = this;
        this.store.findRecord('device', idDispositivo).then(function (device) {
          _this.set('dispositivoEliminare', device);
          _ember['default'].$('#confermaEliminaDispositivo').modal('show');
        });
      },

      eliminaDispositivo: function eliminaDispositivo(idDispositivo) {
        _ember['default'].$('#confermaEliminaDispositivo').modal('hide');
        var _this = this;
        this.store.findRecord('device', idDispositivo).then(function (device) {
          device.destroyRecord().then(function () {
            _this.set('errorModal', false);
            _this.set('titoloModal', 'Dispositivo');
            _this.set('messaggioModal', 'Dispositivo eliminato.');
            _this.set('session.documento_utente_presente', true);
            _ember['default'].$('#myModal').modal('show');
          }, function (error) {
            _this.set('modelError', device);
            _this.set('errorModal', true);
            _this.set('titoloModal', 'Dispositivo');
            _this.set('messaggioModal', 'Errore nell\'eliminazione');
            _ember['default'].$('#myModal').modal('show');
          });
        });
      },

      showUserdataExtra: function showUserdataExtra() {
        var _this2 = this;

        /** lista delle province */
        this.set('listaProvince', this.store.find('province'));
        /** userdata */
        this.store.find('userdata').then(function (result) {
          /** setto userdata */
          _this2.set('userdata', result);

          _this2.get('userdata').forEach(function (item) {
            /** setto provincia e comune */
            _this2.set('proviceSelected', item.get('sigla_prov'));
            _this2.set('citySelected', item.get('comune'));
          });

          /** verifico se sia presente */
          if (_this2.get('userdata').content.length === 0)
            /** creo oggetto per la creazione */
            _this2.set('newUserdata', {
              name: null,
              description: null,
              email: null,
              phone: null,
              fax: null,
              comune: null,
              sigla_prov: null,
              indirizzo: null,
              cap: null
            });
        });
      },
      saveUserdataExtra: function saveUserdataExtra() {
        var _this3 = this;

        this.set('errorUserData', null);
        if (this.get('newUserdata')) {
          /** setto provicia e comune */
          this.set('newUserdata.comune', this.get('citySelected'));
          this.set('newUserdata.sigla_prov', this.get('proviceSelected'));
          /** creo nuovo userdata */
          var post = this.store.createRecord('userdata', this.get('newUserdata'));
          /** salvo */
          post.save().then(function (result) {
            _this3.set('successUserData', 'Modifica avvenuta correttamente');
            setTimeout(function () {
              _this3.set('successUserData', null);
            }, 5000);
          })['catch'](function (reason) {
            _this3.set('errorUserData', 'Errore durante la richiesta');
          });
        } else {
          /** modifico user data */
          this.get('userdata').forEach(function (item) {
            /** salvo le modifiche */
            var put = _this3.store.push('userdata', {
              id: item.get('id'),
              name: item.get('name'),
              description: item.get('description'),
              email: item.get('email'),
              phone: item.get('phone'),
              fax: item.get('fax'),
              idIndirizzo: item.get('idIndirizzo'),
              comune: _this3.get('citySelected'),
              sigla_prov: _this3.get('proviceSelected'),
              indirizzo: item.get('indirizzo'),
              cap: item.get('cap')
            });
            /** salvo */
            put.save().then(function (result) {
              _this3.set('successUserData', 'Modifica avvenuta correttamente');
              setTimeout(function () {
                _this3.set('successUserData', null);
              }, 5000);
            })['catch'](function (reason) {
              _this3.set('errorUserData', 'Errore durante la richiesta');
            });
          });
        }
      }
    }
  });
});
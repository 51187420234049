define('portale-vigilanza/controllers/google-map/polygon', ['exports', 'ember', 'portale-vigilanza/controllers/google-map/polyline'], function (exports, _ember, _portaleVigilanzaControllersGoogleMapPolyline) {

  var computed = _ember['default'].computed;
  var alias = computed.alias;

  /**
   * @class GoogleMapPolygonController
   * @extends GoogleMapPolylineController
   */
  exports['default'] = _portaleVigilanzaControllersGoogleMapPolyline['default'].extend({
    fillColor: alias('model.fillColor'),
    fillOpacity: alias('model.fillOpacity')
  });
});
define('portale-vigilanza/authenticators/django-rest', ['exports', 'ember', 'simple-auth/authenticators/base', 'portale-vigilanza/utils/is-secure-url'], function (exports, _ember, _simpleAuthAuthenticatorsBase, _portaleVigilanzaUtilsIsSecureUrl) {
  exports['default'] = _simpleAuthAuthenticatorsBase['default'].extend({

    init: function init() {
      var globalConfig = window.ENV['simple-auth'] || {};
      this.serverTokenEndpoint = globalConfig.serverTokenEndpoint || '/api-token-auth/';
    },

    authenticate: function authenticate(credentials) {
      var _this = this;
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        var data = { grant_type: "password", client_id: "f3d259ddd3ed8ff3843839b", client_secret: "4c7f6f8fa93d59c45502c0ae8c4a95b", username: credentials.identification, password: credentials.password };
        _this.makeRequest(_this.serverTokenEndpoint, data).then(function (response) {
          _ember['default'].run(function () {
            response.token = response.access_token;
            resolve(response);
          });
        }, function (xhr, status, error) {
          _ember['default'].run(function () {
            reject(xhr.responseJSON || xhr.responseText);
          });
        });
      });
    },

    restore: function restore(data) {
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        if (!_ember['default'].isEmpty(data.access_token)) {
          resolve(data);
        } else {
          console.log('Reject restore');
          reject();
        }
      });
    },

    invalidate: function invalidate(data) {
      function success(resolve) {
        resolve();
      }
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        success(resolve);
      });
    },

    makeRequest: function makeRequest(url, data) {
      if (!(0, _portaleVigilanzaUtilsIsSecureUrl['default'])(url)) {
        _ember['default'].Logger.warn('Credentials are transmitted via an insecure connection - use HTTPS to keep them secure.');
      }
      return _ember['default'].$.ajax({
        url: url,
        type: 'POST',
        data: data,
        dataType: 'json',
        contentType: 'application/x-www-form-urlencoded'
      });
    }
  });
});
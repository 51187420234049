define('portale-vigilanza/mixins/plessi/gestioni', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({

    //  ** LISTA ORDINATA DELLE GESTIONI
    gestioni: null,
    listGestioni: null,
    sortProperties: 'data_apertura',
    sortAscending: false,
    sortedList: null,
    loadSortedList: (function () {
      var newList = _ember['default'].ArrayProxy.createWithMixins(_ember['default'].SortableMixin, {
        sortProperties: [this.get('sortProperties')],
        sortAscending: this.get('sortAscending'),
        content: this.get('gestioni')
      });
      this.set('listGestioni', newList);
    }).observes('gestioni'),

    actions: {
      setSortBy: function setSortBy(fieldName) {
        this.set('sortProperties', fieldName);
        this.toggleProperty('sortAscending');
        var newList = _ember['default'].ArrayProxy.createWithMixins(_ember['default'].SortableMixin, {
          sortProperties: [this.get('sortProperties')],
          sortAscending: this.get('sortAscending'),
          content: this.get('gestioni')
        });
        this.set('listGestioni', newList);
      }

    }

  });
});
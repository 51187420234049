define('portale-vigilanza/controllers/copertura/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    actions: {
      'delete': function _delete(id) {
        /** effettuo chiamata eliminazione */
        this.store.findRecord('copertura', id).then(function (record) {
          record.destroyRecord(); // => DELETE
        });
      }
    }
  });
});
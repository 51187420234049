define('portale-vigilanza/routes/sedes/new', ['exports', 'ember', 'portale-vigilanza/mixins/sedes/save-model-mixin'], function (exports, _ember, _portaleVigilanzaMixinsSedesSaveModelMixin) {
    exports['default'] = _ember['default'].Route.extend(_portaleVigilanzaMixinsSedesSaveModelMixin['default'], {
        model: function model() {
            return this.store.createRecord('sede', {
                istituto: this.modelFor('istitutos/show')
            });
        },

        actions: {

            save: function save() {

                var route = this;
                var sede = route.modelFor('sedes/new');

                sede.save().then(function () {
                    route.transitionTo('sedes');
                }, function () {
                    console.log('Failed to save the model');
                });
                /*
                            var istituto    = this.modelFor('istitutos/show');
                            var sede        = this.currentModel;
                            var route       = this;
                            var sedes       = istituto.get('sedes');
                
                            sedes.pushObject(sede);
                            sede.save().then(function() {
                                route.transitionTo('sedes');
                            }, function() {
                                console.log('Failed to save the model');
                            });
                */
            }
        }
        /*
           var istituto = this.modelFor('istitutos/show');
         this.currentModel.istituto = istituto.id;
         var route = this;
         this.currentModel.save().then(function() {
         route.transitionTo('sedes');
         }, function() {
         console.log('Failed to save the model');
         });
              //////////////
        //this.modelFor('istitutos/show')
          var route = this;
        this.currentModel.save().then(function() {
            route.transitionTo('sedes');
        }, function() {
            console.log('Failed to save the model');
        });
        }*/

    });
});
define('portale-vigilanza/routes/application', ['exports', 'ember', 'simple-auth/mixins/application-route-mixin'], function (exports, _ember, _simpleAuthMixinsApplicationRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_simpleAuthMixinsApplicationRouteMixin['default'], {

    activate: function activate() {
      document.title = "Portale Vigilanza Group " + window.ENV.APP.SUB_NAME + window.ENV.APP.VERSIONE;
    },

    actions: {
      invalidateSession: function invalidateSession() {
        this.get('session').invalidate();
      },
      collaps2: function collaps2() {
        console.log('Collaps2');
        _ember['default'].$("#wrapper").toggleClass("toggled-2");
        _ember['default'].$('#menu ul').hide();
      },
      collaps1: function collaps1() {
        console.log('Collaps1');
        _ember['default'].$("#wrapper").toggleClass("toggled");
        _ember['default'].$("#section").toggleClass("toggled");
      },
      refreshPage: function refreshPage() {
        console.log('Pagina refresh');
        this.refresh();
      },

      ricaricaConfigurazioni: function ricaricaConfigurazioni() {

        var _this = this;

        //  *** VARIABILI GENERICHE CONFIG
        _this.set('session.configurazioni', null);
        _this.store.unloadAll('configurazione');
        _this.store.find('configurazione').then(function (configs) {
          configs.forEach(function (config) {

            //_this.set('session.configurazioni',config);
            _this.get('session').set('configurazioni', config);

            //SALVO VARIABILI GENERALI
            _this.set('session.tipo_creatore_corrente', config.get('tipo_creatore_corrente'));
            //_this.set('session.MaxQuota',config.get('percentuale_agenti'));
            _this.set('session.MaxQuota', window.ENV.APP.MaxQuota);

            _this.set('session.htmlHome', config.get('home'));
            _this.set('session.tag_kit', config.get('tag_kit'));

            if (config.get('plessi_non_attivi_messaggi') != '') {
              //recupero il messaggio
              var messaggio = config.get('plessi_non_attivi_messaggio');
              //recupero i plessi che non sono attivi
              var elenco_plessi = '';
              if (config.get('plessi_non_attivi').get('length') > 0) {
                config.get('plessi_non_attivi').forEach(function (opzione) {
                  elenco_plessi += '- <a style="color: white;" href="plessicliente/dettagli?id_plesso=' + opzione['id'] + '&panel_visualizza=servizi&scroll=no">' + opzione['nome'] + ' -></a>';
                });
              }
              messaggio += elenco_plessi;
              _this.set('session.plessi_non_attivi_messaggio', messaggio);
              _this.set('session.plessi_non_attivi_visualizza', true);
            }

            //SALVO L'ELENCO DOCUMENTI UTENTE
            var elencoDocumenti = config.get('elencoDocumentoUtente');
            _this.set('session.elenco_documenti_tipo', config.get('elencoDocumentoUtente'));
            //formatto
            _this.send('formatElenco', elencoDocumenti, 'session.elenco_documenti_tipo');

            //SALVO LE CONFIGURAZIONI PER LA CREAZIONE UTENTI
            //permesso per creare gli utenti
            if (config.get('crea_utente') && config.get('crea_utente').tipologia) {
              if (config.get('crea_utente').tipologia.get('length') > 0) _this.set('session.crea_utente_show', true);
              //array con le tipologie
              _this.set('session.crea_utente_tipologie', config.get('crea_utente').tipologia);
              //note
              if (config.get('crea_utente').Nota) {
                _this.set('session.crea_utente_show_note', true);
                _this.set('session.crea_utente_note', config.get('crea_utente').Nota);
              }
            }

            //SALVO LE CONFIGURAZIONI PER I PLESSI
            _this.set('session.crea_plesso_show', false);
            _this.set('session.crea_plesso_show_scelta', false);
            _this.set('session.crea_plesso_tipo', null);
            if (config.get('crea_plesso').get('length') > 0) {
              //ha il permesso per creare plessi
              _this.set('session.crea_plesso_show', true);
              var elenco_opzioni = [];
              config.get('crea_plesso').forEach(function (opzione) {
                elenco_opzioni.push({
                  id: opzione.id,
                  text: opzione.etichetta,
                  etichetta: opzione.etichetta,
                  agente_corrente: opzione.agente_corrente,
                  cliente_corrente: opzione.cliente_corrente,
                  agenti: opzione.agenti,
                  descrizione: opzione.descrizione,
                  tipoinserimento: opzione.tipoinserimento,
                  provvigione: null
                });
                _this.set('session.crea_plesso_tipo', opzione.id);
              });
              _this.set('session.crea_plesso_tipologie', elenco_opzioni);
              if (config.get('crea_plesso').get('length') == 1) {
                //ha solo un'opzione e non si deve visualizzare il menù di scelta
                _this.set('session.crea_plesso_show_scelta', false);
              } else {
                //ha più opzioni: visualizzo il menù di scelta e pulisco l'opzione predefinita
                _this.set('session.crea_plesso_show_scelta', true);
                _this.set('session.crea_plesso_tipo', null);
              }
            }
          });
        });
      }

    }

  });
});
define('portale-vigilanza/components/circle-perc', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        attributeBindings: ['perc'],
        onChange2: (function () {
            console.log('change2' + this.get("perc"));
            //this.insertMap();
            $(this.$().find('.easy-pie-chart')).data('easyPieChart').update(this.get("perc"));
        }).observes('perc'),

        insertMap: (function () {

            var trackColor = this.get("colortrack") || '#d6d6d6';
            var size = this.get("dim") || 25;
            var valore = this.get("perc") || 0;

            console.log("creo la torta : " + valore);
            $(this.$().find('.easy-pie-chart')).easyPieChart({

                trackColor: trackColor,
                barColor: this.getColor(parseInt(valore) / 100),
                /*trackColor : trackColor,*/
                scaleColor: !1,
                lineCap: 'butt',
                lineWidth: parseInt(size / 8.5),
                animate: 1500,
                rotate: -90,
                size: size,
                onStep: function onStep(a, b, c) {
                    $(this.el).find(".percent").text(Math.round(c));
                }
            });
        }).on('didInsertElement'),
        getColor: function getColor(value) {
            //value from 0 to 1
            var hue = 120 - ((1 - value) * 120).toString(10);
            return ["hsl(", hue, ",50%,60%)"].join("");
        }
    });
});
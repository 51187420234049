define("portale-vigilanza/templates/plessicommercialeinterno/index", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@1.13.11",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 51,
                      "column": 36
                    },
                    "end": {
                      "line": 51,
                      "column": 83
                    }
                  },
                  "moduleName": "portale-vigilanza/templates/plessicommercialeinterno/index.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("attivo");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() {
                  return [];
                },
                statements: [],
                locals: [],
                templates: []
              };
            })();
            var child1 = (function () {
              return {
                meta: {
                  "revision": "Ember@1.13.11",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 51,
                      "column": 83
                    },
                    "end": {
                      "line": 51,
                      "column": 102
                    }
                  },
                  "moduleName": "portale-vigilanza/templates/plessicommercialeinterno/index.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("disattivato");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() {
                  return [];
                },
                statements: [],
                locals: [],
                templates: []
              };
            })();
            var child2 = (function () {
              return {
                meta: {
                  "revision": "Ember@1.13.11",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 53,
                      "column": 72
                    },
                    "end": {
                      "line": 53,
                      "column": 154
                    }
                  },
                  "moduleName": "portale-vigilanza/templates/plessicommercialeinterno/index.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("(");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(")");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["content", "plessoCommercialeInterno.provincia", ["loc", [null, [53, 115], [53, 153]]]]],
                locals: [],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@1.13.11",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 47,
                    "column": 26
                  },
                  "end": {
                    "line": 60,
                    "column": 26
                  }
                },
                "moduleName": "portale-vigilanza/templates/plessicommercialeinterno/index.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("tr");
                var el2 = dom.createTextNode("\n                                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("td");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("td");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("td");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("td");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("td");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode(" ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("td");
                var el3 = dom.createTextNode("\n                                    ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("button");
                dom.setAttribute(el3, "class", "btn btn-success btn-xs");
                dom.setAttribute(el3, "title", "Invia attivazione");
                var el4 = dom.createTextNode("\n                                        ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("i");
                dom.setAttribute(el4, "class", "fa fa-send");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("Re-invia attivazione\n                                    ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                                ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                            ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element0 = dom.childAt(fragment, [1]);
                var element1 = dom.childAt(element0, [9]);
                var element2 = dom.childAt(element0, [11, 1]);
                var morphs = new Array(7);
                morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
                morphs[1] = dom.createMorphAt(dom.childAt(element0, [3]), 0, 0);
                morphs[2] = dom.createMorphAt(dom.childAt(element0, [5]), 0, 0);
                morphs[3] = dom.createMorphAt(dom.childAt(element0, [7]), 0, 0);
                morphs[4] = dom.createMorphAt(element1, 0, 0);
                morphs[5] = dom.createMorphAt(element1, 2, 2);
                morphs[6] = dom.createElementMorph(element2);
                return morphs;
              },
              statements: [["content", "plessoCommercialeInterno.id", ["loc", [null, [49, 36], [49, 67]]]], ["content", "plessoCommercialeInterno.ragione_sociale", ["loc", [null, [50, 36], [50, 80]]]], ["block", "if", [["get", "plessoCommercialeInterno.isAttivo", ["loc", [null, [51, 42], [51, 75]]]]], [], 0, 1, ["loc", [null, [51, 36], [51, 109]]]], ["content", "plessoCommercialeInterno.categoria", ["loc", [null, [52, 36], [52, 74]]]], ["content", "plessoCommercialeInterno.comune", ["loc", [null, [53, 36], [53, 71]]]], ["block", "if", [["get", "plessoCommercialeInterno.provincia", ["loc", [null, [53, 78], [53, 112]]]]], [], 2, null, ["loc", [null, [53, 72], [53, 161]]]], ["element", "action", ["inviaAttivazione", ["get", "plessoCommercialeInterno.id", ["loc", [null, [55, 129], [55, 156]]]]], [], ["loc", [null, [55, 101], [55, 159]]]]],
              locals: ["plessoCommercialeInterno"],
              templates: [child0, child1, child2]
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.11",
              "loc": {
                "source": null,
                "start": {
                  "line": 27,
                  "column": 10
                },
                "end": {
                  "line": 70,
                  "column": 10
                }
              },
              "moduleName": "portale-vigilanza/templates/plessicommercialeinterno/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("\n            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "row dettagli_plessi");
              var el2 = dom.createTextNode("\n\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "col-xs-12 col-sm-12 col-md-12 col-lg-12");
              var el3 = dom.createTextNode("\n\n                    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3, "class", "table-responsive");
              var el4 = dom.createTextNode("\n                      ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("table");
              dom.setAttribute(el4, "class", "table table-striped table-hover");
              var el5 = dom.createTextNode("\n\n                          ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("thead");
              var el6 = dom.createTextNode("\n                          ");
              dom.appendChild(el5, el6);
              var el6 = dom.createElement("tr");
              var el7 = dom.createTextNode("\n                              ");
              dom.appendChild(el6, el7);
              var el7 = dom.createElement("th");
              dom.setAttribute(el7, "class", "col-xs-2 col-sm-2 col-md-1 col-lg-1");
              var el8 = dom.createElement("a");
              dom.setAttribute(el8, "href", "#");
              var el9 = dom.createComment("");
              dom.appendChild(el8, el9);
              dom.appendChild(el7, el8);
              var el8 = dom.createTextNode(" Codice");
              dom.appendChild(el7, el8);
              dom.appendChild(el6, el7);
              var el7 = dom.createTextNode("\n                              ");
              dom.appendChild(el6, el7);
              var el7 = dom.createElement("th");
              dom.setAttribute(el7, "class", "col-xs-2 col-sm-2 col-md-2 col-lg-2");
              var el8 = dom.createElement("a");
              dom.setAttribute(el8, "href", "#");
              var el9 = dom.createComment("");
              dom.appendChild(el8, el9);
              dom.appendChild(el7, el8);
              var el8 = dom.createTextNode(" Nome");
              dom.appendChild(el7, el8);
              dom.appendChild(el6, el7);
              var el7 = dom.createTextNode("\n                              ");
              dom.appendChild(el6, el7);
              var el7 = dom.createElement("th");
              dom.setAttribute(el7, "class", "col-xs-2 col-sm-2 col-md-2 col-lg-2");
              var el8 = dom.createElement("a");
              dom.setAttribute(el8, "href", "#");
              var el9 = dom.createComment("");
              dom.appendChild(el8, el9);
              dom.appendChild(el7, el8);
              var el8 = dom.createTextNode(" Stato");
              dom.appendChild(el7, el8);
              dom.appendChild(el6, el7);
              var el7 = dom.createTextNode("\n                              ");
              dom.appendChild(el6, el7);
              var el7 = dom.createElement("th");
              dom.setAttribute(el7, "class", "col-xs-2 col-sm-2 col-md-1 col-lg-1");
              var el8 = dom.createElement("a");
              dom.setAttribute(el8, "href", "#");
              var el9 = dom.createComment("");
              dom.appendChild(el8, el9);
              dom.appendChild(el7, el8);
              var el8 = dom.createTextNode(" Categoria");
              dom.appendChild(el7, el8);
              dom.appendChild(el6, el7);
              var el7 = dom.createTextNode("\n                              ");
              dom.appendChild(el6, el7);
              var el7 = dom.createElement("th");
              dom.setAttribute(el7, "class", "col-xs-2 col-sm-2 col-md-2 col-lg-2");
              var el8 = dom.createElement("a");
              dom.setAttribute(el8, "href", "#");
              var el9 = dom.createComment("");
              dom.appendChild(el8, el9);
              dom.appendChild(el7, el8);
              var el8 = dom.createTextNode(" Indirizzo");
              dom.appendChild(el7, el8);
              dom.appendChild(el6, el7);
              var el7 = dom.createTextNode("\n                              ");
              dom.appendChild(el6, el7);
              var el7 = dom.createElement("th");
              dom.setAttribute(el7, "class", "col-xs-1 col-sm-1 col-md-1 col-lg-1");
              dom.appendChild(el6, el7);
              var el7 = dom.createTextNode("\n                          ");
              dom.appendChild(el6, el7);
              dom.appendChild(el5, el6);
              var el6 = dom.createTextNode("\n                          ");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n                          ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("tbody");
              var el6 = dom.createTextNode("\n");
              dom.appendChild(el5, el6);
              var el6 = dom.createComment("");
              dom.appendChild(el5, el6);
              var el6 = dom.createTextNode("                          ");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n\n                      ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                    ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n\n                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element3 = dom.childAt(fragment, [1, 1, 1, 1]);
              var element4 = dom.childAt(element3, [1, 1]);
              var element5 = dom.childAt(element4, [1]);
              var element6 = dom.childAt(element4, [3]);
              var element7 = dom.childAt(element4, [5]);
              var element8 = dom.childAt(element4, [7]);
              var element9 = dom.childAt(element4, [9]);
              var morphs = new Array(12);
              morphs[0] = dom.createElementMorph(element5);
              morphs[1] = dom.createMorphAt(dom.childAt(element5, [0]), 0, 0);
              morphs[2] = dom.createElementMorph(element6);
              morphs[3] = dom.createMorphAt(dom.childAt(element6, [0]), 0, 0);
              morphs[4] = dom.createElementMorph(element7);
              morphs[5] = dom.createMorphAt(dom.childAt(element7, [0]), 0, 0);
              morphs[6] = dom.createElementMorph(element8);
              morphs[7] = dom.createMorphAt(dom.childAt(element8, [0]), 0, 0);
              morphs[8] = dom.createElementMorph(element9);
              morphs[9] = dom.createMorphAt(dom.childAt(element9, [0]), 0, 0);
              morphs[10] = dom.createMorphAt(dom.childAt(element3, [3]), 1, 1);
              morphs[11] = dom.createMorphAt(fragment, 3, 3, contextualElement);
              return morphs;
            },
            statements: [["element", "action", ["setSortBy", "id"], [], ["loc", [null, [38, 78], [38, 105]]]], ["inline", "sort-icon", ["id", ["get", "sort", ["loc", [null, [38, 135], [38, 139]]]], ["get", "order", ["loc", [null, [38, 140], [38, 145]]]]], [], ["loc", [null, [38, 118], [38, 147]]]], ["element", "action", ["setSortBy", "ragione_sociale"], [], ["loc", [null, [39, 78], [39, 118]]]], ["inline", "sort-icon", ["ragione_sociale", ["get", "sort", ["loc", [null, [39, 161], [39, 165]]]], ["get", "order", ["loc", [null, [39, 166], [39, 171]]]]], [], ["loc", [null, [39, 131], [39, 173]]]], ["element", "action", ["setSortBy", "isAttivo"], [], ["loc", [null, [40, 78], [40, 111]]]], ["inline", "sort-icon", ["isAttivo", ["get", "sort", ["loc", [null, [40, 147], [40, 151]]]], ["get", "order", ["loc", [null, [40, 152], [40, 157]]]]], [], ["loc", [null, [40, 124], [40, 159]]]], ["element", "action", ["setSortBy", "categoria"], [], ["loc", [null, [41, 78], [41, 112]]]], ["inline", "sort-icon", ["categoria", ["get", "sort", ["loc", [null, [41, 149], [41, 153]]]], ["get", "order", ["loc", [null, [41, 154], [41, 159]]]]], [], ["loc", [null, [41, 125], [41, 161]]]], ["element", "action", ["setSortBy", "comune"], [], ["loc", [null, [42, 78], [42, 109]]]], ["inline", "sort-icon", ["comune", ["get", "sort", ["loc", [null, [42, 143], [42, 147]]]], ["get", "order", ["loc", [null, [42, 148], [42, 153]]]]], [], ["loc", [null, [42, 122], [42, 155]]]], ["block", "each", [["get", "model", ["loc", [null, [47, 62], [47, 67]]]]], [], 0, null, ["loc", [null, [47, 26], [60, 35]]]], ["inline", "page-numbers", [], ["content", ["subexpr", "@mut", [["get", "content", ["loc", [null, [69, 35], [69, 42]]]]], [], []]], ["loc", [null, [69, 12], [69, 44]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.11",
            "loc": {
              "source": null,
              "start": {
                "line": 25,
                "column": 8
              },
              "end": {
                "line": 71,
                "column": 8
              }
            },
            "moduleName": "portale-vigilanza/templates/plessicommercialeinterno/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "model.length", ["loc", [null, [27, 16], [27, 28]]]]], [], 0, null, ["loc", [null, [27, 10], [70, 17]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.11",
            "loc": {
              "source": null,
              "start": {
                "line": 71,
                "column": 8
              },
              "end": {
                "line": 73,
                "column": 8
              }
            },
            "moduleName": "portale-vigilanza/templates/plessicommercialeinterno/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "partial", ["partial/loading"], [], ["loc", [null, [72, 10], [72, 39]]]]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.11",
          "loc": {
            "source": null,
            "start": {
              "line": 6,
              "column": 2
            },
            "end": {
              "line": 79,
              "column": 2
            }
          },
          "moduleName": "portale-vigilanza/templates/plessicommercialeinterno/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "id", "section");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "container");
          var el3 = dom.createTextNode("\n\n\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "col-xs-12 col-sm-7 col-md-7 col-lg-4");
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("h1");
          dom.setAttribute(el4, "class", "page-title txt-color-blueDark");
          var el5 = dom.createTextNode("\n\n                    ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment(" PAGE HEADER ");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("i");
          dom.setAttribute(el5, "class", "fa fa-fw fa-list ");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                    Elenco Immobili\n\n                ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "clearfix");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("p");
          var el4 = dom.createTextNode(" ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 7, 7);
          return morphs;
        },
        statements: [["block", "if", [["get", "modelIsLoad", ["loc", [null, [25, 14], [25, 25]]]]], [], 0, 1, ["loc", [null, [25, 8], [73, 15]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.11",
          "loc": {
            "source": null,
            "start": {
              "line": 79,
              "column": 2
            },
            "end": {
              "line": 81,
              "column": 2
            }
          },
          "moduleName": "portale-vigilanza/templates/plessicommercialeinterno/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "partial", ["partial/errorepermessi"], [], ["loc", [null, [80, 4], [80, 40]]]]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@1.13.11",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 88,
            "column": 6
          }
        },
        "moduleName": "portale-vigilanza/templates/plessicommercialeinterno/index.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "id", "main");
        dom.setAttribute(el1, "role", "main");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment(" /#wrapper ");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment(" Modal ");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element10 = dom.childAt(fragment, [4]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[2] = dom.createMorphAt(element10, 3, 3);
        morphs[3] = dom.createMorphAt(element10, 7, 7);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "partial", ["partial/header"], [], ["loc", [null, [1, 0], [1, 28]]]], ["inline", "partial", ["partial/menu"], [], ["loc", [null, [2, 0], [2, 26]]]], ["block", "if", [["get", "session.VediPlessi_CommercialeInterno", ["loc", [null, [6, 8], [6, 45]]]]], [], 0, 1, ["loc", [null, [6, 2], [81, 9]]]], ["inline", "partial", ["partial/modal"], [], ["loc", [null, [85, 2], [85, 29]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});
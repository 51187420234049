define('portale-vigilanza/controllers/fatture/index', ['exports', 'ember', 'portale-vigilanza/mixins/sortList/sort-list', 'portale-vigilanza/mixins/download/download', 'ember-cli-pagination/remote/route-mixin'], function (exports, _ember, _portaleVigilanzaMixinsSortListSortList, _portaleVigilanzaMixinsDownloadDownload, _emberCliPaginationRemoteRouteMixin) {

  //export default Ember.ArrayController.extend(Download, SortList, {
  exports['default'] = _ember['default'].Controller.extend(_emberCliPaginationRemoteRouteMixin['default'], _portaleVigilanzaMixinsDownloadDownload['default'], _portaleVigilanzaMixinsSortListSortList['default'], {

    namePage: 'fatture',

    queryParams: ["page", "sort", "order"],
    sort: 'data',
    order: 'desc',
    modelIsLoad: false,

    listDatiFatturazione: null,

    actions: {

      //  ** Ricerca delle fatture **
      searchFatture: function searchFatture() {
        this.set('modelIsLoad', false);
        var _this = this;
        this.findPaged('fattura', { page: 1, sort: this.get('sort'), order: this.get('order'), data_fattura_start: this.get('dataFatturaStart'), data_fattura_end: this.get('dataFatturaEnd'), data_scadenza_start: this.get('dataScadenzaStart'), data_scadenza_end: this.get('dataScadenzaEnd'), numero: this.get('numeroFattura'), codice_fattura: this.get('codice_fatturazione') }).then(function (data) {
          _this.set('model', data);
          _this.set('modelIsLoad', true);
        });
      },

      pulisci: function pulisci() {
        this.set('dataFatturaStart', null);
        this.set('dataFatturaEnd', null);
        this.set('dataScadenzaStart', null);
        this.set('dataScadenzaEnd', null);
        this.set('numeroFattura', null);
        this.set('codice_fatturazione', null);
        this.send('searchFatture');
      }

    }

  });
});
define('portale-vigilanza/models/datifatturazionefornitore', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({

    richiedibile_type: _emberData['default'].attr('string'),
    richiedibile_id: _emberData['default'].attr('string'),

    codice_fatturazione: _emberData['default'].attr('string'),

    stato: _emberData['default'].attr('string'),
    ragione_sociale: _emberData['default'].attr('string'),
    ragione_sociale2: _emberData['default'].attr('string'),
    nome: _emberData['default'].attr('string'),

    codice_fiscale: _emberData['default'].attr('string'),
    partita_iva: _emberData['default'].attr('string'),

    banca: _emberData['default'].attr('string'),
    iban: _emberData['default'].attr('string'),

    sede_legale_indirizzo: _emberData['default'].attr('string'),
    sede_legale_cap: _emberData['default'].attr('string'),
    sede_legale_comune: _emberData['default'].attr('string'),
    sede_legale_provincia: _emberData['default'].attr('string'),
    sede_legale_nazione: _emberData['default'].attr('string'),
    codice_assoggettamento_fiscale: _emberData['default'].attr('string')

  });
});
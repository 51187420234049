define('portale-vigilanza/controllers/plessicommercialeinterno/index', ['exports', 'ember', 'portale-vigilanza/mixins/sortList/sort-list'], function (exports, _ember, _portaleVigilanzaMixinsSortListSortList) {
  exports['default'] = _ember['default'].ArrayController.extend(_portaleVigilanzaMixinsSortListSortList['default'], {

    namePage: 'plessicommercialeinterno',

    queryParams: ["page", "sort", "order"],
    sort: 'id',
    order: 'asc',
    modelIsLoad: false,

    actions: {

      //  ** Azione pulsante del modal **
      toggleConfirm: function toggleConfirm() {
        _ember['default'].$('#myModal').modal('hide');
        this.transitionToRoute('index');
      },

      inviaAttivazione: function inviaAttivazione(codice_plesso) {

        var messaggio = this.store.createRecord('messaggio');
        messaggio.set('tipologia', 'reInvioAttivazione');
        var dati = { codice_plesso: codice_plesso };
        messaggio.set('dati', dati);
        var _this = this;

        messaggio.save().then(function (risposta) {
          var chiamata = JSON.stringify(risposta);
          chiamata = chiamata.split(",");
          var ris = chiamata[2].replace('"risultato":', '');
          ris = ris.replace('"', '');
          ris = ris.replace('"}', '');
          console.log(ris);
          if (ris == 'true') {
            _this.set('errorModal', false);
            _this.set('titoloModal', 'Invio Mail');
            _this.set('messaggioModal', 'Invio della mail d\'attivazione predisposto.');
            _ember['default'].$('#myModal').modal('show');
          } else {
            _this.set('errorModal', true);
            _this.set('titoloModal', 'Invio Mail');
            _this.set('messaggioModal', 'Mail non inviata. L\'utente è già attivo.');
            _ember['default'].$('#myModal').modal('show');
          }
        }, function (error) {
          this.set('errorModal', true);
          _this.set('titoloModal', 'Invio Mail');
          _this.set('messaggioModal', 'Errore nell\'invio della mail');
          _ember['default'].$('#myModal').modal('show');
        });
      }

    }

  });
});
define("portale-vigilanza/templates/plessiistituto/index", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@1.13.11",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 75,
                      "column": 36
                    },
                    "end": {
                      "line": 75,
                      "column": 111
                    }
                  },
                  "moduleName": "portale-vigilanza/templates/plessiistituto/index.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["inline", "format-date", [["get", "plessoIstituto.attivato_at", ["loc", [null, [75, 83], [75, 109]]]]], [], ["loc", [null, [75, 67], [75, 111]]]]],
                locals: [],
                templates: []
              };
            })();
            var child1 = (function () {
              return {
                meta: {
                  "revision": "Ember@1.13.11",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 75,
                      "column": 111
                    },
                    "end": {
                      "line": 75,
                      "column": 120
                    }
                  },
                  "moduleName": "portale-vigilanza/templates/plessiistituto/index.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("-");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() {
                  return [];
                },
                statements: [],
                locals: [],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@1.13.11",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 70,
                    "column": 26
                  },
                  "end": {
                    "line": 82,
                    "column": 26
                  }
                },
                "moduleName": "portale-vigilanza/templates/plessiistituto/index.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("tr");
                var el2 = dom.createTextNode("\n                                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("td");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("td");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("br");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode(" (");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode(")");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("<td>{{#if plessoIstituto.isAttivo}}Attivo da {{else}}Non attivo da {{/if}}{{plessoIstituto.attivato_ago}}</td>");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("td");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n\n                                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("td");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("td");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("<td>{{ untag plessoIstituto.tag}}</td>");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n\n                            ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element0 = dom.childAt(fragment, [1]);
                var element1 = dom.childAt(element0, [3]);
                var morphs = new Array(7);
                morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
                morphs[1] = dom.createMorphAt(element1, 0, 0);
                morphs[2] = dom.createMorphAt(element1, 2, 2);
                morphs[3] = dom.createMorphAt(element1, 4, 4);
                morphs[4] = dom.createMorphAt(dom.childAt(element0, [7]), 0, 0);
                morphs[5] = dom.createMorphAt(dom.childAt(element0, [9]), 0, 0);
                morphs[6] = dom.createMorphAt(dom.childAt(element0, [11]), 0, 0);
                return morphs;
              },
              statements: [["content", "plessoIstituto.id", ["loc", [null, [72, 36], [72, 57]]]], ["content", "plessoIstituto.ragione_sociale", ["loc", [null, [73, 36], [73, 70]]]], ["content", "plessoIstituto.comune", ["loc", [null, [73, 74], [73, 99]]]], ["content", "plessoIstituto.provincia", ["loc", [null, [73, 101], [73, 129]]]], ["block", "if", [["get", "plessoIstituto.isAttivo", ["loc", [null, [75, 42], [75, 65]]]]], [], 0, 1, ["loc", [null, [75, 36], [75, 127]]]], ["content", "plessoIstituto.categoria", ["loc", [null, [77, 36], [77, 64]]]], ["content", "plessoIstituto.sede", ["loc", [null, [78, 36], [78, 59]]]]],
              locals: ["plessoIstituto"],
              templates: [child0, child1]
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.11",
              "loc": {
                "source": null,
                "start": {
                  "line": 48,
                  "column": 10
                },
                "end": {
                  "line": 92,
                  "column": 10
                }
              },
              "moduleName": "portale-vigilanza/templates/plessiistituto/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("\n            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "row dettagli_plessi");
              var el2 = dom.createTextNode("\n\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "col-xs-12 col-sm-12 col-md-12 col-lg-12");
              var el3 = dom.createTextNode("\n\n                    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3, "class", "table-responsive");
              var el4 = dom.createTextNode("\n                      ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("table");
              dom.setAttribute(el4, "class", "table table-striped table-hover");
              var el5 = dom.createTextNode("\n\n                          ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("thead");
              var el6 = dom.createTextNode("\n                          ");
              dom.appendChild(el5, el6);
              var el6 = dom.createElement("tr");
              var el7 = dom.createTextNode("\n                              ");
              dom.appendChild(el6, el7);
              var el7 = dom.createElement("th");
              dom.setAttribute(el7, "class", "col-xs-1 col-sm-1 col-md-1 col-lg-1");
              var el8 = dom.createElement("a");
              dom.setAttribute(el8, "href", "#");
              var el9 = dom.createComment("");
              dom.appendChild(el8, el9);
              dom.appendChild(el7, el8);
              var el8 = dom.createTextNode(" Codice");
              dom.appendChild(el7, el8);
              dom.appendChild(el6, el7);
              var el7 = dom.createTextNode("\n                              ");
              dom.appendChild(el6, el7);
              var el7 = dom.createElement("th");
              dom.setAttribute(el7, "class", "col-xs-2 col-sm-2 col-md-2 col-lg-2");
              var el8 = dom.createElement("a");
              dom.setAttribute(el8, "href", "#");
              var el9 = dom.createComment("");
              dom.appendChild(el8, el9);
              dom.appendChild(el7, el8);
              var el8 = dom.createTextNode(" Nominativo cliente");
              dom.appendChild(el7, el8);
              dom.appendChild(el6, el7);
              var el7 = dom.createTextNode("\n                              ");
              dom.appendChild(el6, el7);
              var el7 = dom.createComment("<th class=\"col-xs-1 col-sm-1 col-md-1 col-lg-1\" {{action \"setSortBy\" \"isAttivo\"}}><a href=\"#\">{{sort-icon 'isAttivo' sort order}}</a> Stato</th>!");
              dom.appendChild(el6, el7);
              var el7 = dom.createTextNode("\n                              ");
              dom.appendChild(el6, el7);
              var el7 = dom.createElement("th");
              dom.setAttribute(el7, "class", "col-xs-1 col-sm-1 col-md-1 col-lg-1");
              var el8 = dom.createElement("a");
              dom.setAttribute(el8, "href", "#");
              var el9 = dom.createComment("");
              dom.appendChild(el8, el9);
              dom.appendChild(el7, el8);
              var el8 = dom.createTextNode(" Attivo Dal");
              dom.appendChild(el7, el8);
              dom.appendChild(el6, el7);
              var el7 = dom.createTextNode("\n                              ");
              dom.appendChild(el6, el7);
              var el7 = dom.createElement("th");
              dom.setAttribute(el7, "class", "col-xs-1 col-sm-1 col-md-1 col-lg-1");
              var el8 = dom.createElement("a");
              dom.setAttribute(el8, "href", "#");
              var el9 = dom.createComment("");
              dom.appendChild(el8, el9);
              dom.appendChild(el7, el8);
              var el8 = dom.createTextNode(" Categoria");
              dom.appendChild(el7, el8);
              dom.appendChild(el6, el7);
              var el7 = dom.createTextNode("\n                              ");
              dom.appendChild(el6, el7);
              var el7 = dom.createElement("th");
              dom.setAttribute(el7, "class", "col-xs-1 col-sm-1 col-md-1 col-lg-1");
              var el8 = dom.createElement("a");
              dom.setAttribute(el8, "href", "#");
              var el9 = dom.createComment("");
              dom.appendChild(el8, el9);
              dom.appendChild(el7, el8);
              var el8 = dom.createTextNode(" Sede associata");
              dom.appendChild(el7, el8);
              dom.appendChild(el6, el7);
              var el7 = dom.createTextNode("\n                              ");
              dom.appendChild(el6, el7);
              var el7 = dom.createComment("<th class=\"col-xs-1 col-sm-1 col-md-1 col-lg-1\" {{action \"setSortBy\" \"tag\"}}><a href=\"#\">{{sort-icon 'tag' sort order}}</a> Tag</th>");
              dom.appendChild(el6, el7);
              var el7 = dom.createTextNode("\n\n                          ");
              dom.appendChild(el6, el7);
              dom.appendChild(el5, el6);
              var el6 = dom.createTextNode("\n                          ");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n                          ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("tbody");
              var el6 = dom.createTextNode("\n");
              dom.appendChild(el5, el6);
              var el6 = dom.createComment("");
              dom.appendChild(el5, el6);
              var el6 = dom.createTextNode("                          ");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n\n                      ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                    ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n\n                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element2 = dom.childAt(fragment, [1, 1, 1, 1]);
              var element3 = dom.childAt(element2, [1, 1]);
              var element4 = dom.childAt(element3, [1]);
              var element5 = dom.childAt(element3, [3]);
              var element6 = dom.childAt(element3, [7]);
              var element7 = dom.childAt(element3, [9]);
              var element8 = dom.childAt(element3, [11]);
              var morphs = new Array(12);
              morphs[0] = dom.createElementMorph(element4);
              morphs[1] = dom.createMorphAt(dom.childAt(element4, [0]), 0, 0);
              morphs[2] = dom.createElementMorph(element5);
              morphs[3] = dom.createMorphAt(dom.childAt(element5, [0]), 0, 0);
              morphs[4] = dom.createElementMorph(element6);
              morphs[5] = dom.createMorphAt(dom.childAt(element6, [0]), 0, 0);
              morphs[6] = dom.createElementMorph(element7);
              morphs[7] = dom.createMorphAt(dom.childAt(element7, [0]), 0, 0);
              morphs[8] = dom.createElementMorph(element8);
              morphs[9] = dom.createMorphAt(dom.childAt(element8, [0]), 0, 0);
              morphs[10] = dom.createMorphAt(dom.childAt(element2, [3]), 1, 1);
              morphs[11] = dom.createMorphAt(fragment, 3, 3, contextualElement);
              return morphs;
            },
            statements: [["element", "action", ["setSortBy", "id"], [], ["loc", [null, [59, 78], [59, 105]]]], ["inline", "sort-icon", ["id", ["get", "sort", ["loc", [null, [59, 135], [59, 139]]]], ["get", "order", ["loc", [null, [59, 140], [59, 145]]]]], [], ["loc", [null, [59, 118], [59, 147]]]], ["element", "action", ["setSortBy", "ragione_sociale"], [], ["loc", [null, [60, 78], [60, 118]]]], ["inline", "sort-icon", ["ragione_sociale", ["get", "sort", ["loc", [null, [60, 161], [60, 165]]]], ["get", "order", ["loc", [null, [60, 166], [60, 171]]]]], [], ["loc", [null, [60, 131], [60, 173]]]], ["element", "action", ["setSortBy", "attivato_at"], [], ["loc", [null, [62, 78], [62, 114]]]], ["inline", "sort-icon", ["attivato_at", ["get", "sort", ["loc", [null, [62, 153], [62, 157]]]], ["get", "order", ["loc", [null, [62, 158], [62, 163]]]]], [], ["loc", [null, [62, 127], [62, 165]]]], ["element", "action", ["setSortBy", "categoria"], [], ["loc", [null, [63, 78], [63, 112]]]], ["inline", "sort-icon", ["categoria", ["get", "sort", ["loc", [null, [63, 149], [63, 153]]]], ["get", "order", ["loc", [null, [63, 154], [63, 159]]]]], [], ["loc", [null, [63, 125], [63, 161]]]], ["element", "action", ["setSortBy", "sede"], [], ["loc", [null, [64, 78], [64, 107]]]], ["inline", "sort-icon", ["sede", ["get", "sort", ["loc", [null, [64, 139], [64, 143]]]], ["get", "order", ["loc", [null, [64, 144], [64, 149]]]]], [], ["loc", [null, [64, 120], [64, 151]]]], ["block", "each", [["get", "model", ["loc", [null, [70, 52], [70, 57]]]]], [], 0, null, ["loc", [null, [70, 26], [82, 35]]]], ["inline", "page-numbers", [], ["content", ["subexpr", "@mut", [["get", "content", ["loc", [null, [91, 35], [91, 42]]]]], [], []]], ["loc", [null, [91, 12], [91, 44]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.11",
            "loc": {
              "source": null,
              "start": {
                "line": 46,
                "column": 8
              },
              "end": {
                "line": 93,
                "column": 8
              }
            },
            "moduleName": "portale-vigilanza/templates/plessiistituto/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "model.length", ["loc", [null, [48, 16], [48, 28]]]]], [], 0, null, ["loc", [null, [48, 10], [92, 17]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.11",
            "loc": {
              "source": null,
              "start": {
                "line": 93,
                "column": 8
              },
              "end": {
                "line": 95,
                "column": 8
              }
            },
            "moduleName": "portale-vigilanza/templates/plessiistituto/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "partial", ["partial/loading"], [], ["loc", [null, [94, 10], [94, 39]]]]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.11",
          "loc": {
            "source": null,
            "start": {
              "line": 6,
              "column": 2
            },
            "end": {
              "line": 101,
              "column": 2
            }
          },
          "moduleName": "portale-vigilanza/templates/plessiistituto/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "id", "section");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "container");
          var el3 = dom.createTextNode("\n\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "col-xs-12 col-sm-7 col-md-7 col-lg-4");
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("h1");
          dom.setAttribute(el4, "class", "page-title txt-color-blueDark");
          var el5 = dom.createTextNode("\n\n                    ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment(" PAGE HEADER ");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("br");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("i");
          dom.setAttribute(el5, "class", "fa fa-fw fa-list ");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                    Elenco Immobili\n\n                ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "clearfix");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("p");
          var el4 = dom.createTextNode(" ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("form");
          dom.setAttribute(el3, "class", "form-inline");
          var el4 = dom.createTextNode("\n\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "row");
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5, "class", "form-group col-sm-2 col-xs-12");
          var el6 = dom.createTextNode("\n                        ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("label");
          dom.setAttribute(el6, "for", "aa");
          var el7 = dom.createTextNode("Parola chiave");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                        ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("br");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                        ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                    ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n\n                    ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5, "class", "form-group col-sm-3 col-xs-12");
          var el6 = dom.createTextNode("\n                        ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("br");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                        ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("button");
          dom.setAttribute(el6, "type", "button");
          dom.setAttribute(el6, "class", "btn btn-default");
          var el7 = dom.createElement("i");
          dom.setAttribute(el7, "class", "fa fa-eraser");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode(" Pulisci");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                        ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("button");
          dom.setAttribute(el6, "type", "button");
          dom.setAttribute(el6, "class", "btn btn-success");
          var el7 = dom.createElement("i");
          dom.setAttribute(el7, "class", "fa fa-search");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode(" Cerca");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                    ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("br");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n\n\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element9 = dom.childAt(fragment, [1, 1]);
          var element10 = dom.childAt(element9, [7, 1]);
          var element11 = dom.childAt(element10, [3]);
          var element12 = dom.childAt(element11, [3]);
          var element13 = dom.childAt(element11, [5]);
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(dom.childAt(element10, [1]), 5, 5);
          morphs[1] = dom.createElementMorph(element12);
          morphs[2] = dom.createElementMorph(element13);
          morphs[3] = dom.createMorphAt(element9, 11, 11);
          return morphs;
        },
        statements: [["inline", "input", [], ["type", "text", "value", ["subexpr", "@mut", [["get", "nome_plesso", ["loc", [null, [30, 50], [30, 61]]]]], [], []], "class", "form-control"], ["loc", [null, [30, 24], [30, 85]]]], ["element", "action", ["pulisci"], [], ["loc", [null, [35, 70], [35, 90]]]], ["element", "action", ["searchPlessi"], [], ["loc", [null, [36, 70], [36, 95]]]], ["block", "if", [["get", "modelIsLoad", ["loc", [null, [46, 14], [46, 25]]]]], [], 0, 1, ["loc", [null, [46, 8], [95, 15]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.11",
          "loc": {
            "source": null,
            "start": {
              "line": 101,
              "column": 2
            },
            "end": {
              "line": 103,
              "column": 2
            }
          },
          "moduleName": "portale-vigilanza/templates/plessiistituto/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "partial", ["partial/errorepermessi"], [], ["loc", [null, [102, 4], [102, 40]]]]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@1.13.11",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 106,
            "column": 6
          }
        },
        "moduleName": "portale-vigilanza/templates/plessiistituto/index.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "id", "main");
        dom.setAttribute(el1, "role", "main");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment(" /#wrapper ");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[2] = dom.createMorphAt(dom.childAt(fragment, [4]), 3, 3);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "partial", ["partial/header"], [], ["loc", [null, [1, 0], [1, 28]]]], ["inline", "partial", ["partial/menu"], [], ["loc", [null, [2, 0], [2, 26]]]], ["block", "if", [["get", "session.VediPlessi_Istituto", ["loc", [null, [6, 8], [6, 35]]]]], [], 0, 1, ["loc", [null, [6, 2], [103, 9]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});
define('portale-vigilanza/mixins/utente/nuovo', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({

    //verifica campi modifica
    hasNominativo: _ember['default'].computed.notEmpty('nominativo'),
    hasAzienda: _ember['default'].computed.notEmpty('azienda'),
    hasEmail: _ember['default'].computed.notEmpty('email'),
    isValidNuovo: _ember['default'].computed.and('hasNominativo', 'hasEmail'),
    signActiveNuovo: false,
    activeSignNuovo: (function () {
      if (this.get('isValidNuovo')) {
        if (this.get('flag_azienda')) {
          console.log('hasAzienda ' + this.get('hasAzienda'));
          if (this.get('hasAzienda')) {
            this.set('signActiveNuovo', true);
          } else {
            this.set('signActiveNuovo', false);
          }
        } else {
          this.set('signActiveNuovo', true);
        }
      } else {
        this.set('signActiveNuovo', false);
      }
    }).observes('email', 'nominativo', 'azienda'),

    actions: {

      //  ** Azione pulsante del modal **
      toggleConfirm: function toggleConfirm() {
        _ember['default'].$('#myModal').modal('hide');
        this.transitionToRoute('home');
      },

      creaUtente: function creaUtente(tipologia) {

        //reset dei dati del modal
        this.set('azienda', null);
        this.set('nominativo', null);
        this.set('email', null);
        this.set('quota', 0);

        //recupero i dati della tipologia
        var array_tipologie = this.get('session.crea_utente_tipologie');
        var tipologia = tipologia;

        var _this = this;
        array_tipologie.filter(function (obj) {
          console.log(tipologia);
          console.log(obj.id);
          if (obj.id == tipologia) {
            _this.set('session.crea_utente_tipologia_selezionata', obj);
            _this.set('info_percentuale', _this.get('session.MessaggioRipartizioneProvvigione') + ' ' + obj.etichetta);
            console.log('flag_azienda' + obj.flag_azienda);
            _this.set('flag_azienda', obj.flag_azienda);
          }
        });

        _ember['default'].$('#modalNuovoUtente').modal('show');
      },

      salvaUtente: function salvaUtente() {

        var user = this.store.createRecord('user');
        user.set('nominativo', this.get('nominativo'));
        user.set('email', this.get('email'));
        user.set('azienda', this.get('azienda'));
        user.set('quota', this.get('quota'));
        user.set('ruolo', this.get('session.crea_utente_tipologia_selezionata.id'));

        //salvo il modello
        var _this = this;
        user.save().then(function () {
          _ember['default'].$('#modalNuovoUtente').modal('hide');
          _this.set('errorModal', false);
          _this.set('titoloModal', 'Nuovo Utente');
          _this.set('messaggioModal', 'Salvataggio corretto');
          _ember['default'].$('#myModal').modal('show');
        }, function (error) {
          _ember['default'].$('#modalNuovoUtente').modal('hide');
          _this.set('modelError', user);
          _this.set('errorModal', true);
          _this.set('titoloModal', 'Nuovo Utente');
          _this.set('messaggioModal', 'Errore nel salvataggio');
          _ember['default'].$('#myModal').modal('show');
        });
      }

    }

  });
});
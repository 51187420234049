define('portale-vigilanza/controllers/spiderweblogin', ['exports', 'ember'], function (exports, _ember) {
    //import permission from '../objects/permission';

    exports['default'] = _ember['default'].Controller.extend({

        queryParams: ['theme', 'identification', 'password', 'weblogin'],
        theme: null,
        isVigilo: false,

        titoloModal: 'Login',
        messaggioModal: '',
        errorModal: false,

        checkTheme: (function () {
            if (this.get('theme') === 'vigilo4you') {
                this.set('isVigilo', true);
            } else {
                this.set('isVigilo', false);
            }
        }).observes('theme'),
        checkAutologin: (function () {
            _ember['default'].Logger.info('checkAutologin ' + this.get('weblogin'));

            if (this.get('weblogin') === 'true') {
                _ember['default'].Logger.info(this.get('identification') + ' - ' + this.get('password'));
                this.send('authenticate');
            }
        }).observes('weblogin'),

        showMail: false,

        authenticator: 'authenticator:django-rest',
        actions: {

            //  ** Azione pulsante del modal **
            toggleConfirm: function toggleConfirm() {
                _ember['default'].$('#myModal').modal('hide');
                this.set('showMail', false);
                this.set('email', '');
            },

            //  ** Azione visualizza redirect **
            avvisoCambio: function avvisoCambio() {
                _ember['default'].$('#modalRedirect').modal('show');
                var _this = this;
                setTimeout(function () {
                    _this.set('isVigilo', false);
                    _ember['default'].$('#modalRedirect').modal('hide');
                }, 2000);
            },

            authenticate: function authenticate() {
                var _this = this;
                var data = this.getProperties('identification', 'password');
                var result = this.get('session').authenticate('authenticator:django-rest', data).then(function () {

                    //***VARIABILI GENERALI
                    _this.set('session.view_message', true);
                    _this.set('session.count_check_message', window.ENV.APP.NCheckMessage);
                    _this.set('session.showreloadconf', window.ENV.APP.SHOWRELOADCONF);
                    _this.set('session.MaxQuota', window.ENV.APP.MaxQuota);

                    //***DATI UTENTE
                    _this.store.find('currentuser').then(function (utenti) {
                        utenti.forEach(function (utente) {
                            _this.set('session.id', utente.get('id'));
                            _this.set('session.nominativo', utente.get('nominativo'));
                            _this.set('session.email', utente.get('email'));
                            _this.set('session.cellulare', utente.get('cellulare'));
                            _this.set('session.telefono_confermato', utente.get('telefono_confermato'));
                            _this.set('session.documento_utente_presente', utente.get('documento_utente_presente'));
                            _this.set('session.crediti', utente.get('crediti'));
                            _this.set('session.is_vigilo', utente.get('is_vigilo'));
                            _this.set('session.is_vigilo_attivo', utente.get('is_vigilo_attivo'));
                        });
                    });

                    //***GRUPPI
                    _this.store.find('group').then(function (groups) {
                        groups.forEach(function (group) {
                            switch (group.get('name')) {
                                case 'cliente':
                                    _this.set('session.isCliente', true);break;
                                case 'procacciatore':
                                    _this.set('session.isProcacciatore', true);break;
                                case 'installatore':
                                    _this.set('session.isInstallatore', true);break;
                                default:
                                    _this.set('session.idUser', group.get('name'));
                            }
                        });
                    });

                    //***PERMESSI
                    _this.store.find('permission').then(function (permissions) {
                        permissions.forEach(function (permission) {
                            switch (permission.get('name')) {
                                case 'CreaPlesso_Cliente':
                                    _this.set('session.CreaPlesso_Cliente', true);break;
                                case 'CreaPlesso_Commerciale':
                                    _this.set('session.CreaPlesso_Commerciale', true);break;
                                case 'CreaPlesso_Procacciatore':
                                    _this.set('session.CreaPlesso_Procacciatore', true);break;
                                case 'CreaPlesso_Installatore':
                                    _this.set('session.CreaPlesso_Installatore', true);break;
                                case 'CreaPlesso_Terzi':
                                    _this.set('session.CreaPlesso_Terzi', true);break;
                                case 'CreaPlesso_MostraScelte':
                                    _this.set('session.CreaPlesso_MostraScelte', true);break;
                                case 'Vedi_fatture':
                                    _this.set('session.Vedi_fatture', true);break;

                                case 'Crea_Procacciatore':
                                    _this.set('session.Crea_Procacciatore', true);break;
                                case 'Crea_Installatore':
                                    _this.set('session.Crea_Installatore', true);break;
                                case 'Crea_Vittoria':
                                    _this.set('session.Crea_Vittoria', true);break;

                                case 'VediPlessi_Completi':
                                    _this.set('session.VediPlessi_Completi', true);break;
                                case 'VediPlessi_Quote':
                                    _this.set('session.VediPlessi_Quote', true);break;
                                case 'VediPlessi_CommercialeInterno':
                                    _this.set('session.VediPlessi_CommercialeInterno', true);break;

                                case 'VediPlessi_Istituto':
                                    _this.set('session.VediPlessi_Istituto', true);break;
                                case 'Istituto_Gestioni':
                                    _this.set('session.Istituto_Gestioni', true);break;
                                //case 'VediGestione_eventi': _this.set('session.VediGestione_eventi',true); break; sostituito da Plesso_Gestioni
                                case 'Istituto_View':
                                    _this.set('session.Istituto_View', true);break;

                                case 'Vedi_Provvigioni':
                                    _this.set('session.Vedi_Provvigioni', true);break;

                                // Controllo sezioni plesso
                                case 'Plesso_Servizi':
                                    _this.set('session.Plesso_Servizi', true);break;
                                case 'Plesso_Localizzazione':
                                    _this.set('session.Plesso_Localizzazione', true);break;
                                case 'Plesso_Recapiti':
                                    _this.set('session.Plesso_Recapiti', true);break;
                                case 'Plesso_DatiFatturazione':
                                    _this.set('session.Plesso_DatiFatturazione', true);break;
                                case 'Plesso_Gestioni':
                                    _this.set('session.Plesso_Gestioni', true);break;
                                case 'Plesso_RecapitiSulPosto':
                                    _this.set('session.Plesso_RecapitiSulPosto', true);break;
                                case 'Plesso_Timbrature':
                                    _this.set('session.Plesso_Timbrature', true);break;
                                case 'Vedi_Contratto':
                                    _this.set('session.Vedi_Contratto', true);break;

                            }
                        });
                    });

                    //creo manualmente dei permessi
                    _this.set('session.VediPlessi_Completi', true);
                    _this.set('session.CreaPlesso_Cliente', true);
                    _this.set('session.isCliente', true);
                    _this.set('session.Crea_Installatore', true);
                    _this.set('session.Crea_Procacciatore', true);

                    var timeout = 0;
                    if (_this.get('isVigilo')) {
                        timeout = 1990;
                        _this.send('avvisoCambio');
                    }
                    setTimeout(function () {
                        //vado alla home
                        _this.transitionToRoute('home');
                    }, timeout);
                }, function (error) {
                    _this.set('errorModal', true);
                    _this.set('titoloModal', 'Login');
                    _this.set('messaggioModal', 'Nome utente o password errati');
                    _ember['default'].$('#myModal').modal('show');
                });
            },

            recuperaPassword: function recuperaPassword() {
                var timeout = 0;
                if (this.get('isVigilo')) {
                    timeout = 1990;
                    this.send('avvisoCambio');
                }

                var _this = this;
                setTimeout(function () {
                    if (_this.get('showMail')) {
                        _this.set('showMail', false);
                        _this.set('email', '');
                    } else _this.set('showMail', true);
                }, timeout);
            },

            inviaMail: function inviaMail() {

                this.set('model', this.store.createRecord('emailreminder'));
                this.set('model.email', this.get('email'));

                var _this = this;
                this.get('model').save().then(function () {
                    _this.set('errorModal', false);
                    _this.set('titoloModal', 'Recupero Password');
                    _this.set('messaggioModal', 'Mail con la nuova password inviata.');
                    _ember['default'].$('#myModal').modal('show');
                }, function (error) {
                    _this.set('errorModal', true);
                    _this.set('titoloModal', 'Recupero Password');
                    _this.set('messaggioModal', 'Mail non riconosciuta.');
                    _ember['default'].$('#myModal').modal('show');
                });
            }

        }

    });
});
define('portale-vigilanza/helpers/menu-active', ['exports', 'ember'], function (exports, _ember) {
  exports.menuActive = menuActive;

  function menuActive(menu, pagina) {
    var classMenu = '';
    if (menu === pagina) {
      classMenu = 'active';
    }

    return new _ember['default'].Handlebars.SafeString(classMenu);
  }

  exports['default'] = _ember['default'].Handlebars.makeBoundHelper(menuActive);
});
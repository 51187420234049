define('portale-vigilanza/models/sede', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        //
        nome: _emberData['default'].attr('string'),
        descrizione: _emberData['default'].attr('string'),
        faxOperativo: _emberData['default'].attr('string'),
        //fax_operativo: DS.attr('string'),
        telOperativo: _emberData['default'].attr('string'),
        indirizzoIndirizzo: _emberData['default'].attr('string'),
        indirizzoCap: _emberData['default'].attr('string'),
        indirizzoComune: _emberData['default'].attr('string'),
        indirizzoProvincia: _emberData['default'].attr('string'),
        indirizzoLocalita: _emberData['default'].attr('string'),
        indirizzoStato: _emberData['default'].attr('string', { defaultValue: '' }),
        istituto: _emberData['default'].belongsTo('istituto')

    });
});
define('portale-vigilanza/helpers/stato-provvigione', ['exports', 'ember'], function (exports, _ember) {
  exports.statoProvvigione = statoProvvigione;

  function statoProvvigione(stato) {
    var img = '';
    switch (stato) {
      case 'incorso':
        img = '<span class="badge alert-danger"><i class="glyphicon glyphicon-list-alt"></i> IN CORSO</span>';
        break;
      case 'richiedibile':
        img = '<span class="badge alert-warning"><i class="glyphicon glyphicon-check"></i> RICHIEDIBILE</span>';
        break;
      case 'richiesto':
        img = '<span class="badge alert-info"><i class="glyphicon glyphicon-share"></i> FATTURA INOLTRATA</span>';
        break;
      case 'pagato':
        img = '<span class="badge alert-success"><i class="glyphicon glyphicon-ok"></i> CONTABILIZZATO</span>';
        break;
      case 'chiuso':
        //img = '<span class="badge alert-default"><i class="glyphicon glyphicon-list-alt"></i> CHIUSO</span>';
        break;
    }

    return new _ember['default'].Handlebars.SafeString(img);
  }

  exports['default'] = _ember['default'].Handlebars.makeBoundHelper(statoProvvigione);
});
define('portale-vigilanza/models/ordine', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({

        tipologia: _emberData['default'].attr('string'),

        has_polizza: _emberData['default'].attr('boolean'),

        stato: _emberData['default'].attr('string'),
        created_at: _emberData['default'].attr('string'),
        pagato_at: _emberData['default'].attr('string'),
        metodo_pagamento_id: _emberData['default'].attr('string'),
        totale_netto: _emberData['default'].attr('number'),
        totale_iva: _emberData['default'].attr('number'),
        codice: _emberData['default'].attr('string'),
        totale_sconto: _emberData['default'].attr('string'),
        codice_fatturazione: _emberData['default'].attr('string'),
        redirect_url: _emberData['default'].attr('string'),
        items: _emberData['default'].attr('items'),
        fattura_id: _emberData['default'].attr('items'),
        spedizione_required: _emberData['default'].attr('boolean'),
        societa: _emberData['default'].attr('string'),

        check_marketing: _emberData['default'].attr('boolean'),

        nominativo: _emberData['default'].attr('string'),
        indirizzo: _emberData['default'].attr('string'),
        cap: _emberData['default'].attr('string'),
        comune: _emberData['default'].attr('string'),
        localita: _emberData['default'].attr('string'),
        provincia: _emberData['default'].attr('string'),
        bootstrapClassStatus: (function () {
            console.log('stato ordine ' + this.get('stato'));
            console.log('rifiutato' == this.get('stato'));

            if ('pendente' == this.get('stato')) {
                return 'warning';
            }
            if ('rifiutato' == this.get('stato')) {
                return 'danger';
            }
            return 'default';
        }).property("stato"),

        isBonifico: (function () {
            return 'bonifico' == this.get('metodo_pagamento_id');
        }).property("metodo_pagamento_id"),
        isPaypal: (function () {
            return 'paypal' == this.get('metodo_pagamento_id');
        }).property("metodo_pagamento_id"),
        isContanti: (function () {
            return 'contanti' == this.get('metodo_pagamento_id');
        }).property("metodo_pagamento_id")

    });
});
define('portale-vigilanza/routes/sedes/index', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        actions: {
            remove: function remove(model) {
                if (confirm('Are you sure?')) {
                    model.destroyRecord();
                }
            },
            save: function save(model) {
                model.save();
                return false;
            }
        },
        model: function model() {

            return this.modelFor('istitutos/show').get('sedes');

            //return this.store.findAll('sede');
        } /*
          ,
           deactivate: function() {
               if (this.currentModel.get('isNew')) {
                   this.currentModel.deleteRecord();
               } else {
                   this.currentModel.rollbackAttributes();
               }
           }*/
    });
});
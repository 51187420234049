define('portale-vigilanza/helpers/untag', ['exports', 'ember'], function (exports, _ember) {
    exports.untag = untag;

    function untag(tag) {
        if (tag == null) {
            return '';
        }
        return tag.split('_').join(' ');
    }

    exports['default'] = _ember['default'].Handlebars.makeBoundHelper(untag);
});
define('portale-vigilanza/components/view-count', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    conteggio: (function () {

      var count = _ember['default'].$('#contatore').val();
      count++;
      _ember['default'].$('#contatore').val(count);
      console.log(_ember['default'].$('#contatore').val());
    }).on("didInsertElement")

  });
});
define('portale-vigilanza/routes/istitutos/show', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({

        model: function model(params) {
            this.istituto_id = params.istituto_id;
            return this.store.findRecord('istituto', this.istituto_id);
        },

        istituto_id: null,
        dataFatturazioneType: "Istituto",
        setupController: function setupController(controller, model) {

            this._super(controller, model);
            var a = this.store.find('datifatturazione', { id: model.id, type: this.dataFatturazioneType });
            a.then(function () {
                if (a.get('length') === 1) {
                    controller.set('datifatturazione', a.objectAt(0));
                    //controller.set('datifatturazione_stato', 'edit');
                    console.log('datifatturazione caricati in show');
                }
            })['catch'](function (error) {
                console.log('datifatturazione non caricabili');
            });
        }

    });
});
/**
 * Created by alessandro.fornasari on 09/09/2015.
 */
define('portale-vigilanza/routes/plessicliente/dettagli', ['exports', 'ember', 'simple-auth/mixins/authenticated-route-mixin', 'portale-vigilanza/mixins/checkMessage/check-message', 'ember-cli-pagination/remote/route-mixin'], function (exports, _ember, _simpleAuthMixinsAuthenticatedRouteMixin, _portaleVigilanzaMixinsCheckMessageCheckMessage, _emberCliPaginationRemoteRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_simpleAuthMixinsAuthenticatedRouteMixin['default'], _portaleVigilanzaMixinsCheckMessageCheckMessage['default'], _emberCliPaginationRemoteRouteMixin['default'], {

    queryParams: {
      id_plesso: { refreshModel: true }
    },

    model: function model(params) {
      //this.store.unloadAll('plessicliente');
      return this.store.findById('plessicliente', params.id_plesso);
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);

      //reimposto le variabili
      controller.set('flagContratto', false);
      controller.set('perPage', 20);
      controller.set('showFormNuovi', false);
      controller.set('showDatiFatturazione', false);
      controller.set('showRichiediNuovi', true);
      controller.set('showRichiediModifica', false);
      controller.set('showRichiestaInCorso', false);
      controller.set('showMessaggioRichiestaAltroPlesso', false);
      controller.set('showElencoDatiFatturazione', false);
      controller.set('showListDatiFatturazione', false);
      controller.set('isAzienda', false);
      controller.set('isPrivato', false);
      controller.set('isAzienda2', false);
      controller.set('isPrivato2', false);
      //controller.set('showRichiestaDati', false ); //????
      controller.set('nuovaChiave', null);
      controller.set('showServiziStandarDettagli', true);

      controller.set('id_richiestaInCorso', null);

      //recupero le timbrature del plesso
      this.store.unloadAll('timbratura');
      this.findPaged('timbratura', { plesso_id: model.get('id'), per_page: controller.get('perPage') }).then(function (data) {
        controller.set('timbrature', data);
      });

      //recupero le gestioni del plesso
      this.store.unloadAll('gestione');
      this.findPaged('gestione', { plesso_id: model.get('id') }).then(function (data) {
        controller.set('gestioni', data);
      });

      //carico l'elenco dei DATI DI FATTURAZIONE per menù a tendina della selezione
      var _this = this;
      this.store.unloadAll('datifatturazionecliente');
      this.store.find('datifatturazionecliente').then(function (dati) {

        //creo il menù a tendina con le opzioni dei codici di fatturazione
        if (dati.get('length') > 0) {
          var lista = [];
          controller.set('showListDatiFatturazione', true);
          dati.forEach(function (dato) {
            var opzione = { id: dato.get('id'), label_path: dato.get('label_path') };
            lista.push(opzione);
          });
          controller.set('listDatiFatturazione', lista);
        }

        //recupero i dati di fatturazione dal codice di fatturazione 1
        _this.store.unloadAll('datifatturazionecliente');
        if (model.get('codice_fatturazione_1')) {
          controller.set('showRichiediModifica', true);
          controller.set('showDatiFatturazione', true);
          controller.set('showListDatiFatturazione', false); //disabilito il menù a tendinda visto che c'è già un'associazione
          console.log('Dati fatturazione: ' + controller.get('showRichiediModifica'));
          _this.store.findById('datifatturazionecliente', model.get('codice_fatturazione_1')).then(function (data) {
            controller.set('datifatturazionecliente', data);
          });
        }

        //cerco se ci sono delle richieste Dati di Fatturazione in corso
        _this.store.unloadAll('datifatturazioneclienterichiestum');
        _this.store.find('datifatturazioneclienterichiestum', { plesso_id: model.get('id'), codice_fatturazione: model.get('codice_fatturazione_1') }).then(function (dati) {
          if (dati.get('length') > 0) {
            controller.set('showRichiestaInCorso', true);
            controller.set('showRichiediNuovi', false);
            controller.set('showRichiediModifica', false);
            console.log('Richiesta: ' + controller.get('showRichiediModifica'));
            dati.forEach(function (dato) {
              controller.set('id_richiestaInCorso', dato.get('id'));
              //controllo se sono sullo stesso plesso
              if (model.get('id') !== dato.get('richiedibile_id')) {
                controller.set('showMessaggioRichiestaAltroPlesso', true);
                controller.set('codice_plesso_richiesta', dato.get('richiedibile_id'));
                console.log('esiste ' + dato.get('richiedibile_id'));
              }
            });
          }
        });
      });

      //recupero l'elenco delle coperture
      this.store.find('coperturacomune', { provincia: model.get('provincia'), comune: model.get('comune') }).then(function (elenco_servizi) {
        var elenco = [];
        elenco_servizi.forEach(function (servizio) {
          var opzioni = servizio.get('opzioni');
          var opzioniPro = [];
          opzioni.forEach(function (opzione) {
            opzioniPro.push(opzione.giorni + ' : ' + opzione.descrizione_ore + '');
          });
          //Comando per dividere le opzioni della select per istituto
          var option = { text: servizio.get('nome_istituto'), children: [{ id: servizio.get('id'), text: opzioniPro.join(' / ') }] };
          //var option = {id: servizio.get('id'), text: opzioniPro.join(' / ')};
          elenco.push(option);
          controller.set('listaCoperture', elenco);
        });

        if (elenco.length == 1) controller.set('modifica_copertura_comune', elenco[0].children[0].id);
      });

      //recupero l'elenco dei plessi per google maps
      controller.set('plessi', this.store.find('plessicliente'));

      //recupero l'elenco dei servizi
      controller.set('showServizioStandard', false);
      controller.set('showServizioConsumo', false);
      controller.set('descrizione_copertura', null);
      this.store.unloadAll('servizio');
      _this = this;
      this.store.find('servizio', { plesso_id: model.get('id') }).then(function (servizi) {
        servizi.forEach(function (servizio) {
          switch (servizio.get('servibile_type')) {
            case 'VigilanzaStandard':
              //richiedo il servizio standard
              _this.store.findById('vigilanzastandard', servizio.get('servibile_id')).then(function (vigilanzaStandard) {
                controller.set('vigilanzaStandard', vigilanzaStandard);
                controller.set('modelContratto', vigilanzaStandard);
                controller.set('showServizioStandard', true);
                if (vigilanzaStandard.get('serdiufer') == '' && vigilanzaStandard.get('sernotfer') == '' && vigilanzaStandard.get('serdiupre') == '' && vigilanzaStandard.get('sernotpre') == '' && vigilanzaStandard.get('serdiufes') == '' && vigilanzaStandard.get('sernotfes') == '') {
                  controller.set('showServiziStandarDettagli', false);
                }
              });
              break;

            case 'VigilanzaConsumo':
              //richiedo il servizio consumo
              _this.store.findById('vigilanzaconsumo', servizio.get('servibile_id')).then(function (vigilanzaConsumo) {
                controller.set('vigilanzaConsumo', vigilanzaConsumo);
                controller.set('modelContratto', vigilanzaConsumo);
                controller.set('showServizioConsumo', true);
                //controllo se i crediti sono sufficenti
                if (parseInt(_this.get('session.crediti')) >= parseInt(vigilanzaConsumo.get('costo_crediti'))) {
                  controller.set('creditoInsufficente', false);
                } else {
                  controller.set('creditoInsufficente', true);
                }

                //Recupero la copertura selezionata
                _this.store.findById('coperturacomune', vigilanzaConsumo.get('copertura_comune')).then(function (coperturacomune) {
                  //recupero il numero di chiavi necessarie per il servizio
                  controller.set('numero_chiavi', coperturacomune.get('numero_chiavi'));
                  //recupero la descrizione della copertura
                  var opzioni = coperturacomune.get('opzioni');
                  var nome_istituto = coperturacomune.get('nome_istituto');
                  var opzioniPro = [];
                  opzioni.forEach(function (opzione) {
                    opzioniPro.push(opzione.giorni + ' : ' + opzione.descrizione_ore + '');
                  });
                  var indirizzo_istituto = coperturacomune.get('indirizzo_istituto');
                  var comune_istituto = coperturacomune.get('comune_istituto');
                  var cap_istituto = coperturacomune.get('cap_istituto');
                  var provincia_istituto = coperturacomune.get('provincia_istituto');
                  var telefono_istituto = coperturacomune.get('telefono_istituto');

                  // var descrizione = opzioniPro.join(' / ');
                  var descrizione = opzioniPro;
                  controller.set('descrizione_copertura', descrizione);
                  controller.set('nome_istituto_scelto', nome_istituto);
                  controller.set('indirizzo_istituto_scelto', indirizzo_istituto);
                  controller.set('cap_istituto_scelto', cap_istituto);
                  controller.set('comune_istituto_scelto', comune_istituto);
                  controller.set('provincia_istituto_scelto', provincia_istituto);
                  controller.set('telefono_istituto_scelto', telefono_istituto);
                });
              });
              break;
          }
        });
      });
      controller.set('richiestachiavi', false);

      //recupero l'elenco dei recapiti
      this.store.unloadAll('anagraficagruppo');
      this.store.query('anagraficagruppo', { plesso: model.get('id') }).then(function (gruppi) {
        controller.set('anagraficaGruppi', gruppi);
      });

      //controllo se forzare apertura del contratto
      if (controller.get('azione') === 'cellulare') {
        setTimeout(function () {
          _ember['default'].$('#modificaCel').modal('show');
        }, 500);
      }
    },

    actions: {
      ricaricaTutto: function ricaricaTutto() {
        //this.setupController(this.controllerFor("plessicliente/nuovo"),this.currentModel);
        this.setupController(this.controllerFor("plessicliente/dettagli"), this.currentModel);
      }

    }

  });
});
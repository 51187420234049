define('portale-vigilanza/routes/copertura/create', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      return this.store.find('province');
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      console.log("QUESTO é l'altro modello PROVINCE");
      console.log(model);
      /** resetto success */
      controller.set('succss', false);
    }
  });
});
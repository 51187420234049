define('portale-vigilanza/helpers/visualizza-codice', ['exports', 'ember'], function (exports, _ember) {
  exports.visualizzaCodiceFatturazione = visualizzaCodiceFatturazione;

  function visualizzaCodiceFatturazione(input) {
    if (input) {
      if (input.indexOf('fake') > -1) {
        var valore = '';
      } else {
        var valore = input;
      }
    } else {
      var valore = '';
    }

    return new _ember['default'].Handlebars.SafeString(valore);
  }

  exports['default'] = _ember['default'].Handlebars.makeBoundHelper(visualizzaCodiceFatturazione);
});
define('portale-vigilanza/routes/provvigioniold/index', ['exports', 'ember', 'simple-auth/mixins/authenticated-route-mixin', 'portale-vigilanza/mixins/checkMessage/check-message', 'portale-vigilanza/mixins/datifatturazionefornitore/dati-fatturazione', 'ember-cli-pagination/remote/route-mixin'], function (exports, _ember, _simpleAuthMixinsAuthenticatedRouteMixin, _portaleVigilanzaMixinsCheckMessageCheckMessage, _portaleVigilanzaMixinsDatifatturazionefornitoreDatiFatturazione, _emberCliPaginationRemoteRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_simpleAuthMixinsAuthenticatedRouteMixin['default'], _emberCliPaginationRemoteRouteMixin['default'], _portaleVigilanzaMixinsCheckMessageCheckMessage['default'], _portaleVigilanzaMixinsDatifatturazionefornitoreDatiFatturazione['default'], {

    model: function model(params) {
      return this.findPaged('fattura', params);
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('showNuoviDati', false);
      controller.set('showPulsanteNuoviDati', true);
      controller.set('showDatiFatturazione', false);
      //recupero i dati di fatturazione dal codice di fatturazione 1
      if (this.get('session.fatturazione_fornitore')) {
        this.store.findById('datifatturazione', this.get('session.fatturazione_fornitore')).then(function (data) {
          controller.set('showDatiFatturazione', true);
        });
      }

      controller.set('listaProvince', this.store.find('province'));

      //  *** PROVVIGIONI
      //trovo l'anno e il trimestre corrente
      if (controller.get('sel_anno')) {
        var year = controller.get('sel_anno');
      } else {
        var d = new Date();
        var year = d.getFullYear();
      }
      controller.set('anno', { id: year, text: year });

      if (controller.get('sel_trimestre')) {
        var trimestre = controller.get('sel_trimestre');
        var descrizione_trimerestre = trimestre + '° trimestre';
      } else {
        var trimestre = Math.floor(d.getMonth() / 3) + 1;
        var descrizione_trimerestre = trimestre + '° trimestre';
      }
      controller.set('trimestre', { id: trimestre, text: descrizione_trimerestre });

      controller.send('caricaTrimestre');
      controller.send('setSortAnticipi', 'created_at');
      controller.send('setSortStandard', 'created_at');
    }

  });
});
define('portale-vigilanza/models/userdata', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    name: _emberData['default'].attr(),
    description: _emberData['default'].attr(),
    email: _emberData['default'].attr(),
    phone: _emberData['default'].attr(),
    fax: _emberData['default'].attr(),
    /** dati indirizzo */
    idIndirizzo: _emberData['default'].attr(),
    indirizzo: _emberData['default'].attr(),
    cap: _emberData['default'].attr(),
    comune: _emberData['default'].attr(),
    sigla_prov: _emberData['default'].attr()
  });
});
define('portale-vigilanza/helpers/navli-activa', ['exports', 'ember'], function (exports, _ember) {
    exports.navliActiva = navliActiva;

    function navliActiva(nav, navactive) {
        var attiva = '';
        if (nav == navactive) attiva = 'active';

        return new _ember['default'].Handlebars.SafeString(attiva);
    }

    exports['default'] = _ember['default'].Handlebars.makeBoundHelper(navliActiva);
});
define('portale-vigilanza/mixins/datifatturazionecliente/nuovi', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({

    listaProvince: null,
    listComuni: null,
    listaProvinceSpedizione: null,
    listComuniSpedizione: null,

    datifatturazionenuovi: null,

    isPrivato: false,

    init: function init() {
      //recupero l'elenco delle province
      var province = this.store.find('province');
      this.set('listaProvince', province);
      this.set('listaProvinceSpedizione', province);
    },

    //  ** Modifica elenco comuni SEDE **
    loadComuni: (function () {
      console.log('carico comuni');
      console.log(this.get('datifatturazionenuovi.sede_legale_provincia'));
      this.set('datifatturazionenuovi.sede_legale_comune', null);
      this.set('listComuni', this.store.find('comuni', { provincia: this.get('datifatturazionenuovi.sede_legale_provincia'), 'case': 'upper' }));
    }).observes('datifatturazionenuovi.sede_legale_provincia'),

    //  ** Modifica FLAG tipo **
    modificaTipoDati_1: (function () {
      if (this.get('tipo_dati_1') == 'azienda') {
        this.set('isAzienda', true);this.set('isPrivato', false);this.set('datifatturazionenuovi.ragione_sociale', '');this.set('datifatturazionenuovi.nome', '');
      }
      if (this.get('tipo_dati_1') == 'privato') {
        this.set('isPrivato', true);this.set('isAzienda', false);this.set('datifatturazionenuovi.ragione_sociale', '');
      }
    }).observes('tipo_dati_1'),

    //  ** Modifica elenco comuni SPEDIZIONE **
    loadComuniSpedizioni: (function () {
      this.set('datifatturazionenuovi.invio_fattura_comune', null);
      this.set('listComuniSpedizione', this.store.find('comuni', { provincia: this.get('datifatturazionenuovi.invio_fattura_provincia'), 'case': 'upper' }));
    }).observes('datifatturazionenuovi.invio_fattura_provincia'),

    actions: {

      copiaDati: function copiaDati() {
        this.set('datifatturazionenuovi.invio_fattura_ragione_sociale', this.get('datifatturazionenuovi.ragione_sociale') + ' ' + this.get('datifatturazionenuovi.nome'));
        this.set('datifatturazionenuovi.invio_fattura_nome', this.get('datifatturazionenuovi.nome'));
        this.set('datifatturazionenuovi.invio_fattura_provincia', this.get('datifatturazionenuovi.sede_legale_provincia'));
        this.set('datifatturazionenuovi.invio_fattura_indirizzo', this.get('datifatturazionenuovi.sede_legale_indirizzo'));
        this.set('datifatturazionenuovi.invio_fattura_cap', this.get('datifatturazionenuovi.sede_legale_cap'));
        this.set('datifatturazionenuovi.invio_fattura_nazione', this.get('datifatturazionenuovi.sede_legale_nazione'));
        this.set('datifatturazionenuovi.invio_fattura_comune', this.get('datifatturazionenuovi.sede_legale_comune'));
      },

      riapriNuoviDati: function riapriNuoviDati() {
        _ember['default'].$('#myModal').modal('hide');
        this.set('datifatturazionenuovi', this.get('modelError'));
        _ember['default'].$('#modalNuoviDatiFatturazione').modal('show');
      },

      openModalTest: function openModalTest() {
        _ember['default'].$('#modalTestImpianto').modal('show');
      },

      inviaRichiesta: function inviaRichiesta() {

        //configuro il codice del plesso
        this.set('datifatturazionenuovi.codice_plesso', this.get('model.id'));

        //salvo il modello
        var _this = this;
        _ember['default'].$('#modalNuoviDatiFatturazione').modal('hide');
        this.get('datifatturazionenuovi').save().then(function (dati) {
          _this.transitionTo('plessicliente.dettagli', {
            queryParams: {
              id_plesso: _this.get('id_plesso'),
              panel_visualizza: 'servizi'
            }
          });
          _this.send('ricaricaTutto');

          _this.set('datifatturazionecliente', dati);
        }, function (error) {
          _this.set('modelError', _this.get('datifatturazionenuovi'));
          _this.set('errorModal', true);
          _this.set('correggiDatiFatturazione', true);
          _this.set('titoloModal', 'Richiesta dati di fatturazione');
          _this.set('messaggioModal', 'Errore nell\'invio della richiesta');
          _ember['default'].$('#myModal').modal('show');
        });
      }

    }

  });
});
define('portale-vigilanza/routes/home', ['exports', 'ember', 'simple-auth/mixins/authenticated-route-mixin', 'portale-vigilanza/mixins/checkMessage/check-message'], function (exports, _ember, _simpleAuthMixinsAuthenticatedRouteMixin, _portaleVigilanzaMixinsCheckMessageCheckMessage) {
  exports['default'] = _ember['default'].Route.extend(_simpleAuthMixinsAuthenticatedRouteMixin['default'], _portaleVigilanzaMixinsCheckMessageCheckMessage['default'], {

    setupController: function setupController(controller, model) {
      this._super(controller, model);

      if (controller.get('azione')) {

        if (controller.get('azione') === 'agente_statuto') {
          setTimeout(function () {
            _ember['default'].$('#modalAgenteStatuto').modal('show');
          }, 500);
        }
      }
    }

  });
});
define('portale-vigilanza/routes/sedes/edit', ['exports', 'ember', 'portale-vigilanza/mixins/sedes/save-model-mixin'], function (exports, _ember, _portaleVigilanzaMixinsSedesSaveModelMixin) {
    exports['default'] = _ember['default'].Route.extend(_portaleVigilanzaMixinsSedesSaveModelMixin['default'], {
        model: function model(params) {

            return this.store.findRecord('sede', params.sede_id);
        },

        dataFatturazioneType: "Sede",
        setupController: function setupController(controller, model) {
            var route = this;
            this._super(controller, model);
            var a = this.store.find('datifatturazione', { id: model.id, type: this.dataFatturazioneType });
            a.then(function () {
                if (a.get('length') === 1) {
                    controller.set('datifatturazione', a.objectAt(0));
                    //controller.set('datifatturazione_stato', 'edit');
                    console.log('datifatturazione caricati');
                }
            })['catch'](function (error) {
                var datifatturazione = route.store.createRecord('datifatturazione', {
                    'tmpId': model.id,
                    'type': route.dataFatturazioneType
                });
                controller.set('datifatturazione', datifatturazione);
            });

            controller.set('listaProvinceDF', this.store.find('province'));
            //controller.loadComuni();
        },
        events: {
            dattifatturazioneSalvati: function dattifatturazioneSalvati() {
                var route = this;
                route.transitionTo('sedes');
                console.log('datifatturazione intercettato');
            }
        }
    });
});
define('portale-vigilanza/controllers/provvigioniold/index', ['exports', 'ember', 'portale-vigilanza/mixins/datifatturazionefornitore/datifatturazione-controller', 'ember-cli-pagination/remote/route-mixin'], function (exports, _ember, _portaleVigilanzaMixinsDatifatturazionefornitoreDatifatturazioneController, _emberCliPaginationRemoteRouteMixin) {
  exports['default'] = _ember['default'].Controller.extend(_portaleVigilanzaMixinsDatifatturazionefornitoreDatifatturazioneController['default'], _emberCliPaginationRemoteRouteMixin['default'], {

    namePage: 'provvigioni',

    queryParams: ["page", "sort", "order", "sel_anno", "sel_trimestre"],
    sel_anno: null,
    sel_trimestre: null,

    panel_visualizza: 'provvigioni',

    richiedibile_type: 'User',

    anno_inizio: 2015,
    dettaglio_trimestre: '',
    show_precedente: true,
    show_successivo: true,

    sortAnticipi: 'created_at',
    orderAnticipi: 'desc',

    sortStandard: 'created_at',
    orderStandard: 'desc',

    modelAnticipi: null,
    modelAnticipiIsLoad: false,

    modelStandard: null,
    modelStandardIsLoad: false,

    ricaricaPlesso: false,

    arrayTrimestri: [{ id: "1", text: "1° trimestre" }, { id: "2", text: "2° trimestre" }, { id: "3", text: "3° trimestre" }, { id: "4", text: "4° trimestre" }],
    arrayAnni: null,
    trimestre: null,
    anno: null,

    pagamento: null,
    storico: null,
    modelstoricoIsLoad: false,

    init: function init() {
      var annoInizio = this.get('anno_inizio');
      var d = new Date();
      var annoCorrente = d.getFullYear();
      var anni = [];
      while (annoInizio <= annoCorrente) {
        var anno = {
          id: annoInizio,
          text: annoInizio
        };
        anni.push(anno);
        annoInizio++;
      }
      this.set('arrayAnni', anni);
    },

    //  ** Modifica elenco comuni **
    loadComune: (function () {
      this.set('datifatturazionenuovi.sede_legale_comune', null);
      this.set('listComuni', this.store.find('comuni', { provincia: this.get('datifatturazionenuovi.sede_legale_provincia'), 'case': 'upper' }));
    }).observes('datifatturazionenuovi.sede_legale_provincia'),

    //  ** Corregge altezza dei panel **
    resizePanel: (function () {
      if (this.get('modelAnticipiIsLoad') && this.get('modelStandardIsLoad')) {
        var _this = this;
        setTimeout(function () {
          _this.send('impostaAltezzaDiv');
        }, 0);
      }
    }).observes('modelAnticipiIsLoad', 'modelStandardIsLoad'),

    onInit: (function () {
      var _this = this;
      _ember['default'].$(window).resize(function () {
        _this.send('impostaAltezzaDiv');
      });
    }).on('init'),

    actions: {

      impostaAltezzaDiv: function impostaAltezzaDiv() {

        _ember['default'].$('#header_anticipi').removeAttr("style");
        _ember['default'].$('#header_effettivi').removeAttr("style");
        _ember['default'].$('#panel_anticipi').removeAttr("style");
        _ember['default'].$('#panel_effettivi').removeAttr("style");

        if (_ember['default'].$(window).width() > 991) {

          var header_anticipi = _ember['default'].$('#header_anticipi').height();
          var header_effettivi = _ember['default'].$('#header_effettivi').height();
          if (header_anticipi > header_effettivi) {
            _ember['default'].$('#header_effettivi').height(header_anticipi);
            _ember['default'].$('#header_anticipi').height(header_anticipi);
          } else {
            _ember['default'].$('#header_anticipi').height(header_effettivi);
            _ember['default'].$('#header_effettivi').height(header_effettivi);
          }

          var panel_anticipi = _ember['default'].$('#panel_anticipi').height();
          var panel_effettivi = _ember['default'].$('#panel_effettivi').height();
          if (panel_anticipi > panel_effettivi) {
            _ember['default'].$('#panel_effettivi').height(panel_anticipi);
            _ember['default'].$('#panel_anticipi').height(panel_anticipi);
          } else {
            _ember['default'].$('#panel_anticipi').height(panel_effettivi);
            _ember['default'].$('#panel_effettivi').height(panel_effettivi);
          }
        }
      },

      visualizzaNuoviDatiFatturazione: function visualizzaNuoviDatiFatturazione() {
        this.set('panel_visualizza', 'dati_fatturazione');
        window.scrollTo(0, 0);
        this.send('showFormNuovi', 'nuovi');
      },

      pulsanteCambiaTrimestre: function pulsanteCambiaTrimestre(azione) {
        var anno = this.get('anno.id');
        var trimestre = this.get('trimestre.id');
        if (azione == 'precedente') {
          if (trimestre == 1) {
            anno--;
            trimestre = 4;
          } else trimestre--;
        }
        if (azione == 'successivo') {
          if (trimestre == 4) {
            anno++;
            trimestre = 1;
          } else trimestre++;
        }
        var descrizione_trimestre = trimestre + '° trimestre';
        this.set('anno', { id: anno, text: anno });
        this.set('trimestre', { id: trimestre, text: descrizione_trimestre });
        this.send('cambiaData');
      },

      cambiaData: function cambiaData() {
        this.set('sortAnticipi', 'created_at');
        this.set('orderAnticipi', 'desc');
        this.set('sortStandard', 'created_at');
        this.set('orderStandard', 'desc');
        this.send('setSortAnticipi', 'created_at');
        this.send('setSortStandard', 'created_at');
        this.send('caricaTrimestre');
      },

      cambiaData_Storico: function cambiaData_Storico(trimestre, anno) {
        this.set('anno', { id: anno, text: anno });
        var descrizione_trimerestre = trimestre + '° trimestre';
        this.set('trimestre', { id: trimestre, text: descrizione_trimerestre });
        this.send('cambiaData');
      },

      //  ** Ordinamento dell'elenco ANTICIPI **
      setSortAnticipi: function setSortAnticipi(fieldName) {
        var old_sort = this.get('sortAnticipi');
        this.set('sortAnticipi', fieldName);
        if (fieldName !== old_sort) {
          this.set('orderAnticipi', 'asc');
        } else {
          if (this.get('orderAnticipi') === 'asc') {
            this.set('orderAnticipi', 'desc');
          } else {
            this.set('orderAnticipi', 'asc');
          }
        }
        this.set('modelAnticipiIsLoad', false);
        var _this = this;
        this.findPaged('provvigioni', { anno: this.get('anno.id'), trimestre: this.get('trimestre.id'), type: 'anticipo', sort: this.get('sortAnticipi'), order: this.get('orderAnticipi') }).then(function (data) {
          _this.set('modelAnticipi', data);
          _this.set('modelAnticipiIsLoad', true);
        });
      },

      //  ** Ordinamento dell'elenco STANDARD **
      setSortStandard: function setSortStandard(fieldName) {
        var old_sort = this.get('sortStandard');
        this.set('sortStandard', fieldName);
        if (fieldName !== old_sort) {
          this.set('orderStandard', 'asc');
        } else {
          if (this.get('orderStandard') === 'asc') {
            this.set('orderStandard', 'desc');
          } else {
            this.set('orderStandard', 'asc');
          }
        }
        this.set('modelStandardIsLoad', false);
        var _this = this;
        this.findPaged('provvigioni', { anno: this.get('anno.id'), trimestre: this.get('trimestre.id'), type: 'standard', sort: this.get('sortStandard'), order: this.get('orderStandard') }).then(function (data) {
          _this.set('modelStandard', data);
          _this.set('modelStandardIsLoad', true);
        });
      },

      //  FUNZIONE CARICAMENTO STATO TRIMESTRE
      caricaTrimestre: function caricaTrimestre() {

        _ember['default'].$('#modalProvvigioniRichiesto').modal('hide');
        this.set('ricaricaPlesso', false);

        var _this = this;
        this.store.find('pagamento', { anno: this.get('anno.id'), trimestre: this.get('trimestre.id') }).then(function (pagamenti) {
          pagamenti.forEach(function (pagamento) {

            var visualizza_stato;
            if (pagamento.get('stato') == 'pronto') {
              if (pagamento.get('richiedibile')) {
                visualizza_stato = 'richiedibile';
              } else {
                visualizza_stato = 'chiuso';
              }
            } else {
              if (pagamento.get('stato') == 'calcolato') {

                var d = new Date();
                var anno_corrente = d.getFullYear();
                var trimestre_corrente = Math.floor(d.getMonth() / 3) + 1;

                if (pagamento.get('anno') == anno_corrente && pagamento.get('trimestre') >= trimestre_corrente) visualizza_stato = 'incorso';else {
                  if (pagamento.get('anno') > anno_corrente) {
                    visualizza_stato = 'incorso';
                  } else {
                    visualizza_stato = 'chiuso';
                  }
                }
              } else visualizza_stato = pagamento.get('stato');
            }

            _this.set('pagamento', pagamento);
            _this.set('pagamento.visualizza_stato', visualizza_stato);
          });

          //controllo se devo caricare lo storico
          if (!_this.get('modelStoricoIsLoad')) {
            _this.findPaged('pagamento', {}).then(function (data) {
              _this.set('storico', data);
              _this.set('modelStoricoIsLoad', true);
            });
          }
        });

        this.set('dettaglio_trimestre', this.get('trimestre.text') + ' ' + this.get('anno.text'));

        //controllo per i pulsanti precedente/successivo
        this.set('show_precedente', true);
        this.set('show_successivo', true);
        if (this.get('anno.id') == this.get('anno_inizio') && this.get('trimestre.id') == '1') this.set('show_precedente', false);
        var d = new Date();
        if (this.get('anno.id') == d.getFullYear() && this.get('trimestre.id') == '4') this.set('show_successivo', false);
      },

      //  FUNZIONE RICHIEDI PAGAMENTO DEL TRIMESTRE
      richiediPagamento: function richiediPagamento() {

        var _this = this;

        //imposto il codice di fatturazione
        this.set('pagamento.codice_fatturazione', this.get('datifatturazionefornitore.id'));

        this.get('pagamento').save().then(function () {
          _this.set('ricaricaPlesso', true);
          _ember['default'].$('#modalProvvigioniRichiesto').modal('show');
        }, function (error) {
          _this.set('modelError', _this.get('pagamento'));
          _this.set('errorModal', true);
          _this.set('titoloModal', 'Richiestra Provvigioni Trimestre');
          _this.set('messaggioModal', 'Errore nella richiesta delle provvigioni');
          _ember['default'].$('#myModal').modal('show');
        });
      },

      //  FUNZIONE APRI MODAL INFON
      apriInfo: function apriInfo() {
        _ember['default'].$('#modalProvvigioniRichiesto').modal('show');
      }

    }
  });
});
define("portale-vigilanza/templates/veicoli", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.11",
            "loc": {
              "source": null,
              "start": {
                "line": 26,
                "column": 22
              },
              "end": {
                "line": 32,
                "column": 22
              }
            },
            "moduleName": "portale-vigilanza/templates/veicoli.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "pull-left");
            var el2 = dom.createTextNode("\n                          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("button");
            dom.setAttribute(el2, "class", "btn btn-sm btn-danger");
            dom.setAttribute(el2, "type", "button");
            var el3 = dom.createTextNode("\n                              Eventi da confermare: ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "class", "badge");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1, 1]), 0, 0);
            return morphs;
          },
          statements: [["content", "numberConfirm", ["loc", [null, [29, 72], [29, 89]]]]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.11",
              "loc": {
                "source": null,
                "start": {
                  "line": 106,
                  "column": 48
                },
                "end": {
                  "line": 108,
                  "column": 48
                }
              },
              "moduleName": "portale-vigilanza/templates/veicoli.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                                                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("br");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 3, 3, contextualElement);
              return morphs;
            },
            statements: [["content", "account.eventoIndirizzo", ["loc", [null, [107, 59], [107, 86]]]]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.11",
              "loc": {
                "source": null,
                "start": {
                  "line": 113,
                  "column": 42
                },
                "end": {
                  "line": 119,
                  "column": 42
                }
              },
              "moduleName": "portale-vigilanza/templates/veicoli.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                                              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1, "class", "pull-left");
              var el2 = dom.createTextNode("\n                                                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              dom.setAttribute(el2, "class", "btn btn-sm btn-danger");
              dom.setAttribute(el2, "title", "Conferma stato");
              var el3 = dom.createTextNode("\n                                                      ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("i");
              dom.setAttribute(el3, "class", "fa fa-check");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode(" Conferma\n                                                  ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element2 = dom.childAt(fragment, [1, 1]);
              var morphs = new Array(1);
              morphs[0] = dom.createElementMorph(element2);
              return morphs;
            },
            statements: [["element", "action", ["send_confirm", ["get", "account", ["loc", [null, [115, 108], [115, 115]]]]], [], ["loc", [null, [115, 84], [115, 117]]]]],
            locals: [],
            templates: []
          };
        })();
        var child2 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.11",
              "loc": {
                "source": null,
                "start": {
                  "line": 123,
                  "column": 42
                },
                "end": {
                  "line": 130,
                  "column": 42
                }
              },
              "moduleName": "portale-vigilanza/templates/veicoli.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                                              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createTextNode("\n                                                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              dom.setAttribute(el2, "class", "pull-left");
              var el3 = dom.createElement("i");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              dom.setAttribute(el2, "class", "pull-right");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode(" ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("br");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              dom.setAttribute(el2, "class", "pull-left");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              dom.setAttribute(el2, "class", "pull-right");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var element1 = dom.childAt(element0, [1, 0]);
              var morphs = new Array(5);
              morphs[0] = dom.createAttrMorph(element0, 'class');
              morphs[1] = dom.createAttrMorph(element1, 'class');
              morphs[2] = dom.createMorphAt(dom.childAt(element0, [3]), 0, 0);
              morphs[3] = dom.createMorphAt(dom.childAt(element0, [6]), 0, 0);
              morphs[4] = dom.createMorphAt(dom.childAt(element0, [8]), 0, 0);
              return morphs;
            },
            statements: [["attribute", "class", ["concat", ["btn btn-allarm ", ["get", "account.eventoStatoColore", ["loc", [null, [124, 75], [124, 100]]]]]]], ["attribute", "class", ["concat", ["fa ", ["get", "account.eventoStatoIcona", ["loc", [null, [125, 89], [125, 113]]]], " fa-fw fa-lg pull-left"]]], ["content", "account.eventoStatoDettaglio", ["loc", [null, [126, 75], [126, 107]]]], ["content", "account.eventoStatoOra", ["loc", [null, [127, 74], [127, 100]]]], ["content", "account.eventoEta", ["loc", [null, [128, 75], [128, 96]]]]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.11",
            "loc": {
              "source": null,
              "start": {
                "line": 87,
                "column": 32
              },
              "end": {
                "line": 135,
                "column": 32
              }
            },
            "moduleName": "portale-vigilanza/templates/veicoli.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n                                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("tr");
            dom.setAttribute(el1, "class", "row-links");
            var el2 = dom.createTextNode("\n\n                                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createTextNode("\n                                          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                                        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n                                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createTextNode("\n                                          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                                        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n                                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createTextNode("\n                                          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                                        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n                                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createTextNode("\n                                              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "class", "pull-left");
            var el4 = dom.createTextNode("\n                                                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("                                              ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                                        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n                                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("                                        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n                                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("                                        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n                                    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element3 = dom.childAt(fragment, [1]);
            var element4 = dom.childAt(element3, [7, 1]);
            var morphs = new Array(7);
            morphs[0] = dom.createMorphAt(dom.childAt(element3, [1]), 1, 1);
            morphs[1] = dom.createMorphAt(dom.childAt(element3, [3]), 1, 1);
            morphs[2] = dom.createMorphAt(dom.childAt(element3, [5]), 1, 1);
            morphs[3] = dom.createMorphAt(element4, 1, 1);
            morphs[4] = dom.createMorphAt(element4, 3, 3);
            morphs[5] = dom.createMorphAt(dom.childAt(element3, [9]), 1, 1);
            morphs[6] = dom.createMorphAt(dom.childAt(element3, [11]), 1, 1);
            return morphs;
          },
          statements: [["content", "account.username", ["loc", [null, [92, 42], [92, 62]]]], ["content", "account.veicolo", ["loc", [null, [96, 42], [96, 61]]]], ["content", "account.eventoOra", ["loc", [null, [100, 42], [100, 63]]]], ["content", "account.eventoCliente", ["loc", [null, [105, 50], [105, 75]]]], ["block", "if", [["get", "account.eventoIndirizzo", ["loc", [null, [106, 54], [106, 77]]]]], [], 0, null, ["loc", [null, [106, 48], [108, 55]]]], ["block", "if", [["get", "account.mostraConferma", ["loc", [null, [113, 48], [113, 70]]]]], [], 1, null, ["loc", [null, [113, 42], [119, 49]]]], ["block", "if", [["get", "account.eventoStato", ["loc", [null, [123, 48], [123, 67]]]]], [], 2, null, ["loc", [null, [123, 42], [130, 49]]]]],
          locals: ["account"],
          templates: [child0, child1, child2]
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.11",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 0
            },
            "end": {
              "line": 148,
              "column": 0
            }
          },
          "moduleName": "portale-vigilanza/templates/veicoli.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "id", "section");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "container");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "row");
          var el4 = dom.createTextNode("\n\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("form");
          dom.setAttribute(el4, "class", "form-horizontal");
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5, "class", "col-md-12");
          var el6 = dom.createTextNode("\n                        ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("h1");
          dom.setAttribute(el6, "align", "center");
          var el7 = dom.createTextNode("MONITORAGGIO VEICOLI");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                    ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "row");
          dom.setAttribute(el4, "style", "margin-bottom: 20px;");
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5, "class", "col-md-12");
          var el6 = dom.createTextNode("\n\n                      ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("span");
          dom.setAttribute(el6, "class", "pull-left");
          var el7 = dom.createTextNode("\n                          ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("button");
          dom.setAttribute(el7, "class", "btn btn-sm btn-primary");
          dom.setAttribute(el7, "type", "button");
          var el8 = dom.createTextNode("\n                              Totale eventi aperti: ");
          dom.appendChild(el7, el8);
          var el8 = dom.createElement("span");
          dom.setAttribute(el8, "class", "badge");
          var el9 = dom.createComment("");
          dom.appendChild(el8, el9);
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("\n                          ");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                      ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n\n");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                        ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("span");
          dom.setAttribute(el6, "class", "pull-right");
          var el7 = dom.createTextNode("\n                          ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("a");
          dom.setAttribute(el7, "class", "btn btn-sm btn-danger");
          dom.setAttribute(el7, "title", "Aggiorna");
          var el8 = dom.createTextNode("\n                              ");
          dom.appendChild(el7, el8);
          var el8 = dom.createElement("i");
          dom.setAttribute(el8, "class", "fa fa-refresh");
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("\n                          ");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                      ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n\n                    ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "row");
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5, "class", "col-md-12");
          var el6 = dom.createTextNode("\n                        ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6, "class", "table-responsive");
          var el7 = dom.createTextNode("\n                            ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("table");
          dom.setAttribute(el7, "class", "table table-striped table-bordered table-condensed");
          dom.setAttribute(el7, "id", "tabella_eventi_aperti");
          var el8 = dom.createTextNode("\n\n                                ");
          dom.appendChild(el7, el8);
          var el8 = dom.createElement("thead");
          var el9 = dom.createTextNode("\n                                ");
          dom.appendChild(el8, el9);
          var el9 = dom.createElement("tr");
          var el10 = dom.createTextNode("\n\n\n                                    ");
          dom.appendChild(el9, el10);
          var el10 = dom.createElement("th");
          dom.setAttribute(el10, "class", "col-sm-1");
          var el11 = dom.createTextNode("\n                                        Utente\n                                    ");
          dom.appendChild(el10, el11);
          dom.appendChild(el9, el10);
          var el10 = dom.createTextNode("\n\n\n                                    ");
          dom.appendChild(el9, el10);
          var el10 = dom.createElement("th");
          dom.setAttribute(el10, "class", "col-sm-1");
          var el11 = dom.createTextNode("\n                                        Dispositivo\n                                    ");
          dom.appendChild(el10, el11);
          dom.appendChild(el9, el10);
          var el10 = dom.createTextNode("\n\n                                    ");
          dom.appendChild(el9, el10);
          var el10 = dom.createComment("\n                                      <th class=\"col-sm-1\" {{action \"setSortBy\"}}>\n                                          Evento\n                                          <span class=\"pull-right\">\n                                            {{sort-icon 'eventoOra' sortProperties sortAscending}}\n                                          </span>\n                                      </th>\n                                      ");
          dom.appendChild(el9, el10);
          var el10 = dom.createTextNode("\n                                    ");
          dom.appendChild(el9, el10);
          var el10 = dom.createElement("th");
          dom.setAttribute(el10, "class", "col-sm-1");
          var el11 = dom.createTextNode("\n                                        Evento\n                                    ");
          dom.appendChild(el10, el11);
          dom.appendChild(el9, el10);
          var el10 = dom.createTextNode("\n\n                                    ");
          dom.appendChild(el9, el10);
          var el10 = dom.createElement("th");
          dom.setAttribute(el10, "class", "col-sm-5");
          var el11 = dom.createTextNode("\n                                        Cliente\n                                    ");
          dom.appendChild(el10, el11);
          dom.appendChild(el9, el10);
          var el10 = dom.createTextNode("\n\n                                    ");
          dom.appendChild(el9, el10);
          var el10 = dom.createElement("th");
          dom.setAttribute(el10, "class", "col-sm-1");
          dom.appendChild(el9, el10);
          var el10 = dom.createTextNode("\n\n\n                                    ");
          dom.appendChild(el9, el10);
          var el10 = dom.createElement("th");
          dom.setAttribute(el10, "class", "col-sm-2");
          dom.appendChild(el9, el10);
          var el10 = dom.createTextNode("\n\n                                ");
          dom.appendChild(el9, el10);
          dom.appendChild(el8, el9);
          var el9 = dom.createTextNode("\n                                ");
          dom.appendChild(el8, el9);
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("\n\n                                ");
          dom.appendChild(el7, el8);
          var el8 = dom.createElement("tbody");
          var el9 = dom.createTextNode("\n\n");
          dom.appendChild(el8, el9);
          var el9 = dom.createComment("");
          dom.appendChild(el8, el9);
          var el9 = dom.createTextNode("\n                                ");
          dom.appendChild(el8, el9);
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("\n\n                            ");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                        ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                    ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n\n            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element5 = dom.childAt(fragment, [1, 1, 1]);
          var element6 = dom.childAt(element5, [3, 1]);
          var element7 = dom.childAt(element6, [5, 1]);
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(dom.childAt(element6, [1, 1, 1]), 0, 0);
          morphs[1] = dom.createMorphAt(element6, 3, 3);
          morphs[2] = dom.createElementMorph(element7);
          morphs[3] = dom.createMorphAt(dom.childAt(element5, [5, 1, 1, 1, 3]), 1, 1);
          return morphs;
        },
        statements: [["content", "numberAllarm", ["loc", [null, [22, 72], [22, 88]]]], ["block", "if", [["get", "numberConfirm", ["loc", [null, [26, 28], [26, 41]]]]], [], 0, null, ["loc", [null, [26, 22], [32, 29]]]], ["element", "action", ["refresh"], [], ["loc", [null, [35, 60], [35, 80]]]], ["block", "each", [["get", "sortedList", ["loc", [null, [87, 51], [87, 61]]]]], [], 1, null, ["loc", [null, [87, 32], [135, 41]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.11",
          "loc": {
            "source": null,
            "start": {
              "line": 148,
              "column": 0
            },
            "end": {
              "line": 150,
              "column": 0
            }
          },
          "moduleName": "portale-vigilanza/templates/veicoli.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "partial", ["partial/errorepermessi"], [], ["loc", [null, [149, 2], [149, 38]]]]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@1.13.11",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 151,
            "column": 6
          }
        },
        "moduleName": "portale-vigilanza/templates/veicoli.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "id", "main");
        dom.setAttribute(el1, "role", "main");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[2] = dom.createMorphAt(dom.childAt(fragment, [4]), 1, 1);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "partial", ["partial/header"], [], ["loc", [null, [1, 0], [1, 28]]]], ["inline", "partial", ["partial/menu"], [], ["loc", [null, [2, 0], [2, 26]]]], ["block", "if", [["get", "session.Vedi_MonitoraggioVeicoli", ["loc", [null, [5, 6], [5, 38]]]]], [], 0, 1, ["loc", [null, [5, 0], [150, 7]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});
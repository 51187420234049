define('portale-vigilanza/models/documentoutente', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({

    user_id: _emberData['default'].attr('string'),
    tipo: _emberData['default'].attr('string'),
    numero: _emberData['default'].attr('string'),
    autorita: _emberData['default'].attr('string'),
    data_emissione: _emberData['default'].attr('string'),

    // Formatto la data
    data_emissione_format: (function () {
      if (this.get('data_emissione') == '0000-00-00') {
        return '';
      } else {
        if (moment(this.get('data_emissione'), 'YYYY-MM-DDTHH:mm').format("HH:mm") === '00:00') var date = moment(this.get('data_emissione'), 'YYYY-MM-DDTHH:mm').format("DD/MM/YYYY");else var date = moment(this.get('data_emissione'), 'YYYY-MM-DDTHH:mm').format("DD/MM/YYYY");
        return date;
      }
    }).property('data_emissione')

  });
});
define("portale-vigilanza/templates/partial/plessi/-modalcontrattokit", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.11",
          "loc": {
            "source": null,
            "start": {
              "line": 128,
              "column": 16
            },
            "end": {
              "line": 130,
              "column": 16
            }
          },
          "moduleName": "portale-vigilanza/templates/partial/plessi/-modalcontrattokit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          dom.setAttribute(el1, "type", "button");
          dom.setAttribute(el1, "class", "btn btn-default");
          dom.setAttribute(el1, "data-dismiss", "modal");
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("i");
          dom.setAttribute(el2, "class", "fa");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" Chiudi");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@1.13.11",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 137,
            "column": 0
          }
        },
        "moduleName": "portale-vigilanza/templates/partial/plessi/-modalcontrattokit.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment(" Modal Contratto ");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "modal fade modal-scrollable");
        dom.setAttribute(el1, "id", "modalContrattokit");
        dom.setAttribute(el1, "tabindex", "-1");
        dom.setAttribute(el1, "role", "dialog");
        dom.setAttribute(el1, "aria-labelledby", "myModalLabel");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "modal-dialog modal-lg");
        dom.setAttribute(el2, "role", "document");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "modal-content");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "modal-header");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("button");
        dom.setAttribute(el5, "type", "button");
        dom.setAttribute(el5, "class", "close");
        dom.setAttribute(el5, "data-dismiss", "modal");
        dom.setAttribute(el5, "aria-label", "Close");
        var el6 = dom.createElement("span");
        dom.setAttribute(el6, "aria-hidden", "true");
        var el7 = dom.createTextNode("×");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h4");
        dom.setAttribute(el5, "class", "modal-title");
        dom.setAttribute(el5, "id", "exampleModalLabel");
        var el6 = dom.createElement("b");
        var el7 = dom.createTextNode("CONDIZIONI DI VENDITA KIT E ACCESSORI");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "modal-body");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("p");
        var el6 = dom.createElement("b");
        var el7 = dom.createTextNode("Accettazione delle condizioni generali di vendita");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("p");
        var el6 = dom.createTextNode("\n                    Il contratto stipulato tra Vigilo 4 You S.r.l. e il Cliente deve intendersi concluso con l'accettazione dell'ordine da parte di Vigilo 4 You S.r.l.. Tale accettazione si ritiene tacita, se non altrimenti comunicato con qualsiasi modalità al Cliente. Effettuando un ordine il Cliente dichiara di aver preso visione di tutte le indicazioni a lui fornite durante la procedura d'acquisto.\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("p");
        var el6 = dom.createTextNode("\n                    Se il Cliente è un consumatore (ossia una persona fisica che acquista la merce per scopi non riferibili alla propria attività professionale), una volta conclusa la procedura d'acquisto online, provvederà a stampare o salvare copia elettronica e comunque conservare le presenti condizioni generali di vendita, nel rispetto di quanto previsto dagli artt. 50 e ss del D.Lgs. 206/05.\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("p");
        var el6 = dom.createTextNode("\n                    Viene escluso ogni diritto del Cliente a un risarcimento danni o indennizzo, nonché qualsiasi responsabilità contrattuale o extracontrattuale per danni diretti o indiretti a persone e/o cose, provocati dalla mancata accettazione, anche parziale, di un ordine.\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("p");
        var el6 = dom.createElement("b");
        var el7 = dom.createTextNode("Modalità e spese di consegna");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("p");
        var el6 = dom.createTextNode("Vigilo 4 You S.r.l. può accettare ordini solo con consegna in territorio italiano.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("p");
        var el6 = dom.createTextNode("\n                    Per ogni ordine effettuato sul portale, Vigilo 4 You S.r.l. emette fattura fiscale del materiale spedito, rendendola disponibile su portale nell’area riservata del cliente, ai sensi dell'art 14 D.P.R. 445/2000 e DL 52/2004. Per l'emissione del documento di vendita, fanno fede le informazioni fornite dal Cliente all'atto dell'ordine. Nessuna variazione del documento di acquisto sarà possibile, dopo l'emissione dello stesso.\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("p");
        var el6 = dom.createTextNode("\n                    Nessuna responsabilità può essere imputata a Vigilo 4 You S.r.l. in caso di ritardo nell'evasione dell'ordine o nella consegna di quanto ordinato.\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("p");
        var el6 = dom.createTextNode("La consegna di quanto ordinato si intende al piano strada");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("p");
        var el6 = dom.createTextNode("Al momento della consegna della merce da parte del corriere, il Cliente è tenuto a controllare:");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("ol");
        dom.setAttribute(el5, "type", "a");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createTextNode("che il numero dei colli in consegna corrisponda a quanto indicato nel documento di trasporto ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createTextNode("che l'imballo risulti integro, non danneggiato, né bagnato o comunque alterato, anche nei materiali di chiusura (nastro adesivo o reggette metalliche).");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createTextNode("Eventuali danni all'imballo e/o al prodotto o la mancata corrispondenza del numero dei colli o delle indicazioni, devono essere immediatamente contestati, apponendo RISERVA DI CONTROLLO SCRITTA (SPECIFICANDO IL MOTIVO DELLA RISERVA, es. \"imballo bucato\", \"imballo schiacciato\", ecc.) sulla prova di consegna del corriere. Una volta firmato il documento del corriere, il Cliente non potrà opporre alcuna contestazione circa le caratteristiche esteriori di quanto consegnato.");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("p");
        var el6 = dom.createTextNode("\n                    Eventuali problemi inerenti l'integrità fisica, la corrispondenza o la completezza dei prodotti ricevuti devono essere segnalati entro 7 giorni dalla avvenuta consegna, secondo le modalità previste nel presente documento.\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("p");
        var el6 = dom.createTextNode("\n                    Nel caso di mancato ritiro entro 5 giorni lavorativi del materiale presente in giacenza presso i magazzini del corriere a causa di reiterata impossibilità di consegna al recapito indicato dal Cliente all'atto dell'ordine, quanto ordinato verrà fatto rientrare presso i magazzini di Vigilo 4 You S.r.l..\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("p");
        var el6 = dom.createElement("b");
        var el7 = dom.createTextNode("Diritto di recesso");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("p");
        var el6 = dom.createTextNode("\n                    Ai sensi degli artt. 64 e ss del D.Lgs. 206/05., se il cliente è un consumatore (ossia una persona fisica che acquista la merce per scopi non riferibili alla propria attività professionale, ovvero non effettua l'acquisto con un riferimento di Partita IVA), ha diritto a recedere dal contratto di acquisto per qualsiasi motivo, senza necessità di fornire spiegazioni e senza alcuna penalità.\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("p");
        var el6 = dom.createTextNode("\n                    Per esercitare tale diritto, il cliente dovrà inviare a Vigilo 4 You S.r.l. una comunicazione in tal senso, entro 10 giorni lavorativi dalla data di ricevimento della merce. Tale comunicazione dovrà essere inviata a mezzo lettera raccomandata con avviso di ricevimento, indirizzata a Vigilo 4 You S.r.l. Via Fura 18 – 25125 Brescia ovvero tramite fax o pec all’indirizzo vigiloforyou@legalmail.it inviati sempre entro il suddetto termine di 10 giorni e seguiti da una conferma. Una volta pervenutaci la suddetta comunicazione di recesso, il Servizio Clienti Vigilo 4 You S.r.l. provvederà rapidamente a comunicare al cliente le istruzioni sulla modalità di restituzione per il reso della merce, che dovrà pervenire a Vigilo 4 You S.r.l. entro 10 giorni dall'autorizzazione. La merce dovrà essere restituita integra, completa di tutte le sue parti e negli imballi originali (buste e confezioni), custodita ed eventualmente adoperata secondo la normale diligenza, senza che vi siano segni di usura o sporcizia.\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("p");
        var el6 = dom.createTextNode("Il diritto di recesso è comunque sottoposto alle seguenti condizioni:");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("ol");
        dom.setAttribute(el5, "type", "a");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createTextNode("\n                        il diritto si applica al prodotto acquistato nella sua interezza; non è possibile esercitare recesso solamente su parte del prodotto acquistato\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createTextNode("\n                        il bene acquistato dovrà essere integro e restituito nella confezione originale, completa in tutte le sue parti (compresi imballo ed eventuale documentazione e dotazione accessoria: manuali, cavi, ecc...); per limitare danneggiamenti alla confezione originale, raccomandiamo di inserirla in una seconda scatola; va evitata in tutti i casi l'apposizione di etichette o nastri adesivi direttamente sulla confezione originale del prodotto;\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createTextNode("\n                        a norma di legge, le spese di spedizione relative alla restituzione del bene sono a carico del cliente;\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createTextNode("\n                        la spedizione, fino all'attestato di avvenuto ricevimento nel nostro magazzino, è sotto la completa responsabilità del cliente;\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createTextNode("\n                        in caso di danneggiamento del bene durante il trasporto, Vigilo 4 You S.r.l. darà comunicazione al cliente dell'accaduto (entro 5 giorni lavorativi dal ricevimento del bene nei propri magazzini), per consentirgli di sporgere tempestivamente denuncia nei confronti del corriere da lui scelto e ottenere il rimborso del valore del bene (se assicurato); in questa eventualità, il prodotto sarà messo a disposizione del cliente per la sua restituzione, contemporaneamente annullando la richiesta di recesso;\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createTextNode("\n                        Vigilo 4 You S.r.l. non risponde in nessun modo per danneggiamenti o furto/smarrimento di beni restituiti con spedizioni non assicurate;\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createTextNode("\n                        al suo arrivo in magazzino, il prodotto sarà esaminato per valutare eventuali danni o manomissioni non derivanti dal trasporto.\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("p");
        var el6 = dom.createTextNode("\n                    Vigilo 4 You S.r.l. provvederà a rimborsare al cliente l’importo già pagato per l’acquisto della merce, entro 14 giorni dal rientro della merce, a mezzo Bonifico Bancario. Sarà cura del cliente fornire tempestivamente le coordinate bancarie sulle quali ottenere il rimborso (codice IBAN e intestatario del conto corrente).\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("p");
        var el6 = dom.createTextNode("\n                    Il diritto di recesso decade totalmente, per mancanza della condizione essenziale di integrità del bene (confezione e/o suo contenuto), nei casi in cui Vigilo 4 You S.r.l. accerti:\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("ol");
        dom.setAttribute(el5, "type", "a");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createTextNode("\n                        l'utilizzo non diligente del bene che ne abbia compromesso l'integrità, o l'utilizzo di eventuali materiali di consumo;\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createTextNode("\n                        la mancanza della confezione esterna e/o dell'imballo interno originale;\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createTextNode("\n                        l'assenza di elementi integranti del prodotto (accessori, cavi, manuali, parti, ...);\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createTextNode("\n                        il danneggiamento del prodotto per cause diverse dal suo trasporto.\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("p");
        var el6 = dom.createElement("b");
        var el7 = dom.createTextNode("Garanzie");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("p");
        var el6 = dom.createTextNode("\n                    Tutti i prodotti venduti da Vigilo 4 You S.r.l. sono coperti dalla garanzia convenzionale del produttore minima di 12 mesi e dalla garanzia di 24 mesi (solo consumatore privato) per i difetti di conformità, ai sensi del Dlgs 206/05. Per fruire dell'assistenza in garanzia, il Cliente dovrà conservare il documento di acquisto (fattura) che potrà scaricare dall’area riservata del cliente sul portale.\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("p");
        var el6 = dom.createTextNode("\n                    La garanzia di 24 mesi ai sensi del Dlgs 206/05 si applica al prodotto che presenti un difetto di conformità, purché il prodotto stesso sia utilizzato correttamente, nel rispetto della sua destinazione d'uso e di quanto previsto nella documentazione tecnica. Tale garanzia è riservata al Consumatore privato (persona fisica che acquista la merce per scopi non riferibili alla propria attività professionale, ovvero effettua l'acquisto con un riferimento di Partita IVA). In caso di difetto di conformità, Vigilo 4 You S.r.l. provvede, senza spese per il Cliente, al ripristino della conformità del prodotto mediante riparazione/sostituzione.\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("p");
        var el6 = dom.createTextNode("\n                    Nel caso in cui, per qualsiasi ragione, non fosse in grado di rendere al proprio cliente un prodotto in garanzia (ripristinato o sostituito), Vigilo 4 You S.r.l. potrà procedere a propria discrezione alla restituzione dell'importo pagato tenendo conto dell'uso del bene oppure alla sua sostituzione con un prodotto di caratteristiche pari o superiori.\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("p");
        var el6 = dom.createTextNode("\n                    I tempi di riparazione o eventuale sostituzione del prodotto dipendono esclusivamente dalle politiche del produttore e nessun danno può essere richiesto a Vigilo 4 You S.r.l. per eventuali ritardi nell'effettuazione di riparazioni o sostituzioni.\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("p");
        var el6 = dom.createElement("b");
        var el7 = dom.createTextNode("Privacy");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("p");
        var el6 = dom.createTextNode("\n                    I dati personali richiesti in fase di inoltro dell'ordine sono raccolti e trattati al fine di soddisfare le espresse richieste del Cliente. Vigilo 4 You S.r.l. garantisce ai propri clienti il rispetto della normativa in materia di trattamento dei dati personali, disciplinata dal codice sulla privacy Europeo 2016/679. Informativa completa sul sito nella sezione Privacy.\n                    Titolare del trattamento dati è Rosa Frassine, in qualità di rappresentante pro tempore di Vigilanza Group, Wolf Srl, Vigilo 4 You S.r.l.\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("p");
        var el6 = dom.createElement("b");
        var el7 = dom.createTextNode("Reclami");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("p");
        var el6 = dom.createTextNode("\n                    Ogni eventuale reclamo dovrà essere rivolto a: Vigilo 4 You S.r.l. Via Fura 18 – 25125 Brescia\n                    Legge applicabile\n                    Il contratto di vendita tra il Cliente e Vigilo 4 You S.r.l.  s'intende concluso in Italia e regolato dalla Legge Italiana. Per qualsiasi controversia dovesse derivare dall’adempimento o interpretazione del presente contratto o quant’altro, sarà competente, in via esclusiva il Foro di Brescia.\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n\n\n\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("  FINE -> HTML DEL CONTRATTO  ");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "modal-footer");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [2, 1, 1, 5]), 1, 1);
        return morphs;
      },
      statements: [["block", "link-to", ["plessicliente.dettagli", ["subexpr", "query-params", [], ["id_plesso", ["get", "plessocliente.id", ["loc", [null, [128, 76], [128, 92]]]], "panel_visualizza", "contratto"], ["loc", [null, [128, 52], [128, 122]]]]], [], 0, null, ["loc", [null, [128, 16], [130, 28]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});
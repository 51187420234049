define('portale-vigilanza/mixins/istitutos/save-model-mixin', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    actions: {
      save: function save() {
        var route = this;
        this.currentModel.save().then(function () {
          route.transitionTo('istitutos');
        }, function () {
          console.log('Failed to save the model');
        });
      },
      cancel: function cancel() {
        var route = this;
        route.transitionTo('istitutos');
      }
    },
    deactivate: function deactivate() {
      console.log('istitutos deactivate');

      if (this.currentModel.get('isNew')) {
        this.currentModel.deleteRecord();
      } else {
        this.currentModel.rollbackAttributes();
      }

      var datifatturazione = this.controller.get('datifatturazione');
      if (datifatturazione) {
        console.log("datafatturazione presente deattivo");
        if (datifatturazione.get('isNew')) {
          datifatturazione.deleteRecord();
        } else {
          datifatturazione.rollbackAttributes();
        }
      }
    }
  });
});
define('portale-vigilanza/controllers/plessicliente/index', ['exports', 'ember', 'portale-vigilanza/mixins/plessi/checkdisabilitato', 'ember-cli-pagination/remote/route-mixin'], function (exports, _ember, _portaleVigilanzaMixinsPlessiCheckdisabilitato, _emberCliPaginationRemoteRouteMixin) {

  //export default Ember.ArrayController.extend( CheckDisabilitato,{
  exports['default'] = _ember['default'].Controller.extend(_emberCliPaginationRemoteRouteMixin['default'], _portaleVigilanzaMixinsPlessiCheckdisabilitato['default'], {

    namePage: 'plessicliente',

    queryParams: ["page", "sort", "order", "nome_plesso"],
    modelIsLoad: true,

    pieSize: 160,

    cambioPagina: (function () {
      if (this.get('model.isFulfilled')) this.set('modelIsLoad', true);else this.set('modelIsLoad', false);
    }).observes('model.isFulfilled'),

    actions: {

      goNuovoPlesso: function goNuovoPlesso() {
        this.controllerFor('plessicliente/verificacopertura').set('elencoPlessi', false);
        this.transitionToRoute('plessicliente.verificacopertura');
      },

      //  ** Ricerca del plesso **
      searchPlesso: function searchPlesso() {
        this.set('modelIsLoad', false);
        var _this = this;
        this.findPaged('plessicliente', { page: 1, sort: this.get('sort'), order: this.get('order'), nome_plesso: this.get('nome_plesso') }).then(function (data) {
          _this.set('model', data);
          _this.set('modelIsLoad', true);
        });
      },

      pulisci: function pulisci() {
        this.set('nome_plesso', null);
        this.send('searchPlesso');
      }

    }

  });
});
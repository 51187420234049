define('portale-vigilanza/mixins/download/download', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({

    actions: {

      richiediFile: function richiediFile(id, tipo) {

        //Chiamata ajax per apertura nuova pagina con pdf
        var url = window.ENV.APP.API_HOST + '/' + window.ENV.APP.API_NAMESPACE + '/' + window.ENV.APP.PathFile;
        var _this = this;
        $.ajax({
          url: url,
          type: "POST",
          data: {
            id: id,
            tipo: tipo
          },
          dataType: 'text',
          success: function success(dataArr, textStatus, jqXHR) {
            var data = JSON.parse(dataArr);
            window.open(data.url, '_blank');
          },
          beforeSend: function beforeSend(xhr, settings) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + _this.get('session.secure.access_token'));
          }
        });
      }

    }

  });
});
define('portale-vigilanza/mixins/verificacopertura/verifica', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({

    listaProvince: null,
    listComuni: null,

    step1: true,
    step2: false,
    step3: false,

    showVerifica: false,

    showAltriServizi: false,

    listaServizi: null,

    hasNominativo: _ember['default'].computed.notEmpty('nominativo'),
    hasEmail: _ember['default'].computed.notEmpty('email'),
    hasTelefono: _ember['default'].computed.notEmpty('telefono'),
    hasTesto: _ember['default'].computed.notEmpty('testo'),
    isValid: _ember['default'].computed.and('hasNominativo', 'hasEmail', 'hasTelefono', 'hasTesto'),

    signActive: false,

    activeSign: (function () {

      if (this.get('checkbox_1')) {

        if (this.get('isValid')) {
          this.set('signActive', true);
        } else {
          this.set('signActive', false);
        }
      } else {
        this.set('signActive', false);
      }
    }).observes('nominativo', 'email', 'telefono', 'testo', 'checkbox_1'),

    init: function init() {
      //recupero l'elenco delle province
      this.set('listaProvince', this.store.find('province'));
    },

    //  ** Modifica elenco comuni  **
    loadComuni: (function () {
      this.set('comune_copertura', null);
      this.set('listComuni', this.store.find('comuni', { provincia: this.get('provincia_copertura'), 'case': 'upper' }));
    }).observes('provincia_copertura'),

    //  ** Abilita pulsante verifica  **
    allowVerifica: (function () {
      if (this.get('comune_copertura')) {
        this.set('showVerifica', true);
      } else {
        this.set('showVerifica', false);
      }
    }).observes('comune_copertura'),

    actions: {

      //  ** Azione pulsante del modal **
      toggleConfirm: function toggleConfirm() {
        _ember['default'].$('#myModal').modal('hide');
        this.transitionToRoute('home');
      },

      //  ** Apri modal Privacy **
      apriPrivacy: function apriPrivacy() {
        _ember['default'].$('#modalPrivacy').modal('show');
      },

      richiediInfo: function richiediInfo() {
        this.set('step1', false);
        this.set('step2', false);
        this.set('step3', true);
      },

      nascondiServizi: function nascondiServizi() {
        this.set('showAltriServizi', false);
      },
      visualizzaServizi: function visualizzaServizi() {
        this.set('showAltriServizi', true);
      },

      verificaCopertura: function verificaCopertura() {

        //salvo il modello
        var _this = this;
        this.store.find('coperturacomune', { provincia: this.get('provincia_copertura'), comune: this.get('comune_copertura') }).then(function (elenco_servizi) {

          var migliore = null;
          var elenco_altri = [];
          var primo = true;

          if (elenco_servizi.get('length') > 0) {
            _this.set('showProcedi', true);
          }

          var peso_migliore = 0;
          elenco_servizi.forEach(function (servizio) {
            var peso_totale = 0.0;
            var nome_istituto = servizio.get('nome_istituto');

            var opzioni = servizio.get('opzioni');
            opzioni.forEach(function (opzione) {
              peso_totale = peso_totale + parseFloat(opzione.peso);
            });

            if (peso_totale > peso_migliore) {
              migliore = servizio;
              primo = false;
              peso_migliore = peso_totale;
            }
          });

          elenco_servizi.forEach(function (servizio) {
            if (servizio != migliore) elenco_altri.push(servizio);
          });

          if (!primo) {
            _this.set('migliore', migliore);_this.set('showMigliore', true);
          } else {
            _this.set('showMigliore', false);_this.set('AltriServizi', false);_this.set('showProcedi', false);
          }

          if (elenco_altri.length > 0) {
            _this.set('AltriServizi', true);
          }
          _this.set('listaServizi', elenco_altri);
          _this.set('showAltriServizi', false);
          _this.set('step2', true);
        }, function () {
          _this.set('modelError', _this.get('datifatturazionenuovi'));
          _this.set('errorModal', true);
          _this.set('titoloModal', 'Verifica Copertura Servizio');
          _this.set('messaggioModal', 'Errore nell\'invio della verifica');
          _ember['default'].$('#myModal').modal('show');
        });
      },

      inviaInfo: function inviaInfo() {
        console.log('Invio info richiesta copertura');
        this.get('nominativo');
        this.get('email');
        this.get('telefono');
        var checkbox_1 = this.get('checkbox_1');
        this.get('checkbox_2');
        this.get('checkbox_3');
        console.log(checkbox_1);
        var richiestacopertura = this.store.createRecord('richiestacopertura');
        richiestacopertura.set('nominativo', this.get('nominativo'));
        richiestacopertura.set('email', this.get('email'));
        richiestacopertura.set('telefono', this.get('telefono'));
        richiestacopertura.set('testo', this.get('testo'));
        richiestacopertura.set('id_utente', this.get('session.id'));
        richiestacopertura.set('provincia', this.get('provincia_copertura'));
        richiestacopertura.set('comune', this.get('comune_copertura'));
        /** setto data checkbox_1 */
        richiestacopertura.set('checkbox_1', moment().format('YYYY-MM-DD HH:mm:ss'));
        /** setto checkbox_2 e checkbox_3 */
        richiestacopertura.set('checkbox_2', this.get('checkbox_2') ? moment().format('YYYY-MM-DD HH:mm:ss') : null);
        richiestacopertura.set('checkbox_3', this.get('checkbox_3') ? moment().format('YYYY-MM-DD HH:mm:ss') : null);

        var _this = this;
        richiestacopertura.save().then(function () {
          _this.set('errorModal', false);
          _this.set('titoloModal', 'Richiesta aggiornamenti copertura servizio');
          _this.set('messaggioModal', 'Invio richiesta avvenuto correttamente.<br>Verrai contattato ai recapiti inseriti in caso d\'attivazione o variazione della copertura del servizio.');
          _ember['default'].$('#myModal').modal('show');
        }, function () {
          _this.set('modelError', richiestacopertura);
          _this.set('titoloModal', 'Richiesta aggiornamenti copertura servizio');
          _this.set('errorModal', true);
          _this.set('messaggioModal', 'Errore');
          _ember['default'].$('#myModal').modal('show');
        });
      }

    }

  });
});
define('portale-vigilanza/initializers/get-helper', ['exports', 'ember', 'ember-get-helper/utils/register-helper', 'ember-get-helper/helpers/get', 'ember-get-helper/helpers/get-glimmer'], function (exports, _ember, _emberGetHelperUtilsRegisterHelper, _emberGetHelperHelpersGet, _emberGetHelperHelpersGetGlimmer) {
  exports.initialize = initialize;

  function initialize() /* container, application */{
    // Do not register helpers from Ember 1.13 onwards, starting from 1.13 they
    // will be auto-discovered.
    if (_ember['default'].Helper) {
      return;
    }

    (0, _emberGetHelperUtilsRegisterHelper.registerHelper)('get', _emberGetHelperHelpersGet['default']);
  }

  exports['default'] = {
    name: 'get-helper',
    initialize: initialize
  };
});
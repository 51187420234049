define('portale-vigilanza/controllers/verificacoperturainstallatori', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    showResult: false,
    actions: {
      search: function search() {
        var _this = this;

        /** effettuo chiamata */
        this.store.query('coperturainstallatori', { province: this.provinciaSelected.id }).then(function (results) {
          _this.set('results', results);
          _this.set('showResult', true);
        });
      },
      showInfo: function showInfo() {
        this.set('info', this.info ? false : true);
        /* setto dati richiesta */
        console.log(this.provinciaSelected.get('nomeProv'));
        this.set('request', {
          province: this.provinciaSelected.get('nomeProv'),
          nominativo: null,
          email: null,
          telefono: null,
          testo: null,
          checkbox_1: false,
          checkbox_2: false,
          checkbox_3: false
        });
      },
      apriPrivacy: function apriPrivacy() {
        _ember['default'].$('#modalPrivacy').modal('show');
      },
      sendInfo: function sendInfo() {
        var _this2 = this;

        /** verifico tutti i campi siano stati inseriti */
        if (!this.request.nominativo || !this.request.email || !this.request.telefono || !this.request.checkbox_1) {
          /** stampo errore */
          this.set('error', "Completa tutti i campi obbligatori");
        } else {
          /** cancello errore */
          this.set('error', false);
          /** creo modello per la richiesta */
          var richiestacopertura = this.store.createRecord('richiestacopertura', {
            provincia: this.provinciaSelected.get('siglaProv'),
            comune: "n.d.",
            nominativo: this.request.nominativo,
            email: this.request.email,
            telefono: this.request.telefono,
            testo: this.request.testo,
            /** trattamento personali */
            checkbox_1: moment().format('YYYY-MM-DD HH:mm:ss'),
            checkbox_2: this.request.checkbox_2 ? moment().format('YYYY-MM-DD HH:mm:ss') : null,
            checkbox_3: this.request.checkbox_3 ? moment().format('YYYY-MM-DD HH:mm:ss') : null,
            /** tipologia */
            type: 1
          });
          /** invio richiesta */
          richiestacopertura.save().then(function () {
            /** visualizzo messaggio */
            _this2.set('successInfo', "Richiesta inviata correttamente");
            /** resetto richiesta */
            _this2.set('request', {
              province: _this2.provinciaSelected.get('nomeProv'),
              nominativo: null,
              email: null,
              telefono: null,
              testo: null,
              checkbox_1: false,
              checkbox_2: false,
              checkbox_3: false
            });
          })['catch'](function (reason) {
            _this2.set('error', reason);
          });
        }
      }
    }
  });
});
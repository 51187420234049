define('portale-vigilanza/helpers/panel-activa', ['exports', 'ember'], function (exports, _ember) {
    exports.panelActiva = panelActiva;

    function panelActiva(panel, panelactive) {
        var attiva = '';
        if (panel === panelactive) attiva = 'active';

        return new _ember['default'].Handlebars.SafeString(attiva);
    }

    exports['default'] = _ember['default'].Handlebars.makeBoundHelper(panelActiva);
});
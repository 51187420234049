define('portale-vigilanza/mixins/sortList/sort-list', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({

    /*
    //  ** LISTA ORDINATA
    sortAscending:true,
    sortedList:null,
    loadSortedList: function() {
      var newList =  Ember.ArrayProxy.createWithMixins(Ember.SortableMixin, {
        sortProperties: [this.get('sortProperties')],
        sortAscending: this.get('sortAscending'),
        content: this.get('model')
      });
      this.set('sortedList',newList);
    }.observes('model'),
      actions: {
        setSortBy: function(fieldName) {
        this.set('sortProperties', fieldName);
        this.toggleProperty('sortAscending');
        var newList = Ember.ArrayProxy.createWithMixins(Ember.SortableMixin, {
          sortProperties: [this.get('sortProperties')],
          sortAscending: this.get('sortAscending'),
          content: this.get('model')
        });
        this.set('sortedList',newList);
      }
      }*/

    actions: {

      setSortBy: function setSortBy(fieldName) {
        var old_sort = this.get('sort');
        this.set('sort', fieldName);
        if (fieldName !== old_sort) {
          this.set('order', 'asc');
        } else {
          if (this.get('order') === 'asc') {
            this.set('order', 'desc');
          } else {
            this.set('order', 'asc');
          }
        }
      }

    }

  });
});
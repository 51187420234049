define('portale-vigilanza/routes/account/modifica', ['exports', 'ember', 'simple-auth/mixins/authenticated-route-mixin', 'portale-vigilanza/mixins/checkMessage/check-message'], function (exports, _ember, _simpleAuthMixinsAuthenticatedRouteMixin, _portaleVigilanzaMixinsCheckMessageCheckMessage) {
  exports['default'] = _ember['default'].Route.extend(_simpleAuthMixinsAuthenticatedRouteMixin['default'], _portaleVigilanzaMixinsCheckMessageCheckMessage['default'], {

    model: function model() {
      this.store.unloadAll('currentuser');
      this.store.unloadAll('modificacellulare');
      return this.store.findById('currentuser', this.get('session.id'));
    },

    deactivate: function deactivate() {
      this.store.unloadAll('documentoutente');
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);

      //reset delle variabili
      controller.set('vecchiaPassword', null);
      controller.set('nuovaPassword', null);
      controller.set('confermaPassword', null);

      controller.set('prefissoCellulare', { id: "39", text: "39" });
      controller.set('nuovoCellulare', this.get('session.temp_cell'));
      controller.set('codiceConferma', '');

      if (controller.get('azione')) {

        controller.set('panel_visualizza', controller.get('azione'));

        if (controller.get('azione') === 'cellulare') {
          setTimeout(function () {
            _ember['default'].$('#modificaCel').modal('show');
          }, 500);
        }
      }

      if (model.get('is_documento_utente')) {

        //Carico i DocumentoUtente
        var _this = this;
        this.store.unloadAll('documentoutente');
        this.store.find('documentoutente', { user_id: this.get('session.id') }).then(function (dati) {
          if (dati.get('length') > 0) {
            controller.set('documentoutente', null);
            dati.forEach(function (data) {
              controller.set('documentoutente', data);
            });
          } else {
            var documento = _this.store.createRecord('documentoutente');
            documento.set('user_id', _this.get('session.id'));
            controller.set('documentoutente', documento);
          }
        });
      }

      //Carico i dispositivi dell'utente
      controller.set('listaDispositivi', null);
      controller.set('dispositivoEliminare', null);
      var _this = this;
      this.store.unloadAll('device');
      this.store.find('device').then(function (dati) {
        controller.set('listaDispositivi', dati);
      });
    }

  });
});
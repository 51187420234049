define('portale-vigilanza/router', ['exports', 'ember', 'portale-vigilanza/config/environment'], function (exports, _ember, _portaleVigilanzaConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    location: _portaleVigilanzaConfigEnvironment['default'].locationType
  });
  Router.reopen({
    notifyGoogleAnalytics: (function () {
      if (typeof ga != 'function') {
        console.log('ga non definito');return;
      }
      console.log('ga definito');
      return ga('send', 'pageview', {
        'page': this.get('url'),
        'title': this.get('url')
      });
    }).on('didTransition')
  });
  Router.map(function () {
    this.route('login', { path: "login/:token" });

    this.route('spiderweblogin');
    this.route('home');
    this.route('veicoli');
    this.route('report');

    this.route('recuperapassword');

    this.route('richiediplesso');

    this.route('verificacopertura');

    this.route('attivazione'); //attivazione automatica procacciatore / installatore

    this.route('confermaattivazione'); //attivazione con conferma dati cliente

    this.resource("plessicliente", function () {
      this.route("verificacopertura");
      this.route("nuovo");
      this.route("dettagli");
    });

    this.resource("plessiquote", function () {
      //this.route("modifica", { path: "modifica/:plesso_id"  });
    });

    this.resource("plessicommercialeinterno", function () {});
    this.resource("plessiistituto", function () {});
    this.resource("kit", function () {});
    this.resource("prodotti", function () {});

    this.resource("ordini", function () {
      this.route("nuovo");
      this.route("dettagli", { path: "dettagli/:ordine_id" });
      this.route("success", { path: "dettagli/:ordine_id/success" });
      this.route("failed", { path: "dettagli/:ordine_id/failed" });
    });

    this.resource("elencoplessi", function () {});
    this.resource("istitutogestioni", function () {
      this.route("istitutogestioni");
    });
    this.resource("fatture", function () {});

    this.resource("utente", function () {
      this.route("nuovo");
    });

    this.resource("account", function () {
      this.route("modifica");
    });

    this.resource("crediti", function () {
      this.route('ricarica');
    });

    this.resource("provvigioni", function () {
      this.route('provvigioni');
    });

    this.resource("provvigioniold", function () {});

    // this.route('indirizzo');

    this.resource("indirizzos", function () {
      this.route("new");
      this.route("edit", { path: "edit/:indirizzo_id" });
      this.route("show", { path: "show/:indirizzo_id" });
    });
    this.resource("istitutos", function () {
      this.route("new");
      this.route("edit", { path: ":istituto_id/edit" });
      this.route('show', { path: ':istituto_id' }, function () {
        this.resource('sedes', function () {
          this.route("new");
          this.route("edit", { path: ":sede_id/edit" });
          this.route("show", { path: ":sede_id/show" });
        });
      });
    });

    this.resource("datifatturaziones", function () {
      this.route("new");
      this.route("edit", { path: "edit/:indirizzo_id" });
      this.route("show", { path: "show/:indirizzo_id" });
    });

    this.resource("datifatturazionefornitore", function () {});

    /*
        this.resource("sedes", function() {
            this.route("new");
            this.route("edit", {      path: ":sede_id/edit"    });
            this.route("show", {      path: ":sede_id"    });
        });
        */

    this.route("404", { path: "*path" });

    this.route('copertura', function () {
      this.route('create');
    });
    this.route('verificacoperturainstallatori');
  });
  exports['default'] = Router;
});
define('portale-vigilanza/helpers/format-date-nohours', ['exports', 'ember'], function (exports, _ember) {
  exports.formatDate = formatDate;

  function formatDate(input) {
    if (input) {
      if (input == '0000-00-00 00:00') {
        var date = '';
      } else {
        if (moment(input, 'YYYY-MM-DDTHH:mm').format("HH:mm") === '00:00') var date = moment(input, 'YYYY-MM-DDTHH:mm').format("DD/MM/YYYY");else var date = moment(input, 'YYYY-MM-DDTHH:mm').format("DD/MM/YYYY");
      }
    } else {
      var date = '';
    }

    return new _ember['default'].Handlebars.SafeString(date);
  }

  exports['default'] = _ember['default'].Handlebars.makeBoundHelper(formatDate);
});
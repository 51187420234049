define('portale-vigilanza/controllers/google-map/polygons', ['exports', 'ember', 'portale-vigilanza/controllers/google-map/polylines'], function (exports, _ember, _portaleVigilanzaControllersGoogleMapPolylines) {

  var computed = _ember['default'].computed;

  /**
   * @class GoogleMapPolygonsController
   * @extends GoogleMapPolylinesController
   */
  exports['default'] = _portaleVigilanzaControllersGoogleMapPolylines['default'].extend({
    itemController: computed.alias('parentController.polygonController'),
    model: computed.alias('parentController.polygons'),
    pathController: computed.alias('parentController.polygonPathController')
  });
});
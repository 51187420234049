define('portale-vigilanza/controllers/confermaattivazione', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    hasEmail: _ember['default'].computed.notEmpty('model.email'),
    hasNominativo: _ember['default'].computed.notEmpty('model.nominativo'),
    hasCodiceFiscale: _ember['default'].computed.notEmpty('model.codiceFiscale'),
    hasCodiceFatturazione: _ember['default'].computed.notEmpty('model.codiceFatturazione'),
    isValid: _ember['default'].computed.and('hasEmail', 'hasNominativo', 'hasCodiceFiscale', 'hasCodiceFatturazione'),

    signActive: false,

    activeSign: (function () {
      if (this.get('isValid')) {
        this.set('signActive', true);
      } else {
        this.set('signActive', false);
      }
    }).observes('model.email', 'model.nominativo', 'model.codiceFiscale', 'model.codiceFatturazione'),

    actions: {

      //  ** Azione pulsante del modal **
      toggleConfirm: function toggleConfirm() {
        _ember['default'].$('#myModal').modal('hide');
        this.transitionToRoute('index');
      },

      confermaAttivazione: function confermaAttivazione() {

        //verifico che il codice reCAPTCHA sia attivato
        if (grecaptcha.getResponse()) {
          var _this = this;
          this.get('model').save().then(function () {
            _this.set('errorModal', false);
            _this.set('titoloModal', 'Conferma Attivazione');
            _this.set('messaggioModal', 'Dati confermati.<br>Le verrà inviata una mail per la procedura d\'impostazione della password.');
            _ember['default'].$('#myModal').modal('show');
          }, function (error) {
            //reset codice reCAPTCHA
            grecaptcha.reset();
            _this.set('modelError', _this.get('model'));
            _this.set('errorModal', true);
            _this.set('titoloModal', 'Conferma Attivazione');
            _this.set('messaggioModal', 'Errore nella procedura di conferma attivazione.');
            _ember['default'].$('#myModal').modal('show');
            console.log(error);
          });
        } else {
          this.set('errorModal', true);
          this.set('titoloModal', 'Conferma Attivazione');
          this.set('messaggioModal', 'Manca la verifica del codice reCAPTCHA');
          _ember['default'].$('#myModal').modal('show');
        }
      }

    }

  });
});
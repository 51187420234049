define('portale-vigilanza/models/richiestaplessoconsumo', ['exports', 'portale-vigilanza/models/creaplesso'], function (exports, _portaleVigilanzaModelsCreaplesso) {

  /** estende modello principale creaplesso */
  exports['default'] = _portaleVigilanzaModelsCreaplesso['default'].extend({
    tel_contatto: DS.attr('string'),
    richiesta_kit: DS.attr('boolean'),
    tipologia_impianto: DS.attr('string'),
    note: DS.attr('string'),
    stato: DS.attr('string'),
    sub_stato: DS.attr('string'),
    messaggio: DS.attr('string'),

    checkbox_1: DS.attr(),
    checkbox_2: DS.attr(),
    checkbox_3: DS.attr()
  });
});
define('portale-vigilanza/models/plessicommercialeinterno', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({

    ragione_sociale: _emberData['default'].attr('string'),
    categoria: _emberData['default'].attr('string'),
    indirizzo: _emberData['default'].attr('string'),
    comune: _emberData['default'].attr('string'),
    provincia: _emberData['default'].attr('string'),
    isAttivo: _emberData['default'].attr('boolean')

  });
});
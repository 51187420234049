define('portale-vigilanza/mixins/plessi/checkdisabilitato', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    //VisualizzaSelezioneKit: window.ENV.APP.VisualizzaSelezioneKit,

    actions: {
      //  ** Visualizza modal scelta chiavi **
      visualizzaOrdineChiavi: function visualizzaOrdineChiavi(model) {
        this.set('modelOrdineChiavi', model);
        console.log('modelOrdineChiavi');
        _ember['default'].$('#modalOrdineChiavi').modal('show');
      },
      correggi: function correggi(plesso, opzione) {
        var opzione2 = arguments.length <= 2 || arguments[2] === undefined ? '' : arguments[2];
        var opzione3 = arguments.length <= 3 || arguments[3] === undefined ? '' : arguments[3];

        var opzioneSwitch = opzione.indexOf('_') > -1 ? opzione.split('_')[0] : opzione;

        switch (opzioneSwitch) {
          case 'kit':

            if (opzione2 || opzione3) {
              this.transitionToRoute('kit', { queryParams: { plesso: plesso, tag: opzione, selezione: 'si' } });
            } else {
              this.transitionToRoute('kit', { queryParams: { plesso: plesso, tag: opzione, selezione: 'no' } });
            }
            break;
          case 'attivachiavi':
            _ember['default'].$('#modalOrdineChiavi').modal('hide');
            this.transitionToRoute('plessicliente.dettagli', { queryParams: { id_plesso: plesso, panel_visualizza: 'servizi', show_chiavi: 'si', scroll: 'si' } });
            break;
          case 'contratto':
            this.transitionToRoute('plessicliente.dettagli', { queryParams: { id_plesso: plesso, panel_visualizza: 'servizi', show_contract: 'si', scroll: 'si' } });
            break;
          case 'note':
            this.transitionToRoute('plessicliente.dettagli', { queryParams: { id_plesso: plesso, panel_visualizza: 'servizi', show_note: 'si', scroll: 'si' } });
            break;
          case 'copertura':
            this.transitionToRoute('plessicliente.dettagli', { queryParams: { id_plesso: plesso, panel_visualizza: 'servizi', show_copertura: 'si', scroll: 'si' } });
            break;
          case 'datifatturazione':
            this.transitionToRoute('plessicliente.dettagli', { queryParams: { id_plesso: plesso, panel_visualizza: 'datifatturazione', show_richiedidati: 'si', scroll: 'si' } });
            break;
          case 'pagamento':
            _ember['default'].$('#modalOrdineChiavi').modal('hide');
            this.transitionToRoute('ordini.nuovo', { queryParams: { plesso: plesso, tipologia: opzione2 } });
            break;
        }
      }

    }

  });
});
define('portale-vigilanza/routes/veicoli', ['exports', 'ember', 'simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _simpleAuthMixinsAuthenticatedRouteMixin) {
    exports['default'] = _ember['default'].Route.extend(_simpleAuthMixinsAuthenticatedRouteMixin['default'], {

        model: function model() {
            return this.store.find('spidersataccount');
        },

        actions: {
            refreshRoute: function refreshRoute() {
                var _this = this;
                _ember['default'].run.later(function () {
                    location.reload();
                    _this.send('refreshRoute');
                }, 10000);
            }
        },
        afterModel: function afterModel() {
            var _this = this;
            _ember['default'].run.later(function () {
                ;
                _this.refresh();
            }, 10000);
        }

    });
});
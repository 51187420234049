define('portale-vigilanza/models/vigilanzaconsumo', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({

    copertura_comune: _emberData['default'].attr('string'),
    note_intervento: _emberData['default'].attr('string'),
    accettazione_contratto: _emberData['default'].attr('string'),
    cellulare_verificato: _emberData['default'].attr('string'),
    costo_crediti: _emberData['default'].attr('string', { defaultValue: "0" }),
    intervento_chiavi_2_livello: _emberData['default'].attr('string'),
    intervento_semplice: _emberData['default'].attr('string')

  });
});
define('portale-vigilanza/mixins/indirizzos/save-model-mixin', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    actions: {
      save: function save() {
        var route = this;
        this.currentModel.save().then(function () {
          route.transitionTo('indirizzos');
        }, function () {
          console.log('Failed to save the model');
        });
      },
      cancel: function cancel() {
        var route = this;
        route.transitionTo('indirizzos');
      }
    },
    deactivate: function deactivate() {
      if (this.currentModel.get('isNew')) {
        this.currentModel.deleteRecord();
      } else {
        this.currentModel.rollbackAttributes();
      }
    }
  });
});
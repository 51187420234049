define('portale-vigilanza/adapters/application', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].RESTAdapter.extend({

        isNewSerializerAPI: true,
        host: window.ENV.APP.API_HOST,
        namespace: window.ENV.APP.API_NAMESPACE,
        coalesceFindRequests: true,
        //ajaxError: function(jqXHR) {
        isInvalid: function isInvalid(jqXHR) {
            var error = this._super(jqXHR);
            // 422 is used by this fictional server to signal a validation error
            if (jqXHR && jqXHR.status === 422) {
                var jsonErrors = Ember.$.parseJSON(jqXHR.responseText)["errors"];

                return new _emberData['default'].InvalidError(jsonErrors);
            } else {
                // The ajax request failed however it is not a result of this
                // record being in an invalid state so we do not return a
                // `InvalidError` object.

                return error;
            }
        },

        headers: (function () {
            var accessToken = 'Bearer ' + this.get('session.secure.access_token');
            return { 'Authorization': accessToken, 'If-Modified-Since': 'Sat, 01 Oct 2015 00:00:00 GMT' };
        }).property('session.secure'),

        shouldReloadAll: function shouldReloadAll() {
            return true;
        }

    });

    /*
    import DRFAdapter from './drf';
    
    export default DRFAdapter.extend({
        addTrailingSlashes: false,
        coalesceFindRequests: true,
        isNewSerializerAPI: true,
        headers: function() {
            var accessToken = 'Bearer ' + this.get('session.secure.access_token');
            return { 'Authorization': accessToken };
        }.property('session.secure')  /*,,
        pathForType: function(type) { //rimuove il plurale dagli url
            var camelized = Ember.String.camelize(type);
            return Ember.String.singularize(camelized);
        }* /
    });
        */
});
//import Ember from 'ember';
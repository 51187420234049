define('portale-vigilanza/routes/copertura/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {

      //console.log();

      return this.store.find('copertura');
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      console.log("Questo è il modello");
      console.log(model);
    }

  });
});
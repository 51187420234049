define('portale-vigilanza/models/richiestacopertura', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({

    nominativo: _emberData['default'].attr('string'),
    email: _emberData['default'].attr('string'),
    telefono: _emberData['default'].attr('string'),
    testo: _emberData['default'].attr('string'),
    id_utente: _emberData['default'].attr('string'),
    provincia: _emberData['default'].attr('string'),
    comune: _emberData['default'].attr('string'),
    checkbox_1: _emberData['default'].attr('string'),
    checkbox_2: _emberData['default'].attr('string'),
    checkbox_3: _emberData['default'].attr('string'),

    type: _emberData['default'].attr()

  });
});
define('portale-vigilanza/models/istituto', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({ //'nome','descrizione','accettazione_sistema','numero_apparati','stato','datiFatturaziones_id'
        nome: _emberData['default'].attr('string'),
        descrizione: _emberData['default'].attr('string'),
        accettazioneSistema: _emberData['default'].attr('number'),
        numeroApparati: _emberData['default'].attr('number'),
        sedeCount: _emberData['default'].attr('number'),
        stato: _emberData['default'].attr('string'),
        sedes: _emberData['default'].hasMany('sede', { async: true })
    });
});